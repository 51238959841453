import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export type MoLoadingOverlayProps = {
  isOpen: boolean;
};

export function MoLoadingOverlay({ isOpen }: MoLoadingOverlayProps) {
  return (
    <Backdrop open={isOpen} style={{ zIndex: 2000 }}>
      <CircularProgress size={200} color="secondary" />
    </Backdrop>
  );
}
