import useViewer from ':src/domains/auth/hooks/useViewer';
import { ResultOf, graphql } from ':src/graphql';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { MoCheckboxField, MoLink, MoSpacing, MoSubmitButton } from '@motivo/guanyin/src/components';
import { Columns, MoDataTable } from '@motivo/guanyin/src/components/MoDataTable';
import { moZ } from '@motivo/guanyin/src/utils/moZod';
import throwWithoutBreaking from '@motivo/guanyin/src/utils/throwWithoutBreaking';
import { Alert, Box, Tooltip } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { z } from 'zod';
import slugify from 'slugify';
import { validatePhoneNumber } from '@motivo/guanyin/src/utils/stringUtils';
import SettingsHeader from '../SettingsHeader';
import UserNotificationTriggerLabel from './UserNotificationTriggerLabel';
import { CheckboxContainer, NotificationTypeHeader, shouldRenderSmsCheckbox } from './utils';

export type UserNotificationPreferenceType = ResultOf<
  typeof userNotificationPreferencesQuery
>['userNotificationPreferences'][number]['preferences'][number] & {
  index: number;
};

const userNotificationPreferencesQuery = graphql(`
  query UserNotificationPreferencesQuery {
    userNotificationPreferences {
      id
      categoryName
      preferences {
        id
        userNotificationTriggerId
        sms
        email
      }
    }
  }
`);

const updateUserNotificationPreferencesMutation = graphql(`
  mutation UpdateUserNotificationPreferencesMutation(
    $input: UpdateUserNotificationPreferencesInput!
  ) {
    updateUserNotificationPreferences(input: $input) {
      success
    }
  }
`);

const formSchema = moZ.object({
  userNotificationPreferences: moZ.array(
    moZ.object({
      preferences: moZ.array(
        moZ.object({
          userNotificationTriggerId: moZ.number(),
          sms: moZ.boolean(),
          email: moZ.boolean(),
        }),
      ),
    }),
  ),
});
type FieldValues = z.infer<typeof formSchema>;

const NotificationsPageForm: FunctionComponent<{}> = () => {
  const {
    data: { userNotificationPreferences },
    refetch,
  } = useSuspenseQuery(userNotificationPreferencesQuery);
  const [updateUserNotificationPreferences] = useMutation(
    updateUserNotificationPreferencesMutation,
  );
  const { enqueueSnackbar } = useSnackbar();

  const userHasSmsOptions = userNotificationPreferences.some((category) =>
    category.preferences.some((preference) =>
      shouldRenderSmsCheckbox(preference.userNotificationTriggerId),
    ),
  );

  const { viewer } = useViewer();
  const hasPhoneNumber = !!viewer.phone;
  const hasInvalidPhoneNumber = hasPhoneNumber && !validatePhoneNumber(viewer.phone!);

  const userHasAnySmsNotificationsEnabled =
    userHasSmsOptions &&
    userNotificationPreferences.some((category) => category.preferences.some(({ sms }) => sms));

  async function onSubmit(fieldValues: FieldValues) {
    const values = fieldValues.userNotificationPreferences
      .flatMap((x) => x.preferences)
      .map(({ userNotificationTriggerId, email, sms }) => ({
        userNotificationTriggerId,
        email,
        sms,
      }));

    try {
      await updateUserNotificationPreferences({
        variables: {
          input: {
            userNotificationPreferences: values,
          },
        },
      });
      enqueueSnackbar('Notifications successfully saved', { variant: 'success' });
      refetch();
    } catch (error) {
      throwWithoutBreaking(error as Error);
      enqueueSnackbar('Error saving notifications, please try again later', {
        variant: 'error',
      });
    }
  }

  return (
    <div>
      <Form<FieldValues>
        mutators={{ ...arrayMutators }}
        onSubmit={onSubmit}
        initialValues={{
          userNotificationPreferences,
        }}
        render={({ handleSubmit, values }) => {
          const areAnySmsNotificationsSelected = values.userNotificationPreferences.some(
            (category) => category.preferences.some(({ sms }) => sms),
          );

          return (
            <form onSubmit={handleSubmit}>
              <FieldArray name="userNotificationPreferences">
                {({ fields }) => {
                  return (
                    <MoSpacing y={4}>
                      {fields.map((categoryName, i) => {
                        const userNotificationPreferenceCategory = userNotificationPreferences[i];

                        const categoryHasSmsOptions =
                          userNotificationPreferenceCategory.preferences.some((preference) =>
                            shouldRenderSmsCheckbox(preference.userNotificationTriggerId),
                          );

                        const columns: Columns<UserNotificationPreferenceType> = [
                          {
                            header: (
                              <SettingsHeader
                                id={slugify(userNotificationPreferenceCategory.categoryName, {
                                  lower: true,
                                })}
                              >
                                {userNotificationPreferenceCategory.categoryName}
                              </SettingsHeader>
                            ),
                            sx: { border: 'none', px: 0, display: 'flex', alignItems: 'center' },
                            content: function categoryDescription(userNotificationPreference) {
                              return (
                                <UserNotificationTriggerLabel
                                  userNotificationTriggerId={
                                    userNotificationPreference.userNotificationTriggerId
                                  }
                                />
                              );
                            },
                          },
                          {
                            header: <NotificationTypeHeader>Email</NotificationTypeHeader>,
                            sx: {
                              border: 'none',
                              px: '0px',
                            },
                            content: function emailCheckboxes(userNotificationPreference) {
                              return (
                                <CheckboxContainer>
                                  <MoCheckboxField
                                    name={`${categoryName}.preferences[${userNotificationPreference.index}].email`}
                                    label=""
                                    sx={{ margin: '0px' }}
                                  />
                                </CheckboxContainer>
                              );
                            },
                          },
                          {
                            header: (
                              <NotificationTypeHeader
                                sx={{
                                  visibility: categoryHasSmsOptions ? 'visible' : 'hidden',
                                }}
                              >
                                SMS
                              </NotificationTypeHeader>
                            ),
                            sx: {
                              border: 'none',
                              px: '0px',
                            },
                            content: function smsCheckboxes(userNotificationPreference) {
                              let tooltipText: string | null = null;
                              if (!hasPhoneNumber) {
                                tooltipText =
                                  'Add a phone number to your account to enable SMS notifications.';
                              }
                              if (hasInvalidPhoneNumber) {
                                tooltipText = 'Your phone number is invalid.';
                              }
                              return (
                                <Tooltip title={tooltipText}>
                                  <div>
                                    <CheckboxContainer>
                                      {shouldRenderSmsCheckbox(
                                        userNotificationPreference.userNotificationTriggerId,
                                      ) && (
                                        <>
                                          <MoCheckboxField
                                            name={`${categoryName}.preferences[${userNotificationPreference.index}].sms`}
                                            label=""
                                            sx={{ margin: '0px' }}
                                            disabled={!hasPhoneNumber || hasInvalidPhoneNumber}
                                          />
                                        </>
                                      )}
                                    </CheckboxContainer>
                                  </div>
                                </Tooltip>
                              );
                            },
                          },
                        ];

                        return (
                          <Box key={categoryName}>
                            <MoDataTable
                              data={userNotificationPreferenceCategory.preferences.map(
                                (preference, i) => ({ ...preference, index: i }),
                              )}
                              columns={columns}
                              rowSx={{
                                display: 'grid',
                                gridTemplateColumns: userHasSmsOptions
                                  ? '1fr 50px 50px'
                                  : '1fr 50px',
                              }}
                            />
                          </Box>
                        );
                      })}
                    </MoSpacing>
                  );
                }}
              </FieldArray>
              {userHasSmsOptions && (
                <MoSpacing y={2}>
                  {hasInvalidPhoneNumber && (
                    <Alert severity="error">
                      Our records show you have an invalid phone number. Please correct your number
                      in your <MoLink to="/settings">general settings</MoLink>.
                    </Alert>
                  )}
                  {areAnySmsNotificationsSelected && !userHasAnySmsNotificationsEnabled && (
                    <Alert severity="info">
                      The phone number we have on file for you is <strong>{viewer.phone}</strong>.
                      If this is incorrect, please update it in your{' '}
                      <MoLink to="/settings">general settings</MoLink>.
                    </Alert>
                  )}
                  <Alert severity="info">
                    By selecting SMS for any of the above events, I agree to receive automated text
                    messages from Motivo at the phone number associated with my account. Message and
                    data rates may apply.
                  </Alert>
                </MoSpacing>
              )}

              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                <MoSubmitButton fullWidth={false}>Save changes</MoSubmitButton>
              </Box>
            </form>
          );
        }}
      />
    </div>
  );
};

export default NotificationsPageForm;
