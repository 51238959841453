import React, { ReactNode } from 'react';
import { Dialog, Typography, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MoSpacing } from '../MoSpacing';

export type MoModalProps = {
  open: boolean;
  onClose: () => void;
  image?: string;
  imageWidth?: string | undefined;
  title?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md';
  isCloseDisabled?: boolean;
  textAlign?: 'left' | 'center';
};

const XS_MAX_WIDTH_IN_PX = 400;
const maxWidthsToPx = new Map<MoModalProps['maxWidth'], number>([
  ['xs', XS_MAX_WIDTH_IN_PX],
  ['sm', 600],
  ['md', 960],
]);

export function MoModal(props: MoModalProps) {
  const {
    open,
    onClose,
    image,
    imageWidth,
    title,
    children,
    actions,
    maxWidth,
    isCloseDisabled = false,
    textAlign = 'left',
  } = props;

  const maxWidthInPx = maxWidthsToPx.get(maxWidth) || XS_MAX_WIDTH_IN_PX;

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { maxWidth: maxWidthInPx, width: '100%' } }}
      // @ts-expect-error TS(2322): Type '(() => void) | null' is not assignable to ty... Remove this comment to see the full error message
      onBackdropClick={isCloseDisabled ? null : onClose}
    >
      <Box mb={4}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          disabled={isCloseDisabled}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ textAlign }}>
        <MoSpacing y={1}>
          {image && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin="auto"
              width={imageWidth}
              mb={2}
            >
              <img src={image} alt="" />
            </Box>
          )}
          {title && (
            <Typography fontWeight={600} variant="h3">
              {title}
            </Typography>
          )}
          {children && <>{children}</>}
        </MoSpacing>
        {actions && <Box mt={3}>{actions}</Box>}
      </DialogContent>
    </Dialog>
  );
}
