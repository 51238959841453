import { Box, MenuItem, Select, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { LinkProps, useHistory } from 'react-router-dom';
import { MoExternalLink, MoExternalLinkProps } from '../MoExternalLink';
import { MoLink } from '../MoLink';
import { MoNotificationBadge } from '../MoNotificationBadge';
import { MoPageContainer } from '../MoPageContainer';
import { MoSpacing } from '../MoSpacing';

type SettingsLinkProps = (LinkProps | MoExternalLinkProps) & { isActive: boolean };

const SettingsLink = styled(
  (props: SettingsLinkProps) =>
    'to' in props ? (
      <MoLink variant="body1" {...props} />
    ) : (
      <MoExternalLink variant="body1" {...props} />
    ),
  {
    shouldForwardProp: (prop) => prop !== 'isActive',
  },
)(({ theme, isActive }) => ({
  display: 'block',
  fontWeight: isActive ? '600' : '400',
  color: isActive ? theme.palette.primary.main : 'inherit',
}));

type PageType = {
  name: string;
  isActive: boolean;
  badgeCount?: number;
  disabled?: boolean;
} & ({ path: string } | { url: string });

export type SettingsLayoutType = {
  children: ReactNode;
  pages: PageType[];
};

export function SettingsLayout({ children, pages }: SettingsLayoutType) {
  const history = useHistory();

  const activePageName = pages.find(({ isActive }) => isActive)?.name;

  return (
    <MoPageContainer hasWhiteBackground maxWidth="md">
      <Box display="flex" gap={6} maxWidth={650} margin="0 auto">
        <Box width={100} flexShrink={0} display={{ xs: 'none', sm: 'block' }}>
          <MoSpacing y={1}>
            {pages.map(({ name, isActive, badgeCount, disabled, ...pathOrUrl }) => {
              const linkProps =
                'path' in pathOrUrl
                  ? { to: pathOrUrl.path }
                  : { href: pathOrUrl.url, target: '_blank', rel: 'noreferrer' };

              return (
                <Box key={name} sx={{ textAlign: 'right', position: 'relative' }}>
                  <SettingsLink
                    {...linkProps}
                    sx={disabled ? { pointerEvents: 'none', color: 'grey.400' } : {}}
                    isActive={isActive}
                  >
                    {name}
                  </SettingsLink>

                  {badgeCount !== null && (
                    <Box
                      sx={{
                        ml: 1,
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: -25,
                      }}
                    >
                      {badgeCount ? <MoNotificationBadge>{badgeCount}</MoNotificationBadge> : ''}
                    </Box>
                  )}
                </Box>
              );
            })}
          </MoSpacing>
        </Box>
        <Box flexGrow={1}>
          <Box display={{ xs: 'block', sm: 'none' }} mb={3}>
            <Select
              defaultValue={activePageName}
              inputProps={{ sx: { fontWeight: 600 } }}
              fullWidth
            >
              {pages.map(({ name, badgeCount, disabled, ...pathOrUrl }) => (
                <MenuItem
                  key={name}
                  value={name}
                  onClick={() => {
                    if ('url' in pathOrUrl) {
                      window.location.href = pathOrUrl.url;
                    } else {
                      history.push(pathOrUrl.path);
                    }
                  }}
                  disabled={disabled}
                >
                  <Box display="flex" alignItems="center">
                    <Box>{name}</Box>
                    {badgeCount ? (
                      <Box ml={1}>
                        <MoNotificationBadge>{badgeCount}</MoNotificationBadge>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>
          {children}
        </Box>
      </Box>
    </MoPageContainer>
  );
}
