import { useContext } from 'react';
import invariant from 'tiny-invariant';
import { IntroRequestContext } from '../components/IntroRequestProvider';

export function useIntroRequestContext() {
  const introRequestContext = useContext(IntroRequestContext);
  invariant(
    introRequestContext,
    'useIntroRequestContext must be used within an IntroRequestProvider',
  );

  return introRequestContext;
}
