import { DateTime } from 'luxon';
import { ChatActorType, ChatMessageType } from './types';

const GROUPED_MESSAGE_THRESHOLD_IN_MINUTES = 3;

export type MessagesGroupedByTime = {
  time: DateTime;
  formattedTime: string;
  messages: ChatMessageType[];
  sender: ChatActorType;
};

export type MessagesGroupedByDate = {
  date: DateTime;
  formattedDate: string;
  messagesByTime: MessagesGroupedByTime[];
};

export function addMessageToGroupedMessages(
  message: ChatMessageType,
  groupedMessages: MessagesGroupedByDate[],
  senderMap: Map<number, ChatActorType>,
) {
  const messageDateTime = DateTime.fromISO(message.createdAt);
  const lastGroupedByDateMessages = groupedMessages[groupedMessages.length - 1];
  if (lastGroupedByDateMessages?.date.hasSame(messageDateTime, 'day')) {
    const lastGroupedByTimeMessages =
      lastGroupedByDateMessages.messagesByTime[lastGroupedByDateMessages.messagesByTime.length - 1];

    if (
      messageDateTime.diff(lastGroupedByTimeMessages.time, 'minute').minutes <=
        GROUPED_MESSAGE_THRESHOLD_IN_MINUTES &&
      lastGroupedByTimeMessages.sender.id === message.senderActorId
    ) {
      lastGroupedByTimeMessages.messages.push(message);
    } else {
      lastGroupedByDateMessages.messagesByTime.push({
        time: messageDateTime,
        formattedTime: messageDateTime.toLocaleString(DateTime.TIME_SIMPLE),
        messages: [message],
        // @ts-expect-error TS(2322): Type 'ChatActorType | undefined' is not assignable... Remove this comment to see the full error message
        sender: senderMap.get(message.senderActorId),
      });
    }
    return;
  }

  groupedMessages.push({
    date: messageDateTime,
    formattedDate: messageDateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
    messagesByTime: [
      {
        time: messageDateTime,
        messages: [message],
        formattedTime: messageDateTime.toLocaleString(DateTime.TIME_SIMPLE),
        // @ts-expect-error TS(2322): Type 'ChatActorType | undefined' is not assignable... Remove this comment to see the full error message
        sender: senderMap.get(message.senderActorId),
      },
    ],
  });
}
