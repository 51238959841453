import React from 'react';
import { Field } from 'react-final-form';
import { MenuItem } from '@mui/material';
import { moZ } from '@src/utils/moZod';
import { BASE_DURATION_OPTIONS } from '@src/lookup';
import { MoSelectField } from '../MoSelectField';

const DURATION_NAME = 'duration';
export const durationSchemaMap = {
  [DURATION_NAME]: moZ.number('Please select a duration'),
};

export function initialDuration() {
  return {
    [DURATION_NAME]: 60,
  };
}

export type MoDurationSelectFieldProps = {
  durationOptions?: number[];
};

export default function MoDurationSelectField({
  durationOptions = BASE_DURATION_OPTIONS,
}: MoDurationSelectFieldProps) {
  return (
    <Field<number>
      name={DURATION_NAME}
      parse={(value) => Number(value)}
      render={({ input }) => (
        <MoSelectField label="Duration" {...input}>
          {durationOptions.map((duration) => (
            <MenuItem key={duration} value={duration}>{`${duration} minutes`}</MenuItem>
          ))}
        </MoSelectField>
      )}
    />
  );
}
