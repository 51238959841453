import { profileProgressViewerQuery } from ':src/domains/settings/queries';
import { SettingsPagesEnum } from ':src/globalConstants';
import { useQuery } from '@apollo/client';
import invariant from 'tiny-invariant';

export default function useProfileProgress() {
  const { data, loading } = useQuery(profileProgressViewerQuery);

  if (!data || loading) return null;

  const { missingFields, isSupervisor, supervisorApplication } = data.viewer;
  invariant(missingFields);
  const missingGeneralFields = (
    [
      'firstName',
      'lastName',
      'postnominals',
      'zipcode',
      'timezone',
      ...(isSupervisor ? (['address1', 'city', 'emergencyContact'] as const) : []),
    ] as const
  ).filter((field) => missingFields.includes(field));

  const missingProfileFields = (
    [
      'avatarUrl',
      'usersModalities',
      'usersSpecialties',
      ...(isSupervisor
        ? ([
            'quote',
            'bio',
            'usersDegrees',
            'userAvailabilities',
            'usersPopulations',
            'resumeUserDocumentId',
            'beganPracticingYear',
          ] as const)
        : []),
    ] as const
  ).filter((field) => missingFields.includes(field));

  const missingPreferencesFields = (
    [
      'weeklyAvailableHours',
      'userAvailabilities',
      'supervisorProfilesSessionTypes',
      'supervisesInPerson',
    ] as const
  ).filter((field) => missingFields.includes(field));

  const missingLicensesFields = missingFields.includes('usersLicenses')
    ? (['usersLicenses'] as const)
    : [];

  const missingPaymentFields =
    isSupervisor && missingFields.includes('stripeVerified') ? (['stripeVerified'] as const) : [];

  const missingAgreementsFields =
    supervisorApplication && missingFields.includes('supervisorAgreements')
      ? (['supervisorAgreements'] as const)
      : [];

  let numberOfIncompleteSteps = 0;
  let numberOfTotalSteps = 0;
  for (const fields of [
    missingGeneralFields,
    missingProfileFields,
    missingPreferencesFields,
    missingLicensesFields,
    missingPaymentFields,
    missingAgreementsFields,
  ]) {
    if (fields.length > 0) numberOfIncompleteSteps += 1;
    numberOfTotalSteps += 1;
  }

  return {
    progressPercentage: Math.round(
      ((numberOfTotalSteps - numberOfIncompleteSteps) / numberOfTotalSteps) * 100,
    ),
    numberOfIncompleteSteps,
    missingFields: {
      [SettingsPagesEnum.GENERAL]: missingGeneralFields,
      [SettingsPagesEnum.PROFILE]: missingProfileFields,
      [SettingsPagesEnum.PREFERENCES]: missingPreferencesFields,
      [SettingsPagesEnum.LICENSES]: missingLicensesFields,
      [SettingsPagesEnum.PAYMENT]: missingPaymentFields,
      [SettingsPagesEnum.AGREEMENTS]: missingAgreementsFields,
    },
    viewer: data.viewer,
  };
}
