import { DateTime } from 'luxon';

export const getBrowserTimeZone = () => {
  let timezone = DateTime.local().zoneName;
  if (timezone === 'America/Indianapolis') {
    timezone = 'America/New_York';
  }
  return timezone;
};

export const DEFAULT_TIMEZONE = 'America/New_York';

export const getTimezoneWithDefaults = (timezone: TimeZoneEnum) => {
  const browserTimeZone = getBrowserTimeZone();
  return timezone || browserTimeZone || (DEFAULT_TIMEZONE as TimeZoneEnum);
};

export enum TimeZoneEnum {
  Eastern = 'America/New_York',
  Central = 'America/Chicago',
  Mountain = 'America/Denver',
  Arizona = 'America/Phoenix',
  Pacific = 'America/Los_Angeles',
  Alaska = 'America/Anchorage',
  Hawaii = 'Pacific/Honolulu',
}

export const timeZoneNames: Record<TimeZoneEnum, string> = {
  [TimeZoneEnum.Eastern]: 'Eastern Time',
  [TimeZoneEnum.Central]: 'Central Time',
  [TimeZoneEnum.Mountain]: 'Mountain Time',
  [TimeZoneEnum.Arizona]: 'Mountain Standard Time (Arizona)',
  [TimeZoneEnum.Pacific]: 'Pacific Time',
  [TimeZoneEnum.Alaska]: 'Alaska Time',
  [TimeZoneEnum.Hawaii]: 'Hawaii Standard Time',
};

export const invertedTimeZoneNames: Record<string, TimeZoneEnum> = {
  'Eastern Time': TimeZoneEnum.Eastern,
  'Central Time': TimeZoneEnum.Central,
  'Mountain Time': TimeZoneEnum.Mountain,
  'Mountain Standard Time (Arizona)': TimeZoneEnum.Arizona,
  'Pacific Time': TimeZoneEnum.Pacific,
  'Alaska Time': TimeZoneEnum.Alaska,
  'Hawaii Standard Time': TimeZoneEnum.Hawaii,
};

export const getTimeZoneNameFromIANA = (
  ianaTimeZone: string,
  { short }: { short?: boolean } = {},
) => {
  return DateTime.local()
    .setZone(ianaTimeZone)
    .toFormat(short ? 'ZZZZ' : 'ZZZZZ');
};
