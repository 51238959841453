import React, { useContext } from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ChatsContext from '../ChatsContext';

export default function BackToChatsLink() {
  // @ts-expect-error TS(2339): Property 'chatRootPath' does not exist on type 'Ch... Remove this comment to see the full error message
  const { chatRootPath } = useContext(ChatsContext);
  return (
    <IconButton component={Link} to={chatRootPath}>
      <ChevronLeft />
    </IconButton>
  );
}
