import React from 'react';
import { Box, Container, Typography, styled, useTheme } from '@mui/material';
import { MoSpacing } from '../MoSpacing';

const FooterLink = styled('a')`
  align-items: center;
  font-size: 1rem;
  color: black;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const Logo = styled('img')`
  width: 30px;
`;

const SocialLink = styled('a')(({ theme }) => ({
  padding: theme.spacing(0.5),
  display: 'inline-flex',
  '&:focus': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
  },
}));

export function Footer() {
  const theme = useTheme();

  return (
    <Box component="footer" bgcolor="white" borderTop={`1px solid ${theme.palette.grey[300]}`}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            textAlign: 'center',
            py: { xs: 4, md: 5 },
            pb: { md: 10 },
          }}
        >
          <Box display={{ md: 'flex' }} gap={5}>
            <Typography fontWeight="600" mb={{ xs: 1, md: 0 }}>
              <strong>&copy; 2019-{new Date().getFullYear()} Motivo</strong>
            </Typography>
            <MoSpacing x={3}>
              <FooterLink href="https://motivohealth.com/privacy" target="_blank" rel="noreferrer">
                Privacy
              </FooterLink>
              <FooterLink href="https://motivohealth.com/terms" target="_blank" rel="noreferrer">
                Terms
              </FooterLink>
              <FooterLink href="https://motivohealth.com/help" target="_blank" rel="noreferrer">
                Help Center
              </FooterLink>
            </MoSpacing>
          </Box>
          <Box
            sx={{
              order: {
                xs: -1,
                md: 1,
              },
              marginBottom: {
                xs: 2,
                md: 0,
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
              <SocialLink
                href="https://www.facebook.com/motivohealth"
                target="_blank"
                rel="noreferrer"
              >
                <Logo
                  src="https://app.motivohealth.com/assets/images/socials/facebook.svg"
                  alt="Facebook"
                />
              </SocialLink>
              <SocialLink
                href="https://www.linkedin.com/company/motivohealth"
                target="_blank"
                rel="noreferrer"
              >
                <Logo
                  src="https://app.motivohealth.com/assets/images/socials/linkedin.svg"
                  alt="LinkedIn"
                />
              </SocialLink>
              <SocialLink
                href="https://www.instagram.com/motivohealth"
                target="_blank"
                rel="noreferrer"
              >
                <Logo
                  src="https://app.motivohealth.com/assets/images/socials/instagram.svg"
                  alt="Instagram"
                />
              </SocialLink>
              <SocialLink href="https://twitter.com/motivohealth" target="_blank" rel="noreferrer">
                <Logo
                  src="https://app.motivohealth.com/assets/images/socials/twitter.svg"
                  alt="Twitter"
                />
              </SocialLink>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
