import React from 'react';
import { Button } from '@mui/material';
import notFoundSrc from './img/notFound.svg';
import ErrorLayout from '../components/ErrorLayout';

export default function NotFoundPage() {
  return (
    <ErrorLayout
      title="Sorry, we couldn’t find that page!"
      body="We may have sent you here by accident, but if you entered the URL by hand, there might have been a typo. Maybe you can find what you need on our homepage!"
      imageSrc={notFoundSrc}
      cta={
        <Button variant="contained" color="secondary" href="https://wearemotivo.com">
          Return to homepage
        </Button>
      }
    />
  );
}
