import { moZ } from '@src/utils/moZod';
import { TimeZoneEnum } from '@src/utils/timeZoneUtils';
import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';
import { useField } from 'react-final-form';
import { MoTimeSlots } from '../../MoTimeSlots';

const REQUIRED_DAYS = 3;
const REQUIRED_TIME_SLOTS = 5;

export const AVAILABLE_SLOTS_NAME = 'availableSlots';
export const availableSlotsSchema = moZ.array(moZ.dateTime());

export function validateAvailableSlots(
  value: DateTime[],
  numberOfSlots = REQUIRED_TIME_SLOTS,
  numberOfDays = REQUIRED_DAYS,
) {
  const dateStrings = value.map((date) => date.toFormat('LLL dd'));
  const distinctDates = new Set(dateStrings);
  const daysLeft = Math.max(numberOfDays - distinctDates.size, 0);
  const slotsLeft = numberOfSlots - value.length;

  return {
    slotsLeft,
    daysLeft,
  };
}

export const availableSlotsPickFiveSchemaMap = {
  [AVAILABLE_SLOTS_NAME]: availableSlotsSchema.superRefine((value, ctx) => {
    const { slotsLeft, daysLeft } = validateAvailableSlots(value);

    if (slotsLeft === 0 && daysLeft === 0) {
      return;
    }

    ctx.addIssue({
      code: 'custom',
      message: 'Please pick 5 options on 3 different days.',
    });
  }),
};

export const availableSlotsPickAtLeastOneSchemaMap = {
  [AVAILABLE_SLOTS_NAME]: availableSlotsSchema.min(1, 'You must pick at least 1 option'),
};

export const availableSlotsPickOnlyOneSchemaMap = {
  [AVAILABLE_SLOTS_NAME]: availableSlotsSchema
    .min(1, 'You must pick at least 1 option')
    .max(1, 'Please pick only 1 option'),
};

export type AvailableSlotsFieldProps = {
  timezone: TimeZoneEnum;
  timeSlots: { date: string; times: string[] }[];
  maxHeight?: number;
};

export const AvailableSlotsField: FunctionComponent<AvailableSlotsFieldProps> = ({
  timeSlots,
  timezone,
  maxHeight,
}) => {
  const {
    input: { onChange, value },
  } = useField<DateTime[]>(AVAILABLE_SLOTS_NAME);

  const valueMap: Record<string, true> = {};
  value.forEach((dt) => {
    valueMap[dt.valueOf()] = true;
  });

  function toggleTimeSlot(newValue: DateTime) {
    onChange(
      valueMap[newValue.valueOf()]
        ? value.filter((dt) => dt.valueOf() !== newValue.valueOf())
        : [...value, newValue],
    );
  }

  return (
    <div>
      <MoTimeSlots
        timezone={timezone}
        timeSlots={timeSlots}
        onChange={toggleTimeSlot}
        isChecked={(currentDate) => !!valueMap[currentDate.valueOf()]}
        maxHeight={maxHeight}
      />
    </div>
  );
};
