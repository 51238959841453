import React, { useMemo, useState, SyntheticEvent } from 'react';
import { styled, TextField } from '@mui/material';
import { useField } from 'react-final-form';
import { DateTime } from 'luxon';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { generateTimePickerOptions, TimePickerOption } from './utils';
import { MoFieldErrorText } from '../MoFieldErrorText';

interface MoTimePickerProps {
  name: string;
  label?: string;
  disabled?: boolean;
  onChange?: (date: DateTime) => void;
  hideLabel?: boolean;
  hideError?: boolean;
  hasErrorOverride?: boolean;
  hidePopupIcon?: boolean;
  beforeTimeFilter?: DateTime;
  afterTimeFilter?: DateTime;
}

const StyledAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot {
    padding-right: ${({ popupIcon }) => (popupIcon ? '30px' : '0')};
  }

  .Mui-error.MuiAutocomplete-inputRoot {
    padding-right: 0;
  }
`;

export function MoTimePicker({
  name,
  label = 'Time',
  disabled = false,
  onChange: onChangeExternal = () => {},
  hideLabel,
  hideError,
  hasErrorOverride,
  hidePopupIcon,
  beforeTimeFilter,
  afterTimeFilter,
}: MoTimePickerProps) {
  const { input, meta } = useField<DateTime>(name);
  const { value, onChange } = input;
  const hasError = Boolean(hasErrorOverride || meta.error?.length);
  const [inputValue, setInputValue] = useState(value.toFormat('hh:mm a'));
  const options = useMemo(() => {
    const formattedSelectedValue = value.toFormat('hh:mm a');

    return generateTimePickerOptions({
      inputValue,
      unfiltered: inputValue === formattedSelectedValue,
      filterFunction: (timePickerOption) => {
        if (beforeTimeFilter && timePickerOption.value <= beforeTimeFilter) {
          return false;
        }
        if (afterTimeFilter && timePickerOption.value >= afterTimeFilter) {
          return false;
        }
        return true;
      },
    });
  }, [inputValue, value, afterTimeFilter, beforeTimeFilter]);

  const defaultValue = useMemo(
    () =>
      options.find((option) => {
        return value.hour === option.value.hour && value.minute === option.value.minute;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  if (!defaultValue) return null;

  return (
    <>
      <StyledAutocomplete
        options={options}
        defaultValue={defaultValue}
        inputValue={inputValue}
        autoSelect
        onInputChange={(_, value) => {
          setInputValue(value);
        }}
        // @ts-expect-error TS(2322): Type '(option: TimePickerOption, value: TimePicker... Remove this comment to see the full error message
        isOptionEqualToValue={(option: TimePickerOption, value: TimePickerOption) =>
          option.label === value.label
        }
        // Handled using the memoized function above
        filterOptions={(options) => options}
        noOptionsText="Please enter a valid time"
        autoHighlight
        disableClearable
        // @ts-expect-error TS(2322): Type '({ label }: { label: any; }) => any' is not ... Remove this comment to see the full error message
        getOptionLabel={({ label }) => label}
        renderInput={({ InputLabelProps, ...params }) => {
          return (
            <TextField
              {...params}
              name={name}
              label={hideLabel ? undefined : label}
              error={hasError}
            />
          );
        }}
        openOnFocus
        // @ts-expect-error TS(2322): Type '(_: SyntheticEvent, newValue: TimePickerOpti... Remove this comment to see the full error message
        onChange={(_: SyntheticEvent, newValue: TimePickerOption) => {
          const newDateTime = newValue.value;
          onChange(newDateTime);
          onChangeExternal(newDateTime);
        }}
        value={defaultValue}
        popupIcon={hidePopupIcon ? null : <KeyboardArrowDownIcon />}
        disabled={disabled}
      />
      {hasError && !hideError && <MoFieldErrorText>{meta.error[0]}</MoFieldErrorText>}
    </>
  );
}
