import auth0 from 'auth0-js';
import config, { ConfigType, setConfig, verifyConfig } from './config';
import { isTokenExpired } from './helpers';
import { exchangeCode, expireRefreshToken, signIn, signOut } from './services';
import { getAccessToken, setAccessToken } from './utils';

let auth0Client: auth0.WebAuth;

export function initAuth(config: ConfigType) {
  setConfig(config);
  auth0Client = new auth0.WebAuth({
    audience: config.audience,
    domain: config.domain,
    clientID: config.clientID,
    redirectUri: `${window.location.origin}/callback${
      config.motivoProduct === 'zeus' ? '?isAdmin=true' : ''
    }`,
    responseType: 'code',
    scope: 'openid profile offline_access',
  });
}

export function login({
  redirect,
  userId,
  organizationAdminId,
}: {
  redirect?: string;
  userId?: number;
  organizationAdminId?: number;
} = {}) {
  localStorage.setItem(
    'redirectAfterLogin',
    redirect || `${window.location.pathname}${window.location.search}`,
  );
  return config.workosEnabled ? signIn({ userId, organizationAdminId }) : auth0Client.authorize();
}

export async function exchangeCodeForAccessToken(code: string, isAdmin: boolean) {
  const response = await exchangeCode(code, isAdmin);
  setAccessToken(response.access_token);
}

export function loggedIn() {
  verifyConfig();
  const token = getAccessToken();
  return !!token && !isTokenExpired(token);
}

export async function logout() {
  const userId = localStorage.getItem('motivo_user_id');
  const organizationAdminId = localStorage.getItem('motivo_organization_admin_id');

  localStorage.removeItem('access_token');
  localStorage.removeItem('motivo_user_id');
  localStorage.removeItem('motivo_organization_admin_id');

  await expireRefreshToken();
  return config.workosEnabled
    ? signOut({ userId, organizationAdminId })
    : auth0Client.logout({
        returnTo: window.location.origin,
      });
}
