import React, { useState } from 'react';
import * as yup from 'yup';
import { Grid, Typography, Link, Box, Button, styled, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { DateTime } from 'luxon';
import { Form } from 'react-final-form';
import { getTimezoneWithDefaults, TimeZoneEnum } from '@src/utils/timeZoneUtils';
import { makeValidateSync } from '@src/utils/validationUtils';
import { MoDatePickerField } from '@src/components/MoDatePickerField';
import { MoTimePicker } from '@src/components/MoTimePicker';
import { useIntroRequestContext } from '../hooks/useIntroRequestContext';

const AlternateTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 0',

  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.palette.grey[800]}`,
  },
}));

const validator = makeValidateSync(
  yup.object().shape({
    date: yup.mixed<DateTime>(),
    time: yup.mixed<DateTime>(),
  }),
);
export type SuggestAlternateTimesProps = {
  onCancel: () => void;
};

/**
 * @deprecated
 */
export function DeprecatedSuggestAlternateTimes({ onCancel }: SuggestAlternateTimesProps) {
  const { viewer, suggestAlternateIntroRequestTimes, loading } = useIntroRequestContext();

  const [alternateTimes, setAlternateTimes] = useState<DateTime[]>([]);

  const timezone = getTimezoneWithDefaults(viewer.timezone as TimeZoneEnum);

  const handleSubmit = ({ date, time }: { date: DateTime; time: DateTime }) => {
    const alternateTime = time
      .set({
        year: date.year,
        month: date.month,
        day: date.day,
      })
      .setZone(timezone, { keepLocalTime: true });

    if (!alternateTimes.find((dateTime) => dateTime.equals(alternateTime))) {
      setAlternateTimes([...alternateTimes, alternateTime]);
    }
  };

  const removeAlternateTime = (dateTime: DateTime) => {
    setAlternateTimes([
      ...alternateTimes.filter((alternateTime) => !alternateTime.equals(dateTime)),
    ]);
  };

  const submitTimes = async () => {
    await suggestAlternateIntroRequestTimes(alternateTimes);
    onCancel();
  };

  const initialValue = DateTime.local().plus({ day: 1 }).startOf('day').set({ hour: 9, minute: 0 });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          date: initialValue,
          time: initialValue,
        }}
        validate={validator}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3}>
              <Typography variant="body3">Suggest alternate times</Typography>
              <Typography color="text.secondary">
                Please submit at least three different times
              </Typography>
            </Box>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={6}>
                <MoDatePickerField name="date" disablePast inputFormat="LL/dd/yyyy" />
              </Grid>
              <Grid item xs={6}>
                <MoTimePicker name="time" />
              </Grid>
            </Grid>

            <Link
              component="button"
              type="submit"
              color="secondary"
              fontWeight={600}
              underline="none"
            >
              + Add time
            </Link>
          </form>
        )}
      />

      {alternateTimes.length > 0 && (
        <Box sx={{ mt: 2, maxHeight: '200px', overflowY: 'scroll' }}>
          {alternateTimes.sort().map((dateTime) => (
            <AlternateTime key={dateTime.toISO()}>
              <Typography fontWeight={600}>
                {dateTime.toLocaleString({
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  timeZoneName: 'short',
                })}
              </Typography>
              <IconButton aria-label="delete" onClick={() => removeAlternateTime(dateTime)}>
                <ClearIcon />
              </IconButton>
            </AlternateTime>
          ))}
        </Box>
      )}

      <Grid container spacing={2} mt={2}>
        <Grid item xs={6}>
          <Button
            onClick={submitTimes}
            variant="contained"
            disabled={alternateTimes.length < 3 || loading}
            fullWidth
          >
            Submit times
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={onCancel} variant="outlined" color="neutral" fullWidth>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default function SuggestAlternateTimes({ onCancel }: SuggestAlternateTimesProps) {
  const { rescheduleForm } = useIntroRequestContext();

  if (rescheduleForm) {
    return <>{rescheduleForm}</>;
  }
  return <DeprecatedSuggestAlternateTimes onCancel={onCancel} />;
}
