'use client';

import React, { Children, cloneElement, ReactElement, ReactNode } from 'react';
import { Box, Menu, MenuItem, Button, styled, MenuItemProps } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledButton = styled(Button)({
  fontWeight: 600,
  fontSize: '1rem',
  padding: '0 16px',
});

const BaseMenuItem = <C extends React.ElementType>(props: MenuItemProps<C, { component?: C }>) => {
  return <MenuItem {...props} />;
};

export const HeaderMenuLink = styled(BaseMenuItem, {
  shouldForwardProp: (prop) => prop !== 'isLogout',
})<{ isLogout?: boolean }>(({ theme, isLogout }) => ({
  color: isLogout ? theme.palette.primary.main : theme.palette.text.primary,
  fontWeight: isLogout ? 600 : 400,
  fontSize: '1rem',
  padding: '12px 24px',
}));

type BaseHeaderLinkType = {
  text: string;
  isActive?: boolean;
  badgeCount?: number;
  isNew?: boolean;
  trackingId?: string;
};

export type PathLinkType = BaseHeaderLinkType & {
  path: string;
  url?: never;
  onClick?: never;
};

export type UrlLinkType = BaseHeaderLinkType & {
  url: string;
  path?: never;
  onClick?: never;
};

export type ButtonLinkType = BaseHeaderLinkType & {
  url?: never;
  path?: never;
  onClick: () => void;
};

export type HeaderLinkType = PathLinkType | UrlLinkType | ButtonLinkType | { divider: boolean };

export type HeaderMenuProps = { menuLinks: ReactElement[]; menuButton: ReactNode };

export default function HeaderMenu({ menuLinks, menuButton }: HeaderMenuProps) {
  return (
    <PopupState variant="popover" popupId="navbar-config-menu">
      {(popupState: any) => (
        <>
          <StyledButton {...bindTrigger(popupState)} color="neutral">
            {menuButton}
            <Box ml={1} display="flex" alignItems="center">
              <ArrowDropDownIcon />
            </Box>
          </StyledButton>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{ sx: { minWidth: 210 } }}
          >
            {Children.map(menuLinks, (child) => {
              if (child?.type === HeaderMenuLink) {
                return cloneElement(child, {
                  onClick: () => {
                    if ('onClick' in child.props) child.props.onClick();
                    popupState.close();
                  },
                });
              }
              return child;
            })}
          </Menu>
        </>
      )}
    </PopupState>
  );
}
