import { graphql } from ':src/graphql';

export const potentialChatParticipantsQuery = graphql(`
  query PotentialChatParticipantsQuery {
    potentialChatParticipants {
      result {
        id
        fullName
        firstName
        lastName
        smallAvatar
      }
    }
  }
`);

export const chatMessagesQuery = graphql(`
  query ChatMessagesQuery($chatId: String!, $cursor: String) {
    chatMessages(chatId: $chatId, cursor: $cursor) {
      nextCursor
      result {
        id
        createdAt
        chatId
        senderActorId
        text
      }
    }
  }
`);

export const chatQuery = graphql(`
  query ChatQuery($id: String!) {
    chat(id: $id) {
      id
      isActive
      participants {
        id
        fullName
        firstName
        lastName
        smallAvatar
      }
    }
  }
`);

export const chatsQuery = graphql(`
  query ChatsQuery {
    chats {
      id
      latestChatMessage {
        id
        text
        createdAt
      }
      participants {
        id
        fullName
        firstName
        lastName
        smallAvatar
      }
      isActive
    }
  }
`);

export const chatsWithUnreadMessagesQuery = graphql(`
  query ChatsWithUnreadMessagesQuery {
    chatsWithUnreadMessages {
      id
    }
  }
`);
