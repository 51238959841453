import { styled, Box } from '@mui/material';

const ChatsHeader = styled(Box)`
  height: ${({ theme }) => theme.spacing(14)};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  box-sizing: border-box;
  flex-shrink: 0;
`;

export default ChatsHeader;
