import { loggedIn } from '@motivo/styx/src/auth';
import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

export default function PublicGatedRoute(props: RouteProps) {
  const history = useHistory();

  useEffectOnce(() => {
    if (loggedIn()) {
      history.replace('/dashboard');
    }
  });

  return <Route {...props}></Route>;
}
