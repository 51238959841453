import { Button, ButtonProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useFormState } from 'react-final-form';

export function MoSubmitButton<T>(props: PropsWithChildren<ButtonProps>) {
  const {
    submitting: isSubmitting,
    invalid: isInvalid,
    submitFailed,
  } = useFormState<T>({
    subscription: {
      submitting: true,
      invalid: true,
      submitFailed: true,
    },
  });

  const shouldDisable = props.disabled || isSubmitting || (submitFailed && isInvalid);

  return (
    <Button
      type="submit"
      fullWidth={props.fullWidth ?? true}
      color="primary"
      variant="contained"
      {...props}
      disabled={shouldDisable}
    />
  );
}
