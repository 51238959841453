import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { MoSpacing } from '@src/components/MoSpacing';
import { getFormattedSessionDateTimeStrings } from '@src/utils/dateUtils';
import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';
import { Field } from 'react-final-form';
import { AVAILABLE_SLOTS_NAME } from './AvailableSlotsField';

export type SelectedTimeSlotsDisplayProps = {};

export const AvailableSlotsFieldDisplay: FunctionComponent<SelectedTimeSlotsDisplayProps> = () => {
  return (
    <Field<DateTime[]>
      name={AVAILABLE_SLOTS_NAME}
      render={({ input: { value, onChange } }) => {
        return (
          <MoSpacing y={2}>
            {value
              .sort((a, b) => {
                return a.valueOf() < b.valueOf() ? -1 : 1;
              })
              .map((slot) => {
                const { scheduledAtDateWithOrdinal, scheduledAtStartTime } =
                  getFormattedSessionDateTimeStrings(slot, 30);

                function deleteTimeSlot() {
                  onChange(value.filter((dt) => dt.valueOf() !== slot.valueOf()));
                }

                return (
                  <Box
                    key={slot.toISO()}
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <div>
                      {scheduledAtDateWithOrdinal} at {scheduledAtStartTime}
                    </div>
                    <IconButton onClick={deleteTimeSlot} size="small">
                      <Close />
                    </IconButton>
                  </Box>
                );
              })}
          </MoSpacing>
        );
      }}
    />
  );
};
