import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import * as Sentry from '@sentry/react';
import { EmptyOrLoadingState } from '@motivo/guanyin/src/components';
import { logout } from '../auth';

export default function Logout() {
  const posthog = usePostHog();

  useEffect(() => {
    posthog.reset();
    Sentry.configureScope((scope) => scope.setUser(null));
    logout();
  }, [posthog]);

  return <EmptyOrLoadingState loading />;
}
