import { loggedIn, login } from '@motivo/styx/src/auth';
import React from 'react';
import { RouteProps, Route } from 'react-router';

export default function AuthenticatedRoute(props: RouteProps) {
  if (!loggedIn()) {
    login();
    return null;
  }

  return <Route {...props} />;
}
