import { Alert, Box } from '@mui/material';
import { FormErrorScroll } from '@src/components/FormErrorScroll';
import { MoFieldErrorText } from '@src/components/MoFieldErrorText';
import { MoLoadingOverlay } from '@src/components/MoLoadingOverlay';
import { MoSpacing } from '@src/components/MoSpacing';
import { MoSubmitButton } from '@src/components/MoSubmitButton';
import {
  AvailableSlotsFieldDisplay,
  availableSlotsPickOnlyOneSchemaMap,
} from '@src/components/form-fields/AvailableSlotsField';
import {
  TimeZoneSelectField,
  initialTimeZone,
  timeZoneSchemaMap,
} from '@src/components/form-fields/TimeZoneSelectField';
import { getValidator, moZ } from '@src/utils/moZod';
import React, { FunctionComponent } from 'react';
import { Form } from 'react-final-form';
import { z } from 'zod';
import { useBookIntroCallProvider } from './BookIntroCallProvider';
import SelectDateFields from './SelectDateFields';

const formSchema = moZ.object({
  ...availableSlotsPickOnlyOneSchemaMap,
  ...timeZoneSchemaMap,
});

export type FormValues = z.infer<typeof formSchema>;

export type BookingFormProps = {
  readOnly?: boolean;
};

const BookingForm: FunctionComponent<BookingFormProps> = ({ readOnly }) => {
  const { onSubmit } = useBookIntroCallProvider();

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={{
        availableSlots: [],
        ...initialTimeZone(),
      }}
      validate={getValidator(formSchema)}
      render={({ handleSubmit, submitting, errors, submitFailed }) => (
        <form onSubmit={handleSubmit}>
          <MoLoadingOverlay isOpen={submitting} />
          <MoSpacing y={3}>
            <MoSpacing y={3}>
              <Box width={{ xs: '100%', sm: '50%' }}>
                <TimeZoneSelectField />
              </Box>

              <SelectDateFields />

              <AvailableSlotsFieldDisplay />

              {errors?.availableSlots && submitFailed && (
                <MoFieldErrorText>{errors?.availableSlots.join(', ')}</MoFieldErrorText>
              )}
            </MoSpacing>

            <Alert severity="info">
              Please note that clicking <strong>Schedule call</strong> will instantly book an intro
              call with this supervisor at your selected date and time.
            </Alert>
            <MoSubmitButton disabled={readOnly}>Schedule call</MoSubmitButton>
          </MoSpacing>

          <FormErrorScroll />
        </form>
      )}
    />
  );
};

export default BookingForm;
