import React from 'react';
import { useField } from 'react-final-form';
import {
  Box,
  BoxProps,
  FormControl,
  RadioGroup,
  RadioGroupProps,
  SxProps,
  Typography,
  styled,
} from '@mui/material';
import { MoFieldErrorText } from '../MoFieldErrorText';
import { MoRadioField } from '../MoRadioField';

interface StyledBoxProps extends BoxProps {
  checked?: boolean;
}

export const buttonCss = ({
  theme,
  checked,
  hasError,
}: {
  theme: any;
  checked?: boolean;
  hasError?: boolean;
}) => ({
  padding: checked ? '11px 15px' : '12px 16px',
  marginBottom: '8px',
  color: (() => {
    if (hasError) return theme.palette.text.secondary;
    if (checked) return '#265F81';
    return theme.palette.text.primary;
  })(),
  backgroundColor: checked ? '#CEE7EA' : theme.palette.common.white,
  borderColor: checked ? '#77C9DD' : '#A6A6A6',
  borderWidth: checked ? '2px' : '1px',
  fontSize: 16,
  fontWeight: 'normal',
  justifyContent: 'flex-start',
  transition: 'backgroundColor,color,borderColor 500ms',
});

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, checked }) => ({
  ...buttonCss({ theme, checked }),
  width: '100%',
  border: checked ? '2px solid #265F81' : '1px solid #A6A6A6',
  borderRadius: '5px',

  '&:hover': {
    backgroundColor: 'rgb(222, 239, 241, 0.5)',
    border: '2px solid #77C9DD',
  },
}));

export type MoRadioGroupProps = {
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
  outlined?: boolean;
  children:
    | React.ReactElement<typeof MoRadioField>
    | Array<React.ReactElement<typeof MoRadioField>>;
  labelSx?: SxProps;
} & RadioGroupProps;

export function MoRadioGroup({
  label,
  children,
  fullWidth = false,
  disabled = false,
  labelSx = {},
  outlined,
  ...radioGroupProps
}: MoRadioGroupProps) {
  const radios = Array.isArray(children) ? children : [children];
  const [selectedValue, setSelectedValue] = React.useState(radioGroupProps.defaultValue);

  // if (import.meta.env.MODE === 'development') {
  //   const radioNames = new Set<string>();
  //   for (const radio of radios) {
  //     radioNames.add(radio.props.name);
  //   }
  //   if (radioNames.size > 1) {
  //     throw new Error(
  //       `MoRadioGroup received multiple radio name props: ${[...radioNames].join(',')}`,
  //     );
  //   }
  // }

  const { name } = radios[0].props;
  const { meta } = useField(name, {
    subscription: { submitFailed: true, error: true, valid: true },
  });
  const hasError = meta.submitFailed && !meta.valid;

  return (
    <>
      <Typography sx={labelSx}>{label}</Typography>
      <FormControl fullWidth={fullWidth} component="fieldset" disabled={disabled}>
        <RadioGroup aria-label={label} {...radioGroupProps}>
          {radios.map((radio, index) => {
            const radioValue = (radio.props as any).value;
            return outlined ? (
              <StyledBox
                key={index}
                component="label"
                checked={selectedValue === radioValue}
                onChange={() => setSelectedValue(radioValue)}
              >
                {radio}
              </StyledBox>
            ) : (
              radio
            );
          })}
        </RadioGroup>
      </FormControl>
      {hasError && <MoFieldErrorText>{meta.error}</MoFieldErrorText>}
    </>
  );
}
