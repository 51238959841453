/* eslint-disable */
// This file has predominantly been lifted from mui-rff's source code, disable linter
// and trust the mui-rff maintainers
// https://github.com/lookfirst/mui-rff/blob/master/src/Validation.ts

import { ReactNode } from 'react';
import { Schema as YupSchema, ValidationError as YupValidationError } from 'yup';
import removeAccents from 'remove-accents';

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
function get(obj: any, path: string, defaultValue?: any) {
  const result = String.prototype.split
    .call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  return result === undefined || result === obj ? defaultValue : result;
}

// https://stackoverflow.com/questions/54733539/javascript-implementation-of-lodash-set-method
function set(obj: any, path: any, value: any) {
  if (Object(obj) !== obj) return obj; // When obj is not an object
  // If not yet an array, get the keys from the string-path
  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
  path.slice(0, -1).reduce(
    (
      a: any,
      c: any,
      i: number, // Iterate all of them except the last one
    ) =>
      Object(a[c]) === a[c] // Does the key exist and is its value an object?
        ? // Yes: then follow that path
          a[c]
        : // No: create the key. Is the next key a potential array-index?
          (a[c] =
            Math.abs(path[i + 1]) >> 0 === +path[i + 1]
              ? [] // Yes: assign a new array object
              : {}), // No: assign a new plain object
    obj,
  )[path[path.length - 1]] = value; // Finally assign the value to the last key
  return obj; // Return the top-level object to allow chaining
}

export type Translator = (errorObj: YupValidationError) => string | ReactNode;

export interface ValidationError {
  [key: string]: ValidationError | string;
}

function normalizeValidationError(
  err: YupValidationError,
  translator?: Translator,
): ValidationError {
  return err.inner.reduce((errors, innerError) => {
    const { path, message } = innerError;
    const el: ReturnType<Translator> = translator ? translator(innerError) : message;

    // eslint-disable-next-line no-prototype-builtins
    if (path && errors.hasOwnProperty(path)) {
      const prev = get(errors, path);
      prev.push(el);
      set(errors, path, prev);
    } else {
      set(errors, path, [el]);
    }
    return errors;
  }, {});
}

/**
 * Wraps the execution of a Yup schema to return an Promise<ValidationError>
 * where the key is the form field and the value is the error string.
 */
export function makeValidate<T>(validator: YupSchema<T>, translator?: Translator) {
  return async (values: T): Promise<ValidationError> => {
    try {
      await validator.validate(values, { abortEarly: false });
      return {};
    } catch (err) {
      return normalizeValidationError(err as YupValidationError, translator);
    }
  };
}

/**
 * Wraps the sync execution of a Yup schema to return an ValidationError
 * where the key is the form field and the value is the error string.
 */
export function makeValidateSync<T>(validator: YupSchema<T>, translator?: Translator) {
  return (values: T): ValidationError => {
    try {
      validator.validateSync(values, { abortEarly: false });
      return {};
    } catch (err) {
      return normalizeValidationError(err as YupValidationError, translator);
    }
  };
}

export const nameValidationMessage =
  'Names can only contain letters, spaces, dashes, and apostrophes';
export const nameValidationFunction = (value: string) =>
  /^[a-zA-Z- '’]+$/.test(removeAccents(value || ''));

export const formattedPhoneRegex =
  /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

export const zipcodeRegex = /^\d{5}(-\d{4})?$/;

export const MAX_WEEKLY_HOURS = 80;
