import { Box } from '@mui/material';
import { EmptyOrLoadingState } from '@src/components/EmptyOrLoadingState';
import { MoSelectAvailableDateCalendar } from '@src/components/MoSelectAvailableDateCalendar';
import { AvailableSlotsField } from '@src/components/form-fields/AvailableSlotsField';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useContext } from 'react';
import { useField } from 'react-final-form';
import invariant from 'tiny-invariant';
import { BookIntroCallContext } from './BookIntroCallProvider';

export type AvailableSlotsProps = {
  availableSlotsFieldMaxHeight?: number;
};

const SelectDateFields: FunctionComponent<AvailableSlotsProps> = ({
  availableSlotsFieldMaxHeight,
}) => {
  const context = useContext(BookIntroCallContext);
  invariant(context);
  const { availableWeekdays, timeSlots, timeslotsLoading, selectedDateTime, setSelectedDateTime } =
    context;

  const {
    input: { value: timezone },
  } = useField('timezone');

  return (
    <>
      <MoSelectAvailableDateCalendar
        availableWeekdays={availableWeekdays}
        onChange={(value: DateTime) => {
          setSelectedDateTime(value);
        }}
        value={selectedDateTime}
      />

      {timeslotsLoading ? (
        <Box sx={{ height: 200 }}>
          <EmptyOrLoadingState loading />
        </Box>
      ) : (
        <AvailableSlotsField
          timezone={timezone}
          timeSlots={timeSlots}
          maxHeight={availableSlotsFieldMaxHeight}
        />
      )}
    </>
  );
};

export default SelectDateFields;
