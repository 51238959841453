import React from 'react';
import { AvatarGroup, Box, Typography } from '@mui/material';
import { MoAvatar, SMALL_AVATAR_SIZE } from '@src/components/MoAvatar';
import ChatsHeader from './ChatsHeader';
import BackToChatsLink from './BackToChatsLink';
import { ChatActorType } from '../types';

type MessagesHeaderProps = {
  participantsMap: Map<number, ChatActorType>;
  viewerActorId: number;
};

const MessagesHeader = React.memo(function MessagesHeader({
  participantsMap,
  viewerActorId,
}: MessagesHeaderProps) {
  const otherParticipants = participantsMap
    ? [...participantsMap.values()].filter((participant) => participant.id !== viewerActorId)
    : [];

  return (
    <ChatsHeader pl={4}>
      <Box display="flex" gap={2} alignItems="center">
        <Box display={{ md: 'none' }}>
          <BackToChatsLink />
        </Box>
        <Box display={{ xs: 'none', md: 'block' }}>
          <AvatarGroup>
            {otherParticipants.map((participant) => (
              <MoAvatar size={SMALL_AVATAR_SIZE} key={participant.id} user={participant} />
            ))}
          </AvatarGroup>
        </Box>
        <Typography variant="h3">
          {otherParticipants.map((participant) => participant.fullName).join(', ')}
        </Typography>
      </Box>
    </ChatsHeader>
  );
});

export default MessagesHeader;
