import React, { ReactNode } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import theme from '@motivo/guanyin/src/theme';

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        fontFamily: ['proxima-nova', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        color: '#434343',
        margin: 0,
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        textAlign: 'left',
        backgroundColor: '#fff',
      },
      a: {
        textDecoration: 'none',
      },
      strong: {
        fontWeight: 600,
      },
      img: {
        maxWidth: '100%',
      },
      '.SnackbarItem-formSaved': {
        minWidth: 'unset !important',
        width: 'fit-content',
        backgroundColor: `${theme.palette.primary.lightest} !important`,
        color: `${theme.palette.primary.dark} !important`,
        fontWeight: '700 !important' as any,
      },
    }}
  />
);

export const StylesAndThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <StyledEngineProvider injectFirst>
      {globalStyles}
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
