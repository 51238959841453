import { Box, CircularProgress } from '@mui/material';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MessagesGroupedByDate, addMessageToGroupedMessages } from '../utils';
import NewMessagesPill from './NewMessagesPill';
import ChatsContext from '../ChatsContext';
import { ChatActorType, ChatMessageType } from '../types';
import MessagesBoxView from './MessagesBoxView';

export type MessagesBoxProps = {
  setIsMessagesEndInView: (isInView: boolean) => void;
  participantsMap: Map<number, ChatActorType>;
};

export interface MessagesBoxRef {
  scrollToBottom: () => void;
}

const MessagesBox = forwardRef<MessagesBoxRef, MessagesBoxProps>(
  ({ setIsMessagesEndInView, participantsMap }, ref) => {
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [isNewMessagesPillVisible, setIsNewMessagesPillVisible] = useState(false);
    const {
      // @ts-expect-error TS(2339): Property 'activeChatId' does not exist on type 'Ch... Remove this comment to see the full error message
      activeChatId,
      // @ts-expect-error TS(2339): Property 'chatMessagesNextCursor' does not exist o... Remove this comment to see the full error message
      chatMessagesNextCursor,
      // @ts-expect-error TS(2339): Property 'fetchMoreChatMessages' does not exist on... Remove this comment to see the full error message
      fetchMoreChatMessages,
      // @ts-expect-error TS(2339): Property 'onNewMessage' does not exist on type 'Ch... Remove this comment to see the full error message
      onNewMessage,
      // @ts-expect-error TS(2339): Property 'offNewMessage' does not exist on type 'C... Remove this comment to see the full error message
      offNewMessage,
      // @ts-expect-error TS(2339): Property 'chatMessages' does not exist on type 'Ch... Remove this comment to see the full error message
      chatMessages,
      // @ts-expect-error TS(2339): Property 'viewerActor' does not exist on type 'Cha... Remove this comment to see the full error message
      viewerActor,
    } = useContext(ChatsContext);

    const containerRef = useRef<HTMLInputElement>(null);
    const loaderRef = useRef<HTMLInputElement>(null);
    const lastMessageRef = useRef<HTMLSpanElement | null>(null);
    const isLastMessageVisibleRef = useRef(false);
    const messagesRef = useRef<(HTMLElement | null)[]>([]);
    const [unreadMessageIds, setUnreadMessageIds] = useState<Set<string>>(new Set());

    const groupedMessages = useMemo(() => {
      const newGroupedMessages: MessagesGroupedByDate[] = [];

      for (const message of chatMessages) {
        addMessageToGroupedMessages(message, newGroupedMessages, participantsMap);
      }

      return newGroupedMessages;
    }, [chatMessages, participantsMap]);

    useEffect(() => {
      if (!messagesRef.current) return undefined;

      const messagesObserver = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setUnreadMessageIds((prev) => {
              const newSet = new Set(prev);
              // @ts-expect-error TS(2812): Property 'dataset' does not exist on type 'HTMLEle... Remove this comment to see the full error message
              newSet.delete((entry.target as HTMLElement).dataset.id);
              return newSet;
            });
            messagesObserver.unobserve(entry.target);
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.8,
        },
      );

      setTimeout(() => {
        for (const messageNode of messagesRef.current.filter(
          // @ts-expect-error TS(2812): Property 'dataset' does not exist on type 'HTMLEle... Remove this comment to see the full error message
          (ref) => ref && unreadMessageIds.has(ref.dataset.id),
        )) {
          messagesObserver.observe(messageNode as $TSFixMe);
        }
      }, 0);

      return () => {
        messagesObserver.disconnect();
      };
    }, [unreadMessageIds]);

    const scrollToBottom = () => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    };

    useImperativeHandle(ref, () => ({
      scrollToBottom,
    }));

    useEffect(() => {
      const lastMessageObserver = new IntersectionObserver(
        ([entry]) => {
          setIsMessagesEndInView(entry.intersectionRatio === 1);
          isLastMessageVisibleRef.current = entry.isIntersecting;
        },
        { root: null, rootMargin: '0px', threshold: [0, 1] },
      );

      setTimeout(() => {
        if (lastMessageRef.current) {
          lastMessageObserver.observe(lastMessageRef.current);
        }
      }, 0);

      return () => {
        lastMessageObserver.disconnect();
      };
    }, [groupedMessages, setIsMessagesEndInView]);

    useEffect(() => {
      const listener = (message: ChatMessageType) => {
        if (viewerActor.id === message.senderActorId || isLastMessageVisibleRef.current) {
          setTimeout(scrollToBottom, 0);
        } else {
          setUnreadMessageIds((prev) => {
            const newSet = new Set(prev);
            newSet.add(message.id);
            return newSet;
          });
          setIsNewMessagesPillVisible(true);
        }
      };
      onNewMessage(listener);

      return () => {
        offNewMessage(listener);
      };
    }, [onNewMessage, viewerActor, offNewMessage]);

    useEffect(() => {
      if (!chatMessagesNextCursor) {
        return undefined;
      }

      const observer = new IntersectionObserver(
        async ([entry]) => {
          if (entry.isIntersecting && chatMessagesNextCursor && !isFetchingMore) {
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            const prevScrollTop = containerRef.current.scrollTop;
            // Subtracting loader height which will be 0 after load
            const prevScrollHeight =
              // @ts-expect-error TS(2531): Object is possibly 'null'.
              containerRef.current.scrollHeight - loaderRef.current.scrollHeight;
            setIsFetchingMore(true);
            try {
              await fetchMoreChatMessages({
                variables: {
                  chatId: activeChatId,
                  cursor: chatMessagesNextCursor,
                },
              });
            } finally {
              setIsFetchingMore(false);
            }
            // Maintain the scroll position by calculating the difference in the new scroll height
            // of the box vs where we were before
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            const newScrollHeight = containerRef.current.scrollHeight;
            const scrollDifference = newScrollHeight - prevScrollHeight;
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            containerRef.current.scrollTop = prevScrollTop + scrollDifference;
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
        },
      );
      observer.observe(loaderRef.current as $TSFixMe);

      return () => {
        observer.disconnect();
      };
    }, [chatMessagesNextCursor, fetchMoreChatMessages, isFetchingMore, activeChatId]);

    return (
      <Box pt={4} boxSizing="border-box" flex={1} overflow="auto" ref={containerRef}>
        {unreadMessageIds.size > 0 && isNewMessagesPillVisible && (
          <NewMessagesPill
            onClick={scrollToBottom}
            count={unreadMessageIds.size}
            onDismiss={() => setIsNewMessagesPillVisible(false)}
          />
        )}
        <Box ref={loaderRef}>
          {isFetchingMore && (
            <Box display="flex" justifyContent="center" alignItems="center" pt={2} pb={4}>
              <CircularProgress size={24} />
            </Box>
          )}
        </Box>
        <MessagesBoxView
          groupedMessages={groupedMessages}
          setLastMessageRef={(node) => {
            lastMessageRef.current = node;
          }}
          setMessagesRef={(index, node) => {
            messagesRef.current[index] = node;
          }}
        />
      </Box>
    );
  },
);

export default MessagesBox;
