import React from 'react';
import { Link } from '@mui/material';
import errorSrc from './img/error.svg';
import ErrorLayout from '../components/ErrorLayout';

export default function ErrorPage() {
  return (
    <ErrorLayout
      title="Sorry, something went wrong on our end!"
      body={
        <>
          In the meantime, you can refresh the page, come back later, or get in touch with us at{' '}
          <Link color="secondary" href="mailto:support@motivohealth.com" underline="hover">
            support@motivohealth.com
          </Link>
        </>
      }
      imageSrc={errorSrc}
    />
  );
}
