'use client';

import React from 'react';
import Container from '@mui/material/Container';
import { Box, css, styled } from '@mui/material';

export const PAGE_CONTAINER_BACKGROUND_COLOR = '#f4f2f6';

const Background = styled('div', {
  shouldForwardProp: (prop: string) => !['useMobileStyles', 'hasWhiteBackground'].includes(prop),
})<{ useMobileStyles: boolean; hasWhiteBackground: boolean }>`
  width: 100%;
  background-color: ${({ hasWhiteBackground }) => (hasWhiteBackground ? '#fff' : '#f4f2f6')};
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    background-color: ${({ useMobileStyles, hasWhiteBackground }) =>
      hasWhiteBackground || useMobileStyles ? '#fff' : PAGE_CONTAINER_BACKGROUND_COLOR};
  }
`;

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => !['hideTopPadding', 'centered'].includes(prop as string),
})<{ hideTopPadding?: boolean; centered: boolean }>`
  padding: 3rem 1.5rem;
  padding-top: ${(props) => (props.hideTopPadding ? '' : '3rem')};
  ${(props) =>
    props.centered
      ? css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `
      : ''}
`;

export type MoPageContainerProps = React.ComponentProps<typeof Container> & {
  hideTopPadding?: boolean;
  useMobileStyles?: boolean;
  hasWhiteBackground?: boolean;
  preContent?: React.ReactNode;
  centered?: boolean;
};

export function MoPageContainer({
  maxWidth = false,
  fixed = false,
  children = null,
  hideTopPadding = false,
  useMobileStyles = false,
  hasWhiteBackground = false,
  preContent,
  centered = false,
}: MoPageContainerProps) {
  return (
    <Box width="100%" height="100%">
      {preContent && <div>{preContent}</div>}
      <Background useMobileStyles={useMobileStyles} hasWhiteBackground={hasWhiteBackground}>
        <StyledContainer
          centered={centered}
          fixed={fixed}
          maxWidth={maxWidth}
          hideTopPadding={hideTopPadding}
        >
          {children}
        </StyledContainer>
      </Background>
    </Box>
  );
}
