import { styled, CircularProgress, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { MoSpacing } from '../MoSpacing';
import biking from './biking.svg';

const EmptyStateWrapper = styled('div')`
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

type EmptyStateProps =
  | {
      loading: true;
      title?: never;
      text?: never;
      image?: never;
      children?: never;
    }
  | {
      loading?: never;
      title?: string;
      text?: string;
      image?: ReactNode;
      children?: ReactNode;
    };

export function EmptyOrLoadingState({
  loading,
  title,
  text,
  children,
  image = <img src={biking} alt="" />,
}: EmptyStateProps) {
  return (
    <EmptyStateWrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <MoSpacing y={2}>
          {image}
          {title && <Typography variant="h2">{title}</Typography>}
          {text && <Typography>{text}</Typography>}
          {children}
        </MoSpacing>
      )}
    </EmptyStateWrapper>
  );
}
