import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';

type CalendarDayProps = PickersDayProps<DateTime> & { availableWeekdays: number[] };

export const CalendarDay: FunctionComponent<CalendarDayProps> = ({
  availableWeekdays,
  ...rest
}) => {
  const mayHaveAvailability =
    availableWeekdays.includes(rest.day.weekday) && DateTime.now() < rest.day;

  return (
    <PickersDay
      data-cy={!mayHaveAvailability ? 'calendar-day-disabled' : 'calendar-day'}
      {...rest}
      sx={
        mayHaveAvailability
          ? {
              bgcolor: (theme) => theme.palette.primary.lighter,
              transition: 'background-color 0.2s',
              color: (theme) => theme.palette.primary.main,
              fontWeight: 'bold',
            }
          : {}
      }
      disabled={!mayHaveAvailability}
    />
  );
};
