import decode from 'jwt-decode';
import config from './config';

export function getTokenExpirationDate(token: string) {
  const decoded = decode<{ exp: number }>(token);
  if (!decoded.exp) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isAuthProviderMismatch(token: string) {
  const decoded = decode<{ sub: string }>(token);
  if (!decoded.sub) {
    return null;
  }

  return (
    (config.workosEnabled && decoded.sub.startsWith('auth0|')) ||
    (!config.workosEnabled && decoded.sub.startsWith('user_'))
  );
}

export function isTokenExpired(token: string) {
  if (isAuthProviderMismatch(token)) return true;

  const date = getTokenExpirationDate(token);
  if (date === null) {
    return false;
  }

  return !(date.valueOf() > new Date().valueOf());
}
