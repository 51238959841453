import { UserNotificationTriggerEnum } from '@motivo/guanyin/src/lookup';
import { Box } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import SettingsHeader from '../SettingsHeader';

export const CheckboxContainer: FunctionComponent<{
  sx?: React.CSSProperties;
  children: ReactNode;
}> = ({ sx, children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export const NotificationTypeHeader: FunctionComponent<{
  sx?: React.CSSProperties;
  children: ReactNode;
}> = ({ sx, children }) => {
  return (
    <SettingsHeader variant="body1" sx={{ fontWeight: 'bold', justifyContent: 'center', ...sx }}>
      {children}
    </SettingsHeader>
  );
};

export function shouldRenderSmsCheckbox(userNotificationTriggerId: number): boolean {
  return [UserNotificationTriggerEnum.OPPORTUNITY_RECEIVED].includes(userNotificationTriggerId);
}
