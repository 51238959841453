import { Chip } from '@mui/material';
import React from 'react';

export type MoNotificationBadgeProps = {
  children?: React.ReactNode;
};

export function MoNotificationBadge({ children }: MoNotificationBadgeProps) {
  return (
    <Chip
      size="small"
      label={children}
      sx={[
        {
          height: '16px',
          backgroundColor: 'error.main',
          color: 'white',
          fontWeight: 'bold',
          fontSize: 11,
        },
        !!children && {
          '& span': {
            padding: '6px',
          },
        },
      ]}
    />
  );
}
