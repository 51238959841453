import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Box, Typography, styled } from '@mui/material';
import Confetti from 'react-dom-confetti';
import zIndex from '@mui/material/styles/zIndex';
import Lottie from 'lottie-react';
import { MoButton } from '@src/components/MoButton';
// @ts-ignore: $TSFixMe. need to update tsconfig somehow to allow this import. can't use @ts-expect-error
import animationData from './match.json';

const ConfettiContainer = styled('div')({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: zIndex.modal + 1,
  pointerEvents: 'none',
});

export type MatchCelebrationModalProps = {
  isCelebrationModalOpen: boolean;
  setIsCelebrationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewerFirstName: string;
  organizationName?: string;
  otherPartyFirstName?: string;
  isSupervisor: boolean;
  superviseeFirstName?: string;
};

export default function MatchCelebrationModal(props: MatchCelebrationModalProps) {
  const {
    isCelebrationModalOpen,
    setIsCelebrationModalOpen,
    viewerFirstName,
    isSupervisor,
    organizationName,
    otherPartyFirstName,
  } = props;
  const [isConfettiShowing, setIsConfettiShowing] = useState(false);
  const isOrganizationAdmin = organizationName && !isSupervisor;

  useEffect(() => {
    if (isCelebrationModalOpen) {
      setTimeout(() => {
        setIsConfettiShowing(true);
      }, 100);
    }
  }, [isCelebrationModalOpen, isConfettiShowing]);

  return (
    <>
      <Dialog
        open={isCelebrationModalOpen}
        onClose={() => setIsCelebrationModalOpen(false)}
        fullWidth
        PaperProps={{ sx: { maxWidth: '350px' } }}
      >
        <DialogContent>
          <Lottie loop autoplay animationData={animationData} />
          {!organizationName && (
            <>
              <Typography mb={2} fontWeight={700} variant="h3">
                It’s a match, {viewerFirstName}!
              </Typography>
              <Typography mb={3}>
                {isSupervisor
                  ? `Congratulations. We don’t take lightly that supervision will mean so much for ${otherPartyFirstName}, their clients, and the world.`
                  : `Congratulations, ${viewerFirstName}! We know how important finding the right supervisor is to navigating your 2-year journey. Here’s to a new and exciting relationship!`}{' '}
              </Typography>
            </>
          )}
          {organizationName && isSupervisor && (
            <>
              <Typography mb={1} fontWeight={700} variant="h3">
                Congratulations! You are now connected with{' '}
                <Typography fontWeight={700} variant="h3" color="primary" component="span">
                  {organizationName}.
                </Typography>
              </Typography>
              <Typography mb={3}>
                This means that they can start matching supervisees to you, in which you can
                approve. You’ll find them in your pending tab on the <b>Supervisees</b> page.
              </Typography>
            </>
          )}

          {isOrganizationAdmin && (
            <>
              <Box mb={3}>
                <Typography fontWeight={700} variant="h3" mb={4}>
                  Congratulations! You can now match your supervisees with
                  {otherPartyFirstName}.
                </Typography>
                <Typography fontWeight={700} variant="h6">
                  What’s next
                </Typography>
                <Typography ml={-2}>
                  <ul>
                    <li>Add your supervisees</li>
                    <li>Match them with your supervisors</li>
                    <li>Once they approve, you’re ready to start supervision.</li>
                  </ul>
                </Typography>
              </Box>
              <MoButton href="/supervisees" variant="contained" fullWidth>
                Add your supervisees
              </MoButton>
            </>
          )}

          {!isOrganizationAdmin && (
            <MoButton
              variant="contained"
              color="primary"
              onClick={() => setIsCelebrationModalOpen(false)}
              fullWidth
            >
              Continue
            </MoButton>
          )}
        </DialogContent>
      </Dialog>

      <ConfettiContainer>
        <Confetti
          active={isConfettiShowing}
          config={{
            spread: 360,
            elementCount: 200,
            startVelocity: 50,
          }}
        />
      </ConfettiContainer>
    </>
  );
}
