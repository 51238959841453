import StyledSnackbarProvider from ':src/StyledSnackbarProvider';
import { EmptyOrLoadingState } from '@motivo/guanyin/src/components';
import { useParseAndStoreUTMParams } from '@motivo/guanyin/src/hooks/useParseAndStoreUTMParams';
import authRoutes from '@motivo/styx/src/authRoutes';
import useSetUpAnalytics from '@motivo/styx/src/useSetUpAnalytics';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { Suspense, lazy } from 'react';
import ReactGA from 'react-ga';
import { Redirect, Route, Switch } from 'react-router-dom';
import { viewerQuery } from ':src/domains/auth/queries';
import { ResultOf } from 'gql.tada';
import Layout from './Layout';
import { StylesAndThemeProvider } from './StylesAndThemeProvider';
import Loading from './components/Loading/Loading';
import useViewer from './domains/auth/hooks/useViewer';
import ErrorBoundary from './domains/errors/ErrorBoundary';
import {
  createSessionPagePath,
  editSessionPagePath,
} from './domains/sessions/CreateSessionPage/routePaths';
import {
  successfullyBookedSessionPagePath,
  successfullyEditedSessionPagePath,
} from './domains/sessions/CreateSessionSuccessPage/routePaths';
import {
  superviseeCreateSessionPagePath,
  superviseeSuccessfullyBookedSessionPagePath,
  superviseeSuccessfullyProposedSessionPagePath,
} from './domains/sessions/SuperviseeCreateSessionPage/routePaths';
import { notificationsPagePath } from './domains/settings/NotificationsPage';
import SettingsLayout from './domains/settings/SettingsLayout';
import CalendarReconnectModal from './domains/settings/components/CalendarReconnectModal';
import { QuestionnaireState } from './domains/supervisee-onboarding/Questionnaire/QuestionnaireState';
import { supervisorOnboardingPaths } from './domains/supervisor-onboarding/supervisorOnboardingPaths';
import ScrollToTop from './legacy-helpers/ScrollToTop';
import redirects from './redirects';
import AuthenticatedRoute from './routing/AuthenticatedRoute';
import QuestionnaireGatedRoute from './routing/QuestionnaireGatedRoute';
import PublicGatedRoute from './routing/PublicGatedRoute';

const SuperviseesPage = lazy(() => import('./domains/supervisees/SuperviseesPage'));
const NotFoundPage = lazy(() => import('./domains/errors/NotFoundPage'));
const StripeOnboardRefreshPage = lazy(() => import('./domains/payments/StripeOnboardRefreshPage'));
const OrganizationOnboardingPage = lazy(
  () => import('./domains/organization-onboarding/OrganizationOnboardingPage'),
);

// ----------------------------------------
// Supervisor application
// ----------------------------------------
const SupervisorApplicationPage = lazy(
  () => import('./domains/supervisor-onboarding/SupervisorApplicationPage'),
);
// ----------------------------------------
// Supervisor onboarding
// ----------------------------------------
const SupervisorGatedRoute = lazy(
  () => import('./domains/supervisor-onboarding/SupervisorGatedRoute'),
);
const Step0AccountCreationPage = lazy(
  () => import('./domains/supervisor-onboarding/Step0AccountCreationPage'),
);
const Step1_1QualificationsPage = lazy(
  () => import('./domains/supervisor-onboarding/Step1_1QualificationsPage'),
);
// TODO bowen v2 - remove after release
// https://linear.app/motivo/issue/MOT-7364/cleanup-feature-flags-after-launch
const Step1_2QualificationsPage = lazy(
  () => import('./domains/supervisor-onboarding/Step1_2QualificationsPage'),
);
const Step2OpportunitiesPage = lazy(
  () => import('./domains/supervisor-onboarding/Step2OpportunitiesPage'),
);
const Step3AvailabilitiesPage = lazy(
  () => import('./domains/supervisor-onboarding/Step3AvailabilitiesPage'),
);
const Step4AgreementsPage = lazy(
  () => import('./domains/supervisor-onboarding/Step4AgreementsPage'),
);
const Step5ProfilePage = lazy(() => import('./domains/supervisor-onboarding/Step5ProfilePage'));
const Step6PaymentPage = lazy(() => import('./domains/supervisor-onboarding/Step6PaymentPage'));
const SupervisorOnboardingSuccessPage = lazy(
  () => import('./domains/supervisor-onboarding/SupervisorOnboardingSuccessPage'),
);

const DashboardHomePage = lazy(() => import('./domains/sessions/DashboardHomePage'));
const SessionsPage = lazy(() => import(':src/domains/sessions/SessionsPage'));
const AdministrativeHoursPage = lazy(() => import(':src/domains/sessions/AdministrativeHoursPage'));
const IntroRequestsPage = lazy(() => import(':src/domains/introRequests/IntroRequestsPage'));
const BookIntroRequestPage = lazy(() => import(':src/domains/introRequests/BookIntroRequestPage'));
const IntroRequestDetailsPage = lazy(
  () => import(':src/domains/introRequests/IntroRequestDetailsPage'),
);
const JoinIntroCallPage = lazy(() => import('./domains/introRequests/JoinIntroCallPage'));

const CreateSessionPage = lazy(() => import(':src/domains/sessions/CreateSessionPage'));
const EditSessionPage = lazy(() => import(':src/domains/sessions/EditSessionPage/'));
const CancelSessionPage = lazy(() => import(':src/domains/sessions/CancelSessionPage/'));
const CreateSessionSuccessPage = lazy(
  () => import(':src/domains/sessions/CreateSessionSuccessPage'),
);

const SuperviseeHoursPage = lazy(() => import(':src/domains/supervisees/SuperviseeHoursPage'));
const SuperviseeGoalsPage = lazy(() => import(':src/domains/supervisees/SuperviseeGoalsPage'));
const MatchDocumentsPage = lazy(() => import(':src/domains/users/MatchDocumentsPage'));

const SuperviseeCreateSessionPage = lazy(
  () => import(':src/domains/sessions/SuperviseeCreateSessionPage'),
);
const SuperviseeSuccessfullyBookedSessionPage = lazy(
  () =>
    import(
      ':src/domains/sessions/SuperviseeCreateSessionPage/SuperviseeSuccessfullyBookedSessionPage'
    ),
);
const SuperviseeSuccessfullyProposedSessionPage = lazy(
  () =>
    import(
      ':src/domains/sessions/SuperviseeCreateSessionPage/SuperviseeSuccessfullyProposedSessionPage'
    ),
);

const SessionDetailsPage = lazy(() => import(':src/domains/sessions/SessionDetailsPage'));
const ContactPage = lazy(() => import(':src/domains/global/ContactPage'));
const HomePage = lazy(() => import('./domains/auth/HomePage'));
const GeneralSettingsPage = lazy(() => import('./domains/settings/GeneralPage'));
const CalendarSettingsPage = lazy(() => import('./domains/settings/CalendarPage'));
const ProfileSettingsPage = lazy(() => import('./domains/settings/ProfilePage'));
const PreferencesSettingsPage = lazy(() => import('./domains/settings/PreferencesPage'));
const NotificationsPage = lazy(() => import('./domains/settings/NotificationsPage'));
const EmailChangeSuccessPage = lazy(
  () => import('./domains/settings/EmailChangeSuccessPage/index'),
);
const LicensesPage = lazy(() => import('./domains/settings/LicensesPage'));
const PaymentPage = lazy(() => import('./domains/settings/PaymentPage'));
const NewPaymentPage = lazy(() => import('./domains/settings/NewPaymentPage'));
const AgreementsSettingsPage = lazy(() => import('./domains/settings/AgreementsPage'));

// Supervisee onboarding
const ThankYouPage = lazy(() => import('./domains/supervisee-onboarding/ThankYouPage'));
const CreateAccountPage = lazy(() => import('./domains/supervisee-onboarding/CreateAccountPage'));
const VerifyEmail = lazy(() => import('./domains/supervisee-onboarding/VerifyEmail'));
const AddPaymentPage = lazy(() => import('./domains/supervisee-onboarding/AddPaymentPage'));
const PrepForCallPage = lazy(() => import('./domains/supervisee-onboarding/PrepForCallPage'));
const ScheduleCallPage = lazy(() => import('./domains/supervisee-onboarding/ScheduleCallPage'));
const ScheduleCallSuccessPage = lazy(
  () => import('./domains/supervisee-onboarding/ScheduleCallSuccessPage'),
);
const SearchPage = lazy(() => import('./domains/supervisee-onboarding/SearchPage'));
const SupervisorProfilePage = lazy(
  () => import('./domains/supervisee-onboarding/SupervisorProfilePage'),
);
const OrganizationSuperviseeOnboardingRedirectPage = lazy(
  () => import('./domains/supervisee-onboarding/OrganizationSuperviseeOnboardingRedirectPage'),
);

const OrganizationSuperviseeOnboardingPage = lazy(
  () => import('./domains/supervisee-onboarding/OrganizationSuperviseeOnboardingPage'),
);

const ChatsPage = lazy(() => import('./domains/messages/ChatsPage'));
const ApproveSuperviseePage = lazy(() => import('./domains/users/ApproveSuperviseePage'));

const OrganizationOnboardingSuccessPage = lazy(
  () => import('./domains/organization-onboarding/components/OrganizationOnboardingSuccessPage'),
);
const RequestRecommendationsPage = lazy(
  () => import('./domains/recommendations/RequestRecommendationsPage'),
);
const WriteRecommendationPage = lazy(
  () => import('./domains/recommendations/WriteRecommendationPage'),
);
const ModerateRecommendationPage = lazy(
  () => import('./domains/recommendations/ModerateRecommendationPage'),
);
const OpportunitiesPage = lazy(() => import('./domains/opportunities/OpportunitiesPage'));
const OpportunityPage = lazy(() => import('./domains/opportunities/OpportunityPage'));
const DeclineOpportunityPage = lazy(() => import('./domains/opportunities/DeclineOpportunityPage'));
const ApproveOpportunityPage = lazy(() => import('./domains/opportunities/ApproveOpportunityPage'));
const JoinSessionPageWrapper = lazy(
  () => import('./domains/sessions/JoinSessionPage/JoinSessionPageWrapper'),
);
const PostSessionPage = lazy(() => import('./domains/sessions/PostSessionPage'));
const ConfirmEmailChangePage = lazy(() => import('./domains/settings/ConfirmEmailChangePage'));

const MicrosoftOAuthCallback = lazy(() => import('./domains/auth/MicrosoftOAuthCallback'));

ReactGA.initialize('UA-117505136-1');

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
  stripeAccount: import.meta.env.VITE_STRIPE_ACCOUNT_ID,
});

const GOOGLE_CLIENT_ID = '582854454261-kcj75h25ndssgh5ol28071arm1ss4f4j.apps.googleusercontent.com';

function App() {
  useParseAndStoreUTMParams();
  // Preload the viewer so it can be assumed to be available everywhere.
  const { viewer, refetch } = useViewer();
  useSetUpAnalytics<ResultOf<typeof viewerQuery>['viewer']>({
    user: viewer,
    getAdditionalProperties: (user) => ({
      userType: user.supervisorProfile ? 'supervisor' : 'supervisee',
    }),
  });

  return (
    // @ts-ignore
    <Elements stripe={stripePromise}>
      <ScrollToTop>
        <StylesAndThemeProvider>
          <QuestionnaireState.Provider>
            <StyledSnackbarProvider>
              <Suspense fallback={<Loading />}>
                <ErrorBoundary>
                  <Switch>
                    {redirects}
                    <Route path="/" exact component={HomePage} />
                    <QuestionnaireGatedRoute path="/search">
                      <SearchPage />
                    </QuestionnaireGatedRoute>
                    <Route path="/employer-search">
                      <SearchPage isEmployer />
                    </Route>
                    <Route path="/supervisor/:slug">
                      <SupervisorProfilePage />
                    </Route>
                    <QuestionnaireGatedRoute path="/signup/create-account">
                      <CreateAccountPage />
                    </QuestionnaireGatedRoute>
                    <Route path="/signup/thank-you" component={ThankYouPage} />
                    <Route path="/signup/verify-email" component={VerifyEmail} />
                    <AuthenticatedRoute path="/signup/add-payment">
                      <AddPaymentPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/signup/prep-for-call" exact>
                      <PrepForCallPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/signup/schedule-call" exact>
                      <ScheduleCallPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/signup/schedule-call-success" exact>
                      <ScheduleCallSuccessPage />
                    </AuthenticatedRoute>
                    <PublicGatedRoute path="/supervisor-apply">
                      <SupervisorApplicationPage />
                    </PublicGatedRoute>
                    {/* --------------------------- */}
                    {/* supervisor onboarding start */}
                    {/* --------------------------- */}
                    <AuthenticatedRoute path={supervisorOnboardingPaths.step_1} exact>
                      <SupervisorGatedRoute>
                        <Step1_1QualificationsPage />
                      </SupervisorGatedRoute>
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={supervisorOnboardingPaths.step_1} exact>
                      <SupervisorGatedRoute>
                        <Step1_2QualificationsPage />
                      </SupervisorGatedRoute>
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={supervisorOnboardingPaths.step_2} exact>
                      <SupervisorGatedRoute>
                        <Step2OpportunitiesPage />
                      </SupervisorGatedRoute>
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={supervisorOnboardingPaths.step_3} exact>
                      <SupervisorGatedRoute>
                        <Step3AvailabilitiesPage />
                      </SupervisorGatedRoute>
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={supervisorOnboardingPaths.step_4} exact>
                      <SupervisorGatedRoute>
                        <Step4AgreementsPage />
                      </SupervisorGatedRoute>
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={supervisorOnboardingPaths.step_5} exact>
                      <SupervisorGatedRoute>
                        <Step5ProfilePage />
                      </SupervisorGatedRoute>
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={supervisorOnboardingPaths.step_6} exact>
                      <SupervisorGatedRoute>
                        <Step6PaymentPage />
                      </SupervisorGatedRoute>
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={supervisorOnboardingPaths.success} exact>
                      <SupervisorGatedRoute>
                        <SupervisorOnboardingSuccessPage />
                      </SupervisorGatedRoute>
                    </AuthenticatedRoute>

                    <AuthenticatedRoute path="/supervisor-onboarding" exact>
                      <Redirect to={supervisorOnboardingPaths.step_1} />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/dashboard/stripe-onboard/refresh" exact>
                      <SupervisorGatedRoute>
                        <StripeOnboardRefreshPage />
                      </SupervisorGatedRoute>
                    </AuthenticatedRoute>

                    <PublicGatedRoute path="/supervisor-onboarding/:id" exact>
                      <Step0AccountCreationPage />
                    </PublicGatedRoute>
                    {/* --------------------------- */}
                    {/* supervisor onboarding end   */}
                    {/* --------------------------- */}
                    <Route
                      path="/employer-supervisee-signup/:slug"
                      component={OrganizationSuperviseeOnboardingPage}
                    />
                    <Route
                      path="/employer-supervisee-onboarding/:slug"
                      component={OrganizationSuperviseeOnboardingRedirectPage}
                    />
                    <Route path="/email-change/success" component={EmailChangeSuccessPage} />
                    <Route path="/employer-onboarding" exact>
                      <OrganizationOnboardingPage />
                    </Route>
                    <Route path="/employer-onboarding/success" exact>
                      <OrganizationOnboardingSuccessPage />
                    </Route>
                    <AuthenticatedRoute path="/approve-supervisee/:organizationUserId" exact>
                      <ApproveSuperviseePage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/dashboard/chats/:id?" exact>
                      <ChatsPage />
                    </AuthenticatedRoute>
                    {/**
                     * @deprecated as of serenity, directing opportunity candidates to opportunity page instead. cleanup: MOT-6849
                     */}
                    <AuthenticatedRoute path="/approve-opportunity/:id" exact>
                      <ApproveOpportunityPage />
                    </AuthenticatedRoute>
                    {/**
                     * @deprecated as of serenity, directing opportunity candidates to opportunity page instead. cleanup: MOT-6849
                     */}
                    <AuthenticatedRoute path="/decline-opportunity/:id" exact>
                      <DeclineOpportunityPage />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/confirm-email-change" exact>
                      <ConfirmEmailChangePage />
                    </AuthenticatedRoute>
                    {authRoutes({ fetchViewer: refetch })}
                    <Route path="/ms-callback" exact>
                      <MicrosoftOAuthCallback />
                    </Route>
                    <Layout>
                      <Suspense fallback={<EmptyOrLoadingState loading />}>
                        <Switch>
                          <AuthenticatedRoute path="/dashboard" exact>
                            <DashboardHomePage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/sessions" exact>
                            <SessionsPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path={createSessionPagePath()} exact>
                            <CreateSessionPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/sessions/:id" exact>
                            <SessionDetailsPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/sessions/:id/cancel" exact>
                            <CancelSessionPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path={editSessionPagePath(':id')} exact>
                            <EditSessionPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/sessions/:id/join" exact>
                            <JoinSessionPageWrapper />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/sessions/:id/end" exact>
                            <PostSessionPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path={successfullyBookedSessionPagePath(':id')} exact>
                            <CreateSessionSuccessPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path={successfullyEditedSessionPagePath(':id')} exact>
                            <CreateSessionSuccessPage isUpdated />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/hours" exact>
                            <SuperviseeHoursPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/supervisees/:id/hours" exact>
                            <SuperviseeHoursPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/matches/:id/documents" exact>
                            <MatchDocumentsPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute
                            path="/dashboard/supervisors/:supervisorId/goals"
                            exact
                          >
                            <SuperviseeGoalsPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute
                            path="/dashboard/supervisees/:superviseeId/goals"
                            exact
                          >
                            <SuperviseeGoalsPage />
                          </AuthenticatedRoute>
                          {/* ------------------------------------ */}
                          {/* supervisee create session page start */}
                          {/* ------------------------------------ */}
                          <AuthenticatedRoute
                            path={superviseeCreateSessionPagePath(':supervisorUserId')}
                            exact
                          >
                            <SuperviseeCreateSessionPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute
                            path={superviseeSuccessfullyBookedSessionPagePath(':id')}
                            exact
                          >
                            <SuperviseeSuccessfullyBookedSessionPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute
                            path={superviseeSuccessfullyProposedSessionPagePath(':id')}
                            exact
                          >
                            <SuperviseeSuccessfullyProposedSessionPage />
                          </AuthenticatedRoute>
                          {/* ------------------------------------ */}
                          {/* supervisee create session page end   */}
                          {/* ------------------------------------ */}
                          <AuthenticatedRoute path="/dashboard/supervisees" exact>
                            <SuperviseesPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/intro-calls/" exact>
                            <IntroRequestsPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute
                            path="/dashboard/intro-calls/create/:opportunitiesCandidateId"
                            exact
                          >
                            <BookIntroRequestPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/intro-calls/:id" exact>
                            <IntroRequestDetailsPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/intro-calls/:id/join" exact>
                            <JoinIntroCallPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/contracted-hours" exact>
                            <AdministrativeHoursPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/opportunities" exact>
                            <OpportunitiesPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/opportunities/archived" exact>
                            <OpportunitiesPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboard/opportunities/:id" exact>
                            <OpportunityPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/request-recommendations" exact>
                            <RequestRecommendationsPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/write-recommendation/:id" exact>
                            <WriteRecommendationPage />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/approve-recommendation/:id/" exact>
                            <ModerateRecommendationPage approved />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/decline-recommendation/:id/" exact>
                            <ModerateRecommendationPage approved={false} />
                          </AuthenticatedRoute>
                          <Route path="/contact" exact>
                            <ContactPage />
                          </Route>
                          <AuthenticatedRoute path="/settings">
                            <SettingsLayout>
                              <Suspense fallback={<EmptyOrLoadingState loading />}>
                                <AuthenticatedRoute path="/settings" exact>
                                  <GeneralSettingsPage />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/settings/calendar" exact>
                                  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                                    <CalendarSettingsPage />
                                  </GoogleOAuthProvider>
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/settings/profile" exact>
                                  <ProfileSettingsPage />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/settings/preferences" exact>
                                  <PreferencesSettingsPage />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/settings/licenses" exact>
                                  <LicensesPage />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/settings/payment" exact>
                                  <PaymentPage />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/settings/payment/new" exact>
                                  <NewPaymentPage />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/settings/agreements" exact>
                                  <AgreementsSettingsPage />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path={notificationsPagePath} exact>
                                  <NotificationsPage />
                                </AuthenticatedRoute>
                              </Suspense>
                            </SettingsLayout>
                          </AuthenticatedRoute>
                          <Route path="*">
                            <NotFoundPage />
                          </Route>
                        </Switch>
                      </Suspense>
                      <CalendarReconnectModal />
                    </Layout>
                  </Switch>
                </ErrorBoundary>
              </Suspense>
            </StyledSnackbarProvider>
          </QuestionnaireState.Provider>
        </StylesAndThemeProvider>
      </ScrollToTop>
    </Elements>
  );
}
export default App;
