import { DateTime, Interval } from 'luxon';

export type TimePickerOption = { label: string; value: DateTime };

const TIME_FORMAT = 'hh:mm a';

function generateAutocompleteOption(dateTime: DateTime) {
  return {
    label: dateTime.toFormat(TIME_FORMAT),
    value: dateTime,
  };
}

const HALF_HOUR_INTERVALS: TimePickerOption[] = [];
const sevenAm = DateTime.fromObject({ hour: 7 });
const dayEnd = DateTime.local().endOf('day');
const dayStart = DateTime.local().startOf('day');

const intervals = [
  ...Interval.fromDateTimes(sevenAm, dayEnd).splitBy({ minutes: 30 }),
  ...Interval.fromDateTimes(dayStart, sevenAm).splitBy({ minutes: 30 }),
];
intervals.forEach((interval) => {
  HALF_HOUR_INTERVALS.push(generateAutocompleteOption(interval.start));
});

const HALF_HOUR_INTERVAL_LABELS = new Set(HALF_HOUR_INTERVALS.map(({ label }) => label));

function timeComparator(a: TimePickerOption, b: TimePickerOption) {
  // Non-half-hour intervals i.e. custom times come first
  if (!HALF_HOUR_INTERVAL_LABELS.has(a.label) && HALF_HOUR_INTERVAL_LABELS.has(b.label)) {
    return -1;
  }
  return 1;
}

export function generateTimePickerOptions({
  inputValue,
  unfiltered = false,
  filterFunction,
}: {
  inputValue: string;
  unfiltered: boolean;
  filterFunction?: (dateTime: TimePickerOption) => boolean;
}) {
  let options = [...HALF_HOUR_INTERVALS];

  let inputValueTime = DateTime.fromFormat(inputValue, TIME_FORMAT);
  if (!inputValueTime.isValid) {
    inputValueTime = DateTime.fromFormat(inputValue, 'hh:mma');
  }

  if (!inputValueTime.isValid) {
    inputValueTime = DateTime.fromFormat(inputValue, 'hh:mm');
  }

  if (!inputValueTime.isValid) {
    inputValueTime = DateTime.fromFormat(inputValue, 'h:mm');
  }

  // Insert time options for arbitrary times the user types
  if (
    inputValueTime.isValid &&
    // Don't add times that already exist in the list
    !HALF_HOUR_INTERVAL_LABELS.has(inputValueTime.toFormat(TIME_FORMAT))
  ) {
    options.push(generateAutocompleteOption(inputValueTime));

    const oppositeMeridianTime =
      inputValueTime.hour >= 12
        ? inputValueTime.minus({ hours: 12 })
        : inputValueTime.plus({ hours: 12 });
    options.push(generateAutocompleteOption(oppositeMeridianTime));
  }

  if (filterFunction) {
    options = options.filter(filterFunction);
  }

  options.sort(timeComparator);
  if (unfiltered || !inputValue) {
    return options;
  }

  options = options.filter(
    (option) =>
      option.label.startsWith(inputValue) || option.label.replace(/^0/, '').startsWith(inputValue),
  );
  return options;
}
