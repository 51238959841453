import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as yup from 'yup';
import { Form } from 'react-final-form';
import throwWithoutBreaking from '@src/utils/throwWithoutBreaking';
import { makeValidateSync } from '@src/utils/validationUtils';
import { MoTextInputField } from '../MoTextInputField';

type UpdateEmailFormValues = {
  email: string;
};

const validator = makeValidateSync<UpdateEmailFormValues>(
  // @ts-expect-error TS(2345): Argument of type 'ObjectSchema<{ email: string; } ... Remove this comment to see the full error message
  yup.object({
    email: yup.string().email().required('Please provide an email'),
  }),
);

type EditEmailModalProps = {
  isOpen: boolean;
  updateEmail: (newEmail: string) => Promise<void>;
  closeModal: () => void;
  initialEmail: string;
};

export function EditEmailModal({
  isOpen,
  updateEmail,
  closeModal,
  initialEmail,
}: EditEmailModalProps) {
  const handleSubmit = async ({ email }: UpdateEmailFormValues) => {
    try {
      await updateEmail(email);
      closeModal();
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      throwWithoutBreaking(error);
    }
  };

  return (
    <Form
      validate={validator}
      initialValues={{ email: initialEmail }}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <Dialog open={isOpen} onClose={closeModal} fullWidth>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Edit email</DialogTitle>
            <DialogContent>
              <Box my={4}>
                <MoTextInputField name="email" label="Email" />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
              <Button onClick={closeModal}>Cancel</Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    />
  );
}

export default EditEmailModal;
