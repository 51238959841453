import { moZ } from '@src/utils/moZod';
import { getBrowserTimeZone } from '@src/utils/timeZoneUtils';
import React, { FunctionComponent } from 'react';
import { useField } from 'react-final-form';
import { TimeZoneSelect } from '../TimeZoneSelect';

const TIME_ZONE_NAME = 'timezone';
export const timeZoneSchemaMap = {
  [TIME_ZONE_NAME]: moZ.string().default(''),
};

export function initialTimeZone() {
  return {
    [TIME_ZONE_NAME]: getBrowserTimeZone(),
  };
}

export const TimeZoneSelectField: FunctionComponent<{}> = () => {
  const { input } = useField(TIME_ZONE_NAME);
  return (
    <TimeZoneSelect
      name={TIME_ZONE_NAME}
      label="Time zone"
      value={input.value}
      onChange={input.onChange}
    />
  );
};
