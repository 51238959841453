'use client';

import React, { ReactNode } from 'react';
import { Breakpoint, styled, Theme } from '@mui/material';

type SpacingProp = number | Partial<Record<Breakpoint, number>>;

const generateStyles = (
  spacing: SpacingProp,
  property: 'marginLeft' | 'marginTop',
  theme: Theme,
) => {
  if (typeof spacing === 'number') return { [property]: theme.spacing(spacing) };

  const breakpoints = Object.keys(spacing) as Breakpoint[];
  return breakpoints.reduce((accumulator, breakpoint) => {
    const spacingValue = spacing[breakpoint];
    if (spacingValue != null) {
      return {
        ...accumulator,
        [theme.breakpoints.up(breakpoint)]: {
          [property]: theme.spacing(spacingValue),
        },
      };
    }
    return {};
  }, {} as Record<string, Record<string, string>>);
};

const Component = styled('div', {
  shouldForwardProp: (prop) => !['x', 'y'].includes(prop as string),
})<{ x?: SpacingProp; y?: SpacingProp }>(({ theme, x, y }) => ({
  '&& > * + *': {
    ...(x ? generateStyles(x, 'marginLeft', theme) : {}),
    ...(y ? generateStyles(y, 'marginTop', theme) : {}),
  },
}));

export type MoSpacingProps = {
  children: ReactNode;
} & (
  | {
      x: SpacingProp;
    }
  | {
      y: SpacingProp;
    }
);

export function MoSpacing({ children, ...props }: MoSpacingProps) {
  return (
    <Component x={'x' in props ? props.x : undefined} y={'y' in props ? props.y : undefined}>
      {children}
    </Component>
  );
}
