import { ResultOf, graphql } from ':src/graphql';

export const creditCardsQuery = graphql(`
  query CreditCardsQuery {
    creditCards {
      id
      lastFourDigits
      expirationMonth
      expirationYear
      preferred
      cardType
    }
  }
`);

export const settingsViewerQuery = graphql(`
  query SettingsViewerQuery {
    viewer {
      id
      prenominals
      firstName
      lastName
      postnominals
      zipcode
      timezone
      email
    }
  }
`);

export const licensesViewerQuery = graphql(`
  query LicensesViewerQuery {
    viewer {
      id
      usersLicenses {
        stateId
        licenseId
        licenseNumber
        isAbleToSupervise
      }
      usersSupervisionCredentials {
        stateId
        supervisionCredentialId
      }
    }
  }
`);

export const profileSettingsFormQuery = graphql(`
  query ProfileSettingsFormQuery {
    modalities {
      id
      name
    }
    specialties {
      id
      name
    }
    languages {
      id
      name
    }
  }
`);

export const profileSettingsViewerQuery = graphql(`
  query ProfileSettingsViewerQuery {
    viewer {
      id
      largeAvatar
      pronouns
      slug
      signature
      supervisorProfile {
        id
        quote
        bio
        supervisorProfileDispositionId
      }
      usersSpecialties {
        specialtyId
      }
      usersModalities {
        modalityId
      }
      usersPopulations {
        populationId
      }
      usersDegrees {
        id
        degreeId
        universitySuggestedName
      }
      languages {
        id
      }
      recommendations {
        id
        recommendationText
        completedAt
        approvedAt
        supervisee {
          id
          firstName
          lastName
        }
      }
    }
  }
`);

export const superviseeProfilePreferencesViewerQuery = graphql(`
  query SuperviseeProfilePreferencesViewerQuery {
    viewer {
      id
      openZoomSessionsInApp
    }
  }
`);

export const supervisorProfilePreferencesViewerQuery = graphql(`
  query SupervisorProfilePreferencesViewerQuery {
    viewer {
      id
      userPrimaryCalendar {
        calendarId
        calendarName
        oauthTokenId
      }
      userConnectedCalendars {
        id
        calendarId
        calendarName
        oauthTokenId
      }
      supervisorProfile {
        id
        supervisorProfilePreferences {
          id
          calendarMinimumNoticeHours
          calendarBeforeBufferTimeMinutes
          calendarAfterBufferTimeMinutes
          calendarAllowInstantBook
        }
        sessionTypes {
          id
        }
        supervisesInPerson
      }
      openZoomSessionsInApp
    }
  }
`);

export const profileProgressViewerQuery = graphql(`
  query ProfileProgressViewerQuery {
    viewer {
      id
      largeAvatar
      prenominals
      firstName
      lastName
      postnominals
      zipcode
      timezone
      supervisorProfile {
        id
        quote
        bio
        weeklyAvailableHours
        supervisesInPerson
        sessionTypes {
          id
        }
      }
      userAvailabilities {
        dayOfWeek
        startTime
        endTime
      }
      usersSpecialties {
        specialtyId
      }
      usersModalities {
        modalityId
      }
      usersPopulations {
        populationId
      }
      usersLicenses {
        id
        stateId
        licenseId
        licenseNumber
        isAbleToSupervise
        license {
          id
          displayName
        }
      }
      usersSupervisionCredentials {
        id
        stateId
        supervisionCredentialId
      }
      usersDegrees {
        degreeId
        universitySuggestedName
      }
      stripeVerified
      isSupervisor
      stripeConnectedAccountOnboardingLink(linkType: "account_onboarding")
      stripeExpressDashboardLink
      missingFields
      supervisorApplication {
        id
      }
    }
  }
`);

export const oauthConnectionsQuery = graphql(`
  query OAuthConnectionsQuery {
    oauthConnections {
      result {
        id
        provider
        email
        calendars {
          id
          provider
          name
          oauthTokenId
        }
        isRevoked
      }
      primaryCalendar {
        id
        provider
        name
        oauthTokenId
      }
    }
  }
`);

export type OAuthConnections = ResultOf<typeof oauthConnectionsQuery>['oauthConnections'];
