import React from 'react';

const GroupIcon = ({ color = '#4A4A4A' }: { color?: string }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 13.2812C14.1979 13.2812 15.6979 13.6875 16.9167 14.2187C18.0417 14.7187 18.75 15.8437 18.75 17.0625V18.75H6.25V17.0729C6.25 15.8437 6.95833 14.7187 8.08333 14.2292C9.30208 13.6875 10.8021 13.2812 12.5 13.2812ZM4.16667 13.5417C5.3125 13.5417 6.25 12.6042 6.25 11.4583C6.25 10.3125 5.3125 9.375 4.16667 9.375C3.02083 9.375 2.08333 10.3125 2.08333 11.4583C2.08333 12.6042 3.02083 13.5417 4.16667 13.5417ZM5.34375 14.6875C4.95833 14.625 4.57292 14.5833 4.16667 14.5833C3.13542 14.5833 2.15625 14.8021 1.27083 15.1875C0.5 15.5208 0 16.2708 0 17.1146V18.75H4.6875V17.0729C4.6875 16.2083 4.92708 15.3958 5.34375 14.6875ZM20.8333 13.5417C21.9792 13.5417 22.9167 12.6042 22.9167 11.4583C22.9167 10.3125 21.9792 9.375 20.8333 9.375C19.6875 9.375 18.75 10.3125 18.75 11.4583C18.75 12.6042 19.6875 13.5417 20.8333 13.5417ZM25 17.1146C25 16.2708 24.5 15.5208 23.7292 15.1875C22.8438 14.8021 21.8646 14.5833 20.8333 14.5833C20.4271 14.5833 20.0417 14.625 19.6562 14.6875C20.0729 15.3958 20.3125 16.2083 20.3125 17.0729V18.75H25V17.1146ZM12.5 6.25C14.2292 6.25 15.625 7.64583 15.625 9.375C15.625 11.1042 14.2292 12.5 12.5 12.5C10.7708 12.5 9.375 11.1042 9.375 9.375C9.375 7.64583 10.7708 6.25 12.5 6.25Z"
        fill={color}
      />
    </svg>
  );
};

export default GroupIcon;
