import React from 'react';
import { FormHelperText, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export function MoFieldErrorText({ children }: { children: React.ReactNode }) {
  return (
    <FormHelperText error component="div" sx={{ ml: 0 }}>
      <Box display="flex" alignItems="center">
        <ErrorOutlineIcon fontSize="inherit" />
        <Box ml={0.5}>{children}</Box>
      </Box>
    </FormHelperText>
  );
}
