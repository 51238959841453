import { ResultOf, graphql } from ':src/graphql';

export const sessionDetailsFragment = graphql(`
  fragment SessionDetails on Session {
    id
    sessionTypeId
    locationId
    duration
    scheduledAt
    isViewerSupervisor
    supervisor {
      fullName
    }
    sessionAttendees {
      user {
        fullName
      }
    }
  }
`);

export const upcomingSessionsQuery = graphql(
  `
    query UpcomingSessionsQuery($cursor: String) {
      upcomingSessions(cursor: $cursor) {
        result {
          ...SessionDetails
          joinUrl
          meetingUrl
        }
        nextCursor
      }
    }
  `,
  [sessionDetailsFragment],
);

export const nextStepsSessionsQuery = graphql(`
  query NextStepsSessionsQuery {
    nextStepsSessions {
      result {
        id
      }
      total
    }
  }
`);

export const introRequestsDashboardNotificationQuery = graphql(`
  query IntroRequestsDashboardNotificationQuery {
    introRequests(waitingForViewer: true) {
      result {
        id
      }
      total
    }
  }
`);

export const attendeesFragment = graphql(`
  fragment AttendeeDetails on User {
    firstName
    lastName
    fullName
    avatarUrl
    id
  }
`);

export const getSessionDetailsBySessionIdQuery = graphql(`
  query GetSessionDetailsBySessionIdQuery($sessionId: Int!) {
    sessionById(id: $sessionId) {
      id
      supervisorSignature
      scheduledAt
      timezone
      duration
      createdBy
      locationId
      sessionTypeId
      requiresNotes
      sessionType {
        name
      }
      sessionDispositionId
      recurrenceLabel
      recurrenceRule {
        recurrenceType
        monthlyWeek
        interval
      }
      joinUrl
      meetingUrl
      supervisor {
        id
        firstName
        lastName
        fullName
        displayName
        smallAvatar
      }
      sessionAttendees {
        signature
        user {
          id
          firstName
          lastName
          fullName
          smallAvatar
        }
      }
    }
  }
`);

export const getSessionNotesBySessionIdQuery = graphql(`
  query GetSessionNotesBySessionIdQuery($sessionId: Int!) {
    sessionById(id: $sessionId) {
      id
      sessionNotes {
        id
        key
        question
        answer
      }
      sessionNoteDrafts {
        id
        key
        question
        answer
      }
      supervisor {
        id
        signature
        fullName
      }
    }
  }
`);

export const getMySessionAttendanceQuery = graphql(
  `
    query GetMySessionAttendanceQuery($sessionId: Int!) {
      mySessionAttendance(sessionId: $sessionId) {
        attended
        billable
        partialAttendanceMinutes
        user {
          ...AttendeeDetails
        }
      }
    }
  `,
  [attendeesFragment],
);

export const getSessionAttendanceBySessionIdQuery = graphql(
  `
    query GetSessionAttendanceBySessionIdQuery($sessionId: Int!) {
      sessionAttendanceBySessionId(sessionId: $sessionId) {
        sessionAttendees {
          attended
          billable
          user {
            ...AttendeeDetails
          }
          partialAttendanceMinutes
        }
      }
    }
  `,
  [attendeesFragment],
);

export const superviseeMatchesQuery = graphql(`
  query SuperviseeMatchesQuery($includeMatchesForSessionId: Int, $matchStatusId: Int) {
    superviseeMatches(
      includeMatchesForSessionId: $includeMatchesForSessionId
      matchStatusId: $matchStatusId
    ) {
      id
      matchableId
      supervisee {
        id
        fullName
        firstName
        lastName
        fullName
        avatarUrl
        smallAvatar
        email
        superviseeProfile {
          id
          licenseCategory {
            id
            displayName
          }
          license {
            id
            displayName
          }
        }
        organization {
          id
          name
        }
      }
    }
  }
`);

export const superviseeMatchesGroupedByOrganizationQuery = graphql(`
  query SuperviseeMatchesGroupedByOrganizationQuery($includeMatchesForSessionId: Int) {
    superviseeMatchesGroupedByOrganization(
      includeMatchesForSessionId: $includeMatchesForSessionId
    ) {
      matches {
        id
        matchableId
        supervisee {
          id
          firstName
          lastName
          fullName
          avatarUrl
          smallAvatar
          organization {
            id
            name
          }
          activeUserSupervisionPause {
            supervisionPausedReasonText
            supervisionResumesOn
          }
        }
      }
      organization {
        id
        name
      }
    }
  }
`);

export const editableSessionDetailsById = graphql(`
  query EditableSessionDetailsById($sessionId: Int!) {
    sessionById(id: $sessionId) {
      id
      scheduledAt
      duration
      createdBy
      locationId
      sessionTypeId
      timezone
      recurrenceRule {
        endsAt
        occurrences
        recurrenceType
        monthlyWeek
        interval
      }
      isViewerSupervisor
      supervisor {
        fullName
      }
      sessionAttendees {
        matchId
        user {
          fullName
        }
      }
      sessionDispositionId
      organizationSession {
        id
        organization {
          id
          name
        }
      }
    }
  }
`);

export const cancelSessionDetailsById = graphql(
  `
    query CancelSessionDetailsByIdQuery($sessionId: Int!) {
      sessionById(id: $sessionId) {
        ...SessionDetails
        recurrenceRule {
          recurrenceType
        }
        createdBy
        sessionDispositionId
      }
    }
  `,
  [sessionDetailsFragment],
);

export const matchesForSessionsFiltersQuery = graphql(`
  query MatchesForSessionsFiltersQuery {
    matches {
      superviseeMatches {
        id
        supervisee {
          id
          fullName
        }
      }
      supervisorMatches {
        id
        supervisor {
          id
          fullName
        }
      }
    }
  }
`);

export const billableWorkRecordsQuery = graphql(`
  query BillableWorkRecordsQuery($limit: Int!, $offset: Int!) {
    billableWorkRecords(limit: $limit, offset: $offset) {
      result {
        id
        completedOn
        duration
        description
        organization {
          id
          name
        }
        organizationProgram {
          id
          name
        }
        billableWorkRecordType {
          id
          name
        }
        transfer {
          id
        }
      }
      total
    }
  }
`);

export const organizationsForBillableWorkQuery = graphql(`
  query OrganizationsForBillableWorkQuery {
    organizationsForBillableWork {
      result {
        organization {
          id
          name
        }
        organizationPrograms {
          id
          name
        }
      }
    }
  }
`);

export const billableWorkRecordTypesQuery = graphql(`
  query BillableWorkRecordTypesQuery {
    billableWorkRecordTypes {
      id
      name
    }
  }
`);

export const postSessionQuery = graphql(`
  query PostSessionQuery($sessionId: Int!) {
    postSession(sessionId: $sessionId) {
      session {
        id
        supervisor {
          id
          fullName
        }
      }
      shouldShowNpsSurvey
      sessionAttendeeNpsResponse {
        id
        npsScore
        additionalFeedback
        feedbackRating
        goalsRating
        communicationRating
        trustRating
        supportRating
      }
    }
  }
`);

export const proposedSessionsQuery = graphql(`
  query ProposedSessionsQuery($limit: Int!, $offset: Int!) {
    proposedSessions(limit: $limit, offset: $offset) {
      result {
        id
        scheduledAt
        duration
        timezone
        supervisor {
          id
          firstName
          lastName
          fullName
          mediumAvatar
          avatarUrl
        }
        supervisee {
          id
          firstName
          lastName
          fullName
          mediumAvatar
          avatarUrl
        }
      }
      total
    }
  }
`);

export const activeUserSupervisionPauseIdByUserIdQuery = graphql(`
  query activeUserSupervisionPauseIdByUserIdQuery($userId: Int!) {
    userById(id: $userId) {
      activeUserSupervisionPause {
        id
      }
    }
  }
`);

export type EditableSession = ResultOf<typeof editableSessionDetailsById>['sessionById'];

export type SessionDetails = ResultOf<typeof getSessionDetailsBySessionIdQuery>['sessionById'];

export type SessionAttendees = ResultOf<
  typeof getSessionDetailsBySessionIdQuery
>['sessionById']['sessionAttendees'];
