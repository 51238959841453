'use client';

import React from 'react';
import { Field } from 'react-final-form';
import {
  FormHelperText,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';

export interface MoCheckboxFieldProps extends CheckboxProps {
  name: string;
  label: FormControlLabelProps['label'];
  hideError?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function MoCheckboxField({
  name,
  label,
  value,
  disabled,
  hideError,
  sx,
  onChange,
}: MoCheckboxFieldProps) {
  return (
    <Field
      name={name}
      subscription={{
        error: true,
        value: true,
        touched: true,
        submitFailed: true,
        valid: true,
      }}
      type="checkbox"
      value={value}
      render={({ input, meta }) => {
        const hasError = (meta.touched || meta.submitFailed) && !meta.valid;
        return (
          <>
            <FormControlLabel
              sx={sx}
              label={label}
              control={
                <Checkbox
                  {...input}
                  onChange={(event) => {
                    input.onChange(event);
                    if (onChange) {
                      onChange(event);
                    }
                  }}
                  disabled={disabled}
                />
              }
            />
            {!hideError && hasError && meta.error && (
              <FormHelperText error>{meta.error}</FormHelperText>
            )}
          </>
        );
      }}
    />
  );
}
