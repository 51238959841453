import React from 'react';

export default function MotivoLogo({
  color = '#A73A64',
  width = 120,
  height = 64,
}: {
  color?: string;
  width?: number | string;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 384 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.484497 37.3989C6.05324 28.0791 17.8485 27.4806 24.2293 32.1067C27.6918 34.6166 29.608 38.1223 30.5646 41.8609C32.9689 39.1186 35.0637 35.9994 37.8381 33.6989C47.184 25.9499 60.898 27.9686 65.0304 42.589C66.496 40.5139 67.7257 38.4207 69.2928 36.6213C74.0204 31.1942 79.9058 28.3467 87.2252 29.5541C93.9334 30.6614 99.6512 37.5551 100.024 45.339C100.44 54.013 100.268 62.7145 100.643 71.391C100.849 76.1489 102.157 80.5671 107.013 82.9473C109.102 83.9713 107.799 85.4077 107.012 86.3968C103.971 90.2171 99.9189 92.4093 95.061 92.6848C84.5845 93.2791 77.0714 86.3124 76.8402 75.7754C76.6777 68.4046 76.8137 61.0275 76.7548 53.6535C76.7424 52.0853 76.648 50.4729 76.2709 48.9603C75.3646 45.3229 72.1913 44.4598 69.5465 47.1286C66.8752 49.8234 65.7918 53.2734 65.8392 56.9562C65.972 67.3098 66.1886 77.6645 66.5782 88.0114C66.66 90.1859 66.1485 90.6859 64.0657 90.652C56.8824 90.5353 49.6965 90.6072 42.1345 90.6072C42.1345 89.4874 42.1345 88.5588 42.134 87.6296C42.1288 76.3046 42.1392 64.9801 42.0976 53.6551C42.0918 52.1797 42.11 50.5879 41.5834 49.2625C41.0564 47.9363 39.9594 46.0265 38.9318 45.9057C37.4976 45.7369 35.47 46.601 34.449 47.7139C31.8689 50.5265 31.0908 54.1921 31.0714 57.9478C31.0225 67.7801 31.047 77.613 31.0424 87.4452C31.0418 88.3895 31.0424 89.3338 31.0424 90.4411C22.9209 90.4411 15.0585 90.4411 6.76939 90.4411C6.76939 89.5775 6.75638 88.6609 6.77148 87.7452C6.96419 76.1603 7.19387 64.5765 7.32408 52.991C7.34648 50.9931 7.05376 48.9729 6.72304 46.9931C6.07876 43.1333 4.03346 40.2265 0.484497 38.453C0.484497 38.1015 0.484497 37.7505 0.484497 37.3989"
        fill={color}
      />
      <path
        d="M150.179 59.6419C149.964 57.2821 149.884 54.9009 149.504 52.5681C148.957 49.1971 148.067 45.9165 145.817 43.1857C143.155 39.9523 138.409 39.5399 135.241 42.2733C133.025 44.1851 132 46.7681 131.298 49.4774C129.902 54.8633 129.85 60.3446 130.574 65.8233C131.022 69.2118 131.873 72.5185 133.953 75.3441C135.775 77.819 138.201 79.0149 141.308 78.531C144.897 77.9717 146.787 75.5014 148.028 72.3769C149.649 68.2914 150.008 63.9957 150.179 59.6419V59.6419ZM173.713 59.8706C173.571 74.1441 165.012 86.481 150.745 90.6149C138.108 94.2769 126.391 92.1149 116.47 83.0253C110.764 77.7982 107.569 71.1571 106.756 63.4685C105.524 51.8211 109.289 42.0342 118.298 34.5086C125.411 28.5675 133.82 26.3758 142.981 27.0414C149.824 27.5389 156.04 29.8274 161.432 34.1247C168.028 39.3805 171.977 46.2066 173.297 54.5409C173.428 55.3602 173.497 56.1905 173.563 57.0181C173.628 57.8487 173.652 58.6826 173.713 59.8706"
        fill={color}
      />
      <path
        d="M196.832 92.7201C195.573 92.4894 194.308 92.2868 193.056 92.0232C185.672 90.4696 180.611 85.0383 179.596 77.5233C179.396 76.0446 179.323 74.5383 179.319 73.0441C179.296 62.7045 179.307 52.3643 179.307 42.0242C179.307 41.5321 179.307 41.0399 179.307 40.3769C177.515 40.3769 175.819 40.3113 174.131 40.3993C172.941 40.4618 172.617 39.9899 172.64 38.8747C172.695 36.0405 172.696 33.2039 172.637 30.3701C172.616 29.3883 172.909 29.031 173.904 29.0659C175.645 29.1269 177.392 29.0831 179.307 29.0831C179.307 26.6373 179.307 24.279 179.307 21.9201C179.307 19.5571 179.308 19.5613 181.487 18.6649C188.277 15.8717 195.067 13.0738 201.859 10.2821C202.268 10.1138 202.695 9.98887 203.396 9.7462C203.396 16.2613 203.396 22.5681 203.396 29.0695C204.157 29.0695 204.679 29.0727 205.2 29.069C212.661 29.0222 220.125 28.8695 227.585 28.9581C234.141 29.0357 239.549 31.5878 243.229 37.2039C244.952 39.8315 245.836 42.7837 245.859 45.9242C245.96 59.9315 246.008 73.9399 246.099 87.9472C246.107 89.0316 245.825 89.4961 244.619 89.4878C237.239 89.4373 229.859 89.4472 222.48 89.4805C221.456 89.4852 221.069 89.1711 221.085 88.1248C221.244 78.0373 221.407 67.9498 221.483 57.8618C221.505 54.9571 221.504 52.0129 221.073 49.1529C220.348 44.3383 217.319 41.5586 212.549 40.6222C209.615 40.0462 206.68 40.0821 203.66 40.3367C203.623 40.7409 203.569 41.0591 203.569 41.3774C203.571 50.4665 203.503 59.5566 203.641 68.6435C203.672 70.6681 204.209 72.7649 204.917 74.679C205.847 77.1883 207.964 78.5029 210.615 78.6758C212.769 78.817 214.943 78.6414 217.103 78.7342C217.821 78.7649 218.935 79.0133 219.136 79.479C219.423 80.143 219.265 81.2211 218.899 81.9092C216.904 85.6456 213.729 88.1467 209.972 89.9487C206.952 91.3972 203.776 92.3123 200.417 92.5378C200.22 92.5513 200.028 92.6571 199.835 92.7201C198.833 92.7201 197.832 92.7201 196.832 92.7201"
        fill={color}
      />
      <path
        d="M231.008 23.174C226.132 22.9333 221.721 21.5025 218.743 17.1593C216.333 13.6442 216.772 8.66716 219.721 5.57342C225.747 -0.748044 236.503 -0.716715 242.508 5.63955C245.909 9.23849 245.805 15.0276 242.345 18.5844C239.256 21.7593 235.415 22.9942 231.008 23.174Z"
        fill={color}
      />
      <path
        d="M275.644 50.6414C275.644 57.1878 275.601 63.7347 275.676 70.2804C275.693 71.7982 275.981 73.3476 276.38 74.8184C277.177 77.7643 279.272 78.867 282.209 78.0575C285.716 77.0919 287.572 74.4659 288.92 71.3456C290.173 68.4414 290.639 65.3518 290.824 62.2362C291.131 57.056 290.795 51.9184 289.296 46.9107C288.035 42.7008 285.625 39.3128 282.052 36.7347C280.101 35.3274 280.015 34.5831 281.521 32.6618C283.619 29.9888 286.391 28.2711 289.609 27.3143C295.757 25.4872 301.411 26.3263 306.176 30.8555C309.411 33.9304 311.172 37.894 312.141 42.1711C314.535 52.7274 313.82 63.0763 309.651 73.105C306.619 80.3971 301.78 86.0956 294.48 89.4159C291.365 90.8321 288.036 91.6227 284.621 91.8982C278.971 92.3534 273.404 92.0029 268.031 89.9695C259.567 86.7669 253.981 80.9408 252.007 72.0222C251.457 69.5367 251.427 66.9044 251.413 64.3378C251.353 53.2462 251.387 42.1544 251.387 31.0623C251.387 29.0836 251.388 29.0826 253.381 29.0826C260.261 29.082 267.141 29.1096 274.021 29.0571C275.241 29.0476 275.591 29.4326 275.583 30.6263C275.535 37.2976 275.56 43.9695 275.56 50.6414C275.588 50.6414 275.616 50.6414 275.644 50.6414"
        fill={color}
      />
      <path
        d="M359.608 59.6978C359.392 57.2967 359.316 54.8723 358.924 52.4999C358.383 49.2171 357.516 46.0133 355.357 43.3285C352.384 39.6316 347.069 39.4873 343.9 43.0108C342.207 44.891 341.324 47.1624 340.712 49.5442C339.327 54.9363 339.279 60.415 340.011 65.891C340.463 69.2775 341.321 72.5838 343.42 75.3999C345.228 77.8254 347.627 78.992 350.68 78.541C354.279 78.0098 356.192 75.5472 357.439 72.43C359.069 68.3515 359.436 64.053 359.608 59.6978V59.6978ZM383.14 59.8759C383.009 74.2655 374.235 86.5061 360.067 90.6691C349.749 93.7004 339.753 92.6066 330.597 86.7348C322.697 81.6691 317.967 74.363 316.411 65.0796C314.923 56.2082 316.696 48.001 321.923 40.6863C327.348 33.0963 334.837 28.7442 344.057 27.3748C354.019 25.8963 363.125 28.013 371.048 34.2754C377.575 39.4348 381.437 46.2816 382.725 54.5338C382.853 55.353 382.924 56.1827 382.989 57.0103C383.055 57.841 383.079 58.6748 383.14 59.8759Z"
        fill={color}
      />
    </svg>
  );
}
