import React, { createContext, ReactNode } from 'react';
import { IntroRequestViewProps } from '../types';

export const IntroRequestContext = createContext<IntroRequestViewProps | null>(null);

type IntroRequestProviderProps = {
  children: ReactNode;
  value: IntroRequestViewProps;
};

export default function IntroRequestProvider({ children, value }: IntroRequestProviderProps) {
  return <IntroRequestContext.Provider value={value}>{children}</IntroRequestContext.Provider>;
}
