import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollToSearchParam(param?: string) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scrollTo = searchParams.get('scrollTo');
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!scrollTo) {
      return;
    }

    if (ref.current && scrollTo === param) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollTo, param]);

  return param ? ref : null;
}
