export const isStaging = window.location.hostname.includes('app-staging');

export enum SettingsPagesEnum {
  GENERAL = 'General',
  PROFILE = 'Profile',
  PREFERENCES = 'Preferences',
  LICENSES = 'Licenses & certifications',
  PAYMENT = 'Payment',
  AGREEMENTS = 'Agreements',
  CALENDAR = 'Calendar',
  NOTIFICATIONS = 'Notifications',
}
