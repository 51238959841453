import { isTokenExpired } from './helpers';
import { getAccessToken, refreshAndGetAccessToken } from './utils';

export const authHeaders = async () => {
  let accessToken = getAccessToken();
  if (accessToken && isTokenExpired(accessToken)) {
    accessToken = await refreshAndGetAccessToken();
    if (!accessToken) await new Promise(() => window.location.reload());
  }
  if (accessToken) {
    const userId = localStorage.getItem('motivo_user_id');
    const organizationAdminId = localStorage.getItem('motivo_organization_admin_id');

    return {
      Authorization: `Bearer ${accessToken}`,
      ...(userId && { 'x-motivo-userId': userId }),
      ...(organizationAdminId && { 'x-motivo-organizationAdminId': organizationAdminId }),
    };
  }
  return {};
};

export default async function fetchWithAuthHeaders(uri: RequestInfo, options: RequestInit) {
  return fetch(uri, {
    ...options,
    headers: {
      ...options.headers,
      'X-Motivo-Referrer': window.location.toString().replace(window.location.search, ''),
      ...(await authHeaders()),
    },
  });
}
