import React from 'react';
import { FormSpy } from 'react-final-form';

export function FormErrorScroll() {
  return (
    <FormSpy
      subscription={{ submitFailed: true, modifiedSinceLastSubmit: true }}
      onChange={({ submitFailed, modifiedSinceLastSubmit }) => {
        if (submitFailed && !modifiedSinceLastSubmit) {
          setTimeout(() => {
            const el = document.querySelector('.Mui-error');
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
          }, 200);
        }
      }}
    />
  );
}
