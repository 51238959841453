type Dictionary = Record<PropertyKey, PropertyKey>;
type InvertResult<T extends Dictionary> = {
  [P in keyof T as T[P]]: P;
};

export function invert<T extends Dictionary>(object: T): InvertResult<T> {
  return Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [value]: key,
    }),
    {} as InvertResult<T>,
  );
}
