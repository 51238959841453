import React, { ReactElement } from 'react';
import { Button, FormLabel, FormControl, Box, styled, ButtonProps } from '@mui/material';
import { Field } from 'react-final-form';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import GroupIcon from './components/GroupIcon';
import { MoFieldErrorText } from '../MoFieldErrorText';
import { SessionTypeEnum } from '../../lookup';
import { buttonCss } from '../MoRadioGroup';

interface SessionTypeCheckboxStyleProps {
  checked: boolean;
  hasError: boolean;
}

const StyledButton = styled(Button)<SessionTypeCheckboxStyleProps>(
  ({ theme, checked, hasError }) => ({
    ...buttonCss({ theme, checked, hasError }),
    textTransform: 'none',

    '&:hover': {
      backgroundColor: 'rgb(222, 239, 241, 0.5)',
      border: (() => {
        if (checked) return '2px solid #77C9DD';
        return '1px solid #A6A6A6';
      })(),
    },

    '& svg': {
      height: '24px',
      width: '24px',
    },

    '&:not(:last-child)': {
      marginRight: 16,
    },
  }),
);

const SessionTypeButton = React.forwardRef<ButtonProps, any>((props, ref) => (
  <StyledButton {...props} ref={ref} />
));
SessionTypeButton.displayName = 'SessionTypeButton';

const getSessionTypeIcon = (id: number, checked: boolean) => {
  if (id === SessionTypeEnum.INDIVIDUAL) return <PersonIcon />;
  if (id === SessionTypeEnum.DYAD) return <PeopleIcon />;
  return <GroupIcon color={checked ? '#265F81' : '#444243'} />;
};

type SessionTypecheckBoxProps = {
  id: number;
  label: string;
  checked: boolean;
  hasError: boolean;
  onChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SessionTypeCheckbox = ({
  id,
  label,
  checked,
  hasError,
  onChange,
}: SessionTypecheckBoxProps) => {
  return (
    <SessionTypeButton
      component={FormLabel}
      role="checkbox"
      htmlFor={`session-type-${id}`}
      variant="outlined"
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onChange(event)}
      startIcon={getSessionTypeIcon(id, checked)}
      aria-checked={checked}
      checked={checked}
      hasError={hasError}
    >
      {label}
      <input type="checkbox" hidden id={`session-type-${id}`} checked={checked} />
    </SessionTypeButton>
  );
};

export interface SessionTypesFieldProps {
  name: string;
}

const sessionTypes = [
  { id: SessionTypeEnum.INDIVIDUAL, name: 'Individual' },
  { id: SessionTypeEnum.DYAD, name: 'Dyad' },
  { id: SessionTypeEnum.GROUP, name: 'Group' },
];

export function SessionTypesField({ name }: SessionTypesFieldProps): ReactElement {
  return (
    <Field<number[]>
      name={name}
      render={({ input: { value, onChange, ...restInput }, meta: { error, submitFailed } }) => {
        const hasError = Boolean(submitFailed && error);
        return (
          <FormControl fullWidth>
            <FormLabel error={hasError}>Type of supervision</FormLabel>
            <Box display="flex">
              {sessionTypes.map(({ id, name }) => (
                <SessionTypeCheckbox
                  key={id}
                  id={id}
                  label={name}
                  hasError={hasError}
                  onChange={(e) => {
                    e.preventDefault();
                    onChange(
                      value.includes(id) ? value.filter((value) => value !== id) : [...value, id],
                    );
                  }}
                  {...restInput}
                  checked={value.includes(id)}
                />
              ))}
            </Box>
            {hasError && <MoFieldErrorText>{error}</MoFieldErrorText>}
          </FormControl>
        );
      }}
    />
  );
}
