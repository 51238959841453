import { Button, styled, ButtonProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';

const StyledButton = styled(Button)`
  transition: filter 300ms, background-color 300ms;

  :hover {
    filter: opacity(90%);
  }

  :focus-visible {
    filter: opacity(75%);
  }
`;

export type MoButtonProps = PropsWithChildren<ButtonProps> & {
  target?: string;
  rel?: string;
};

export function MoButton(props: MoButtonProps) {
  return <StyledButton disableElevation disableRipple disableFocusRipple {...props} />;
}
