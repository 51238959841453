import React from 'react';
import { Redirect } from 'react-router-dom';

const redirects = [
  <Redirect key="/welcome" from="/welcome" to="/search-supervisor" />,
  <Redirect key="/superviseeonboarding" from="/superviseeonboarding" to="/search-supervisor" />,
  <Redirect
    key="/superviseeonboarding/:stateCode?/:licenseCode?"
    from="/superviseeonboarding/:stateCode?/:licenseCode?"
    to="/search-supervisor/:stateCode?/:licenseCode?"
  />,
  <Redirect key="/products/:slug" from="/products/:slug" to="/s/:slug" />,
  <Redirect key="/setPassword" from="/setPassword" exact to="/forgotPassword" />,
  <Redirect key="/profile" from="/profile" exact to="/dashboard/profile" />,
  <Redirect key="/licenses" from="/licenses" exact to="/dashboard/licenses" />,
  <Redirect
    key="/create-supervisee"
    from="/create-supervisee"
    exact
    to="/dashboard/create-supervisee"
  />,
  <Redirect key="/sessions" from="/sessions" exact to="/dashboard/sessions" />,
  <Redirect
    key="/dashboard/my-sessions"
    from="/dashboard/my-sessions"
    exact
    to="/dashboard/sessions"
  />,
  <Redirect key="/sessions/:id" from="/sessions/:id" exact to="/dashboard/sessions/:id" />,
  <Redirect key="/sessions/new" from="/sessions/new" exact to="/dashboard/sessions/create" />,
  <Redirect key="/search-supervisor" from="/search-supervisor" exact to="/search" />,
  <Redirect
    key="/sessions/:id/details"
    from="/sessions/:id/details"
    exact
    to="/dashboard/session-details/:id"
  />,
  <Redirect
    key="/dashboard/administrative-hours"
    from="/dashboard/administrative-hours"
    exact
    to="/dashboard/contracted-hours"
  />,
  <Redirect
    key="/dashboard/create-session"
    from="/dashboard/create-session"
    exact
    to="/dashboard/sessions/create"
  />,
  <Redirect
    key="/dashboard/session-details/:id"
    from="/dashboard/session-details/:id"
    exact
    to="/dashboard/sessions/:id"
  />,
  <Redirect
    key="/dashboard/create-session-success/:sessionId"
    from="/dashboard/create-session-success/:sessionId"
    exact
    to="/dashboard/sessions/:sessionId/create-success"
  />,
  <Redirect
    key="/dashboard/update-session-success/:sessionId"
    from="/dashboard/update-session-success/:sessionId"
    exact
    to="/dashboard/sessions/:sessionId/edit-success"
  />,
  <Redirect
    key="/dashboard/cancel-session/:id"
    from="/dashboard/cancel-session/:id"
    exact
    to="/dashboard/sessions/:id/cancel"
  />,
  <Redirect
    key="/dashboard/edit-session/:id"
    from="/dashboard/edit-session/:id"
    exact
    to="/dashboard/sessions/:id/edit"
  />,
];

export default redirects;
