import { Color, PaletteColorOptions } from '@mui/material';
import { SimplePaletteColorOptions, TypeText } from '@mui/material/styles/createPalette';

export const vineyardRose: SimplePaletteColorOptions = {
  dark: '#A73A64',
  main: '#A73A64',
  light: '#E36BA1',
  lighter: '#F5D9DE',
  lightest: '#FCF7F7',
  contrastText: '#FFFFFF',
};

export const bluebell: SimplePaletteColorOptions = {
  dark: '#266180',
  main: '#3B7DBF',
  light: '#4AA1E8',
  lighter: '#C9E3E5',
  lightest: '#F5FAFA',
  contrastText: '#FFFFFF',
};

export const spruce: SimplePaletteColorOptions = {
  dark: '#1C8C7D',
  main: '#3BA894',
  light: '#33CF9C',
  lighter: '#DBEDEB',
  lightest: '#F7FCFA',
  contrastText: '#FFFFFF',
};

export const maple: SimplePaletteColorOptions = {
  dark: '#D18021',
  main: '#E8A35E',
  light: '#FFD18C',
  lighter: '#FFE8D4',
  lightest: '#FFFAF5',
  contrastText: '#FFFFFF',
};

export const warning: PaletteColorOptions = {
  900: '#463B24',
  dark: '#463B24',
  main: '#EDC676',
  light: '#FBF4E4',
  50: '#FBF4E4',
};

export const error: PaletteColorOptions = {
  900: '#5A1F19',
  dark: '#5A1F19',
  main: '#E46252',
  light: '#FAEDEB',
  50: '#FAEDEB',
};

export const success: PaletteColorOptions = {
  900: '#2B4726',
  dark: '#2B4726',
  main: '#75B46B',
  light: '#EFF7EE',
  50: '#EFF7EE',
};

export const info: PaletteColorOptions = {
  900: '#204162 ',
  dark: '#204162 ',
  main: '#61A4EF',
  light: '#EAF4FC',
  50: '#EAF4FC',
};

export const grey: Omit<Color, 'A100' | 'A200' | 'A400' | 'A700'> = {
  900: '#2B2B2B',
  800: '#4D4D4D',
  700: '#6D6D6D',
  600: '#818181',
  500: '#ABABAB',
  400: '#C9C9C9',
  300: '#DEDCDD',
  200: '#F2F2F2',
  100: '#F7F7F7',
  50: '#FCFCFC',
};

export const textColors: TypeText = {
  primary: grey[900],
  secondary: '#747072',
  tertiary: '#797979',
  disabled: '#D2D2D2',
};

export const neutralDark: SimplePaletteColorOptions = {
  main: textColors.primary,
  contrastText: '#fff',
};

export const neutralLight: SimplePaletteColorOptions = {
  main: '#fff',
  contrastText: textColors.primary,
};

export const neutral = neutralDark;
