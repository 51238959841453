import * as React from 'react';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { Link, useLocation } from 'react-router-dom';
import useViewer from ':src/domains/auth/hooks/useViewer';
import { introRequestsDashboardNotificationQuery } from ':src/domains/sessions/queries';
import {
  BaseHeaderLink,
  Header as MoHeader,
  HeaderLink,
  MoNotificationBadge,
} from '@motivo/guanyin/src/components';
import { Avatar, Badge, Box, Chip, Divider, ListItem } from '@mui/material';
import { chatsWithUnreadMessagesQuery } from ':src/domains/messages/queries';
import { HeaderLinkType, HeaderMenuLink } from '@motivo/guanyin/src/components/Header/HeaderMenu';
import { loggedIn, login } from '@motivo/styx/src/auth';
import { useOpportunities } from ':src/hooks/useOpportunities';
import { DateTime } from 'luxon';
import { usePostHog } from 'posthog-js/react';
import BandwidthSelector from './BandwidthSelector';

export type HeaderProps = {
  hideLinks?: boolean;
  centerLogo?: boolean;
};

export default function Header({ hideLinks, centerLogo }: HeaderProps) {
  const { viewer } = useViewer();
  const posthog = usePostHog();
  const { firstName, lastName, smallAvatar, isSupervisor, isSupervisee } = viewer;
  const isLoggedIn = loggedIn();

  const { pathname, state } = useLocation<{ isEmployer: boolean }>();
  const isSupervisees = pathname.includes('supervisees');
  const isSessions = pathname.startsWith('/dashboard/sessions');
  const isIntroCalls = pathname.includes('intro-calls');
  const isSuperviseeHours =
    pathname.startsWith('/dashboard/hours') ||
    (pathname.startsWith('/dashboard/supervisors') && pathname.endsWith('/goals'));
  const isOpportunities = pathname.includes('opportunities');
  const isDashboard =
    pathname.startsWith('/dashboard') &&
    !isSessions &&
    !isSupervisees &&
    !isIntroCalls &&
    !isSuperviseeHours &&
    !isOpportunities;
  const isEmployer = pathname === '/employer-search' || state?.isEmployer;

  const { data: chatsWithUnreadMessagesData } = useQuery(chatsWithUnreadMessagesQuery, {
    skip: !isLoggedIn,
  });
  const { data: introRequestsDashboardNotificationData } = useQuery(
    introRequestsDashboardNotificationQuery,
    {
      fetchPolicy: 'network-only',
      skip: !isLoggedIn,
    },
  );

  const opportunitiesData = useOpportunities();

  const introRequestsDashboardNotificationTotal =
    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
    introRequestsDashboardNotificationData?.introRequests.total;

  const totalNotificationCount =
    (introRequestsDashboardNotificationTotal || 0) +
    (chatsWithUnreadMessagesData?.chatsWithUnreadMessages.length || 0);

  const links: HeaderLinkType[] = [
    { text: 'Dashboard', path: '/dashboard', isActive: isDashboard },
    { text: 'Sessions', path: '/dashboard/sessions', isActive: isSessions },
    ...(isSupervisor
      ? [{ text: 'Supervisees', path: '/dashboard/supervisees', isActive: isSupervisees }]
      : []),
    ...(isSupervisor
      ? [
          {
            text: 'Opportunities',
            path: '/dashboard/opportunities',
            isActive: isOpportunities,
            badgeCount: opportunitiesData.active.length,
          },
        ]
      : []),
    ...(isSupervisee
      ? [{ text: 'My hours', path: '/dashboard/hours', isActive: isSuperviseeHours }]
      : []),
  ];

  const introCallLink: HeaderLinkType = {
    text: 'Intro calls',
    // @ts-ignore
    path: '/dashboard/intro-calls',
    // @ts-expect-error TS(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
    badgeCount: introRequestsDashboardNotificationTotal,
  };

  const menuLinks: HeaderLinkType[] = [
    {
      text: 'Messages',
      path: '/dashboard/chats',
      badgeCount: chatsWithUnreadMessagesData?.chatsWithUnreadMessages.length,
    },
    ...(isSupervisor ? [introCallLink] : []),
    { text: 'My profile', path: '/settings/profile' },
    { text: 'Settings', path: '/settings' },
    ...(isSupervisor
      ? [
          { divider: true },
          {
            text: 'Supervisor Resource Center',
            url: 'https://motivo.notion.site/Supervisor-Resource-Center-6c42c8cb598d400389de8a9f7f9d2ead',
            isNew: DateTime.local() < DateTime.fromISO('2024-08-31'),
            trackingId: 'supervisor_resource_center_clicked',
          },
        ]
      : []),
    ...(isSupervisee
      ? [{ divider: true }, introCallLink, { text: 'Browse directory', path: '/search' }]
      : []),
    { divider: true },
    { text: 'Log out', path: '/logout' },
  ];

  return (
    <MoHeader
      logoPath="/dashboard"
      // @ts-expect-error TS(2322) FIXME: Type 'false | "https://employers.motivohealth.com"... Remove this comment to see the full error message
      logoUrlOverride={isEmployer && 'https://employers.motivohealth.com'}
      isLoggedIn={isLoggedIn}
      hideLinks={hideLinks}
      centerLogo={centerLogo}
      links={links.map((link, i) => {
        if ('divider' in link) return null;
        const { path, isActive, text, badgeCount } = link;
        return (
          <HeaderLink key={i} component={Link} to={path} isActive={isActive}>
            {text}
            {!!badgeCount && (
              <Box ml={1}>
                <MoNotificationBadge>{badgeCount}</MoNotificationBadge>
              </Box>
            )}
          </HeaderLink>
        );
      })}
      loginLink={<HeaderLink onClick={() => login()}>Log in</HeaderLink>}
      logoutLink={
        <HeaderLink component={Link} to="/logout" isLogout>
          Log out
        </HeaderLink>
      }
      menuLinks={menuLinks.map((link, i) => {
        if ('divider' in link) return <Divider key={i} sx={{ my: 0.5 }} />;

        const { text, badgeCount } = link;

        if ('onClick' in link) {
          return (
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            <HeaderMenuLink key={i} onClick={() => link.onClick()} isLogout>
              {text}
            </HeaderMenuLink>
          );
        }

        return (
          <HeaderMenuLink
            key={i}
            onClick={() => {
              if (link.trackingId)
                posthog.capture(link.trackingId, {
                  source: 'headerMenu',
                });
            }}
            component={link.path ? Link : 'a'}
            {...(link.path
              ? { to: link.path }
              : { href: link.url, rel: 'noreferrer nofollow', target: '_blank' })}
          >
            {text}
            {!!badgeCount && (
              <Box ml={1}>
                <MoNotificationBadge>{badgeCount}</MoNotificationBadge>
              </Box>
            )}
            {link.isNew && (
              <Chip
                sx={(theme) => ({
                  marginLeft: 2,
                  backgroundColor: theme.palette.success.light,
                  color: theme.palette.success.dark,
                })}
                label="New"
                size="small"
              />
            )}
          </HeaderMenuLink>
        );
      })}
      menuButton={
        <>
          {smallAvatar && (
            <Badge color="error" badgeContent={totalNotificationCount}>
              <Avatar src={smallAvatar} />
            </Badge>
          )}
          <Box
            ml={1}
            maxWidth="300px"
            overflow="hidden"
            textOverflow="ellipsis"
            display={isSupervisor ? { xs: 'none', lg: 'block' } : 'block'}
          >
            {firstName} {lastName}
          </Box>
        </>
      }
      menuNotificationCount={totalNotificationCount}
      headerWidget={isSupervisor ? <BandwidthSelector /> : undefined}
      headerWidgetMobile={isSupervisor ? <BandwidthSelector fullWidth /> : undefined}
      mobileLinks={[...links, { divider: true }, ...menuLinks].map((link, i) => {
        if ('divider' in link) return <Divider key={i} sx={{ my: 0.5 }} />;

        const { text, badgeCount } = link;

        if ('onClick' in link) {
          return (
            <ListItem key={i}>
              {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
              <BaseHeaderLink onClick={() => link.onClick()} isLogout>
                {text}
              </BaseHeaderLink>
            </ListItem>
          );
        }

        return (
          <ListItem key={i}>
            <BaseHeaderLink
              component={link.path ? Link : 'a'}
              {...(link.path
                ? { to: link.path }
                : { href: link.url, rel: 'noreferrer nofollow', target: '_blank' })}
            >
              {text}
              {!!badgeCount && (
                <Box ml={1}>
                  <MoNotificationBadge> {badgeCount}</MoNotificationBadge>
                </Box>
              )}
            </BaseHeaderLink>
          </ListItem>
        );
      })}
    />
  );
}
