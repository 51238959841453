import React, { FunctionComponent, ReactNode, createContext, useContext, useState } from 'react';

type MoOneTimeTooltipContextProps = {
  currentlyOpenTooltip: string | undefined;
  closeCurrentTooltip: () => void;
};

const MoOneTimeTooltipContext = createContext<MoOneTimeTooltipContextProps | null>(null);

export function useMoOneTimeTooltipContext() {
  const context = useContext(MoOneTimeTooltipContext);
  if (!context) {
    throw new Error('useMoOneTimeTooltipContext must be used within a MoOneTimeTooltipProvider');
  }

  return context;
}

type MoMultipleOneTimeTooltipsProps = { children: ReactNode; tooltipKeys: string[] };

export const MoOneTimeTooltipContainer: FunctionComponent<MoMultipleOneTimeTooltipsProps> = ({
  children,
  tooltipKeys,
}) => {
  const [tooltipStates, setTooltipStates] = useState(tooltipKeys);

  function closeCurrentTooltip() {
    setTooltipStates((prev) => prev.slice(1));
  }

  return (
    <MoOneTimeTooltipContext.Provider
      value={{ currentlyOpenTooltip: tooltipStates[0], closeCurrentTooltip }}
    >
      {children}
    </MoOneTimeTooltipContext.Provider>
  );
};
