import { UserNotificationTriggerEnum } from '@motivo/guanyin/src/lookup';
import throwWithoutBreaking from '@motivo/guanyin/src/utils/throwWithoutBreaking';
import React, { FunctionComponent } from 'react';

export type UserNotificationTriggerLabelProps = { userNotificationTriggerId: number };

const UserNotificationTriggerLabel: FunctionComponent<UserNotificationTriggerLabelProps> = ({
  userNotificationTriggerId,
}) => {
  switch (userNotificationTriggerId) {
    case UserNotificationTriggerEnum.INTRO_CALL_REQUESTED:
      return <div>Notify me when I receive a new intro call request</div>;
    case UserNotificationTriggerEnum.INTRO_CALL_REMINDER_INACTION:
      return (
        <div>
          Remind me after <strong>48 hours</strong> if I haven’t taken action on an intro call
          request
        </div>
      );
    case UserNotificationTriggerEnum.INTRO_CALL_REMINDER_1_HOUR:
      return (
        <div>
          Remind me <strong>1 hour</strong> before my intro call starts
        </div>
      );
    case UserNotificationTriggerEnum.INTRO_CALL_REMINDER_24_HOURS:
      return (
        <div>
          Remind me <strong>24 hours</strong> before my intro call starts
        </div>
      );
    case UserNotificationTriggerEnum.SESSION_REQUESTED:
      return <div>Notify me when a supervisee requests a session</div>;
    // not used right now
    // case UserNotificationTriggerEnum.SESSION_REQUEST_REMINDER_INACTION:
    //   return <div></div>;
    case UserNotificationTriggerEnum.SESSION_BOOKED:
      return <div>Notify me when a session has been booked</div>;
    case UserNotificationTriggerEnum.SESSION_REMINDER_1_HOUR:
      return (
        <div>
          Remind me <strong>1 hour</strong> before my session starts
        </div>
      );
    case UserNotificationTriggerEnum.SESSION_REMINDER_24_HOURS:
      return (
        <div>
          Remind me <strong>24 hours</strong> before my session starts
        </div>
      );
    case UserNotificationTriggerEnum.SESSION_REMINDER_48_HOURS:
      return (
        <div>
          Remind me <strong>48 hours</strong> before my session starts
        </div>
      );
    case UserNotificationTriggerEnum.UNREAD_CHAT_MESSAGE:
      return <div>Notify me when I have an unread message</div>;
    case UserNotificationTriggerEnum.UNREAD_CHAT_MESSAGE_REMINDER:
      return (
        <div>
          Remind me after <strong>24 hours</strong> if I still have unread messages
        </div>
      );
    case UserNotificationTriggerEnum.OPPORTUNITY_RECEIVED:
      return <div>Notify me when I receive a new opportunity</div>;

    default:
      throwWithoutBreaking(
        new Error(`Unknown userNotificationTriggerId: ${userNotificationTriggerId}`),
      );
      return null;
  }
};

export default UserNotificationTriggerLabel;
