import React, { ComponentProps } from 'react';
import { Button, FormLabel, styled, Box } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const SelectionLabel = styled(FormLabel, {
  shouldForwardProp: (prop) => !['hasIcon', 'leftAlign'].includes(prop as string),
})<{ checked: boolean; hasIcon: boolean; leftAlign: boolean }>(
  ({ theme, checked, hasIcon, leftAlign }) => ({
    '&&': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: hasIcon || leftAlign ? 'flex-start' : 'center',
      textTransform: 'none',
      fontSize: '14px',
      textAlign: hasIcon || leftAlign ? 'left' : 'center',
      boxShadow: 'none',
      borderStyle: 'solid',
      ...(checked
        ? {
            fontWeight: 600,
            color: '#265F81',
            backgroundColor: '#CEE7EA',
            padding: '15px',
            borderWidth: '2px',
            borderColor: '#2D6996',
          }
        : {
            fontWeight: 500,
            color: theme.palette.text.primary,
            padding: '16px',
            borderWidth: '1px',
            borderColor: `${theme.palette.grey[400]}`,
          }),

      '&:hover': {
        boxShadow: 'none',
        ...(checked
          ? { borderColor: '#2D6996', backgroundColor: '#B2D8DD', borderWidth: '2px' }
          : { borderColor: theme.palette.grey[400], backgroundColor: 'rgba(0, 0, 0, 0.08)' }),
      },
    },
  }),
);

export interface MoSelectionButtonProps extends ComponentProps<'input'> {
  hasIcon?: boolean;
  leftAlign?: boolean;
}

export function MoSelectionButton({
  type = 'checkbox',
  id,
  checked,
  children,
  hasIcon = false,
  disabled,
  leftAlign = false,
  ...inputProps
}: MoSelectionButtonProps) {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Button
      fullWidth
      component={SelectionLabel}
      role={type}
      htmlFor={id}
      variant="outlined"
      color="secondary"
      checked={checked}
      onClick={(e: any) => {
        if (inputProps.onChange) {
          inputProps.onChange(e);
        }
      }}
      hasIcon={hasIcon}
      leftAlign={leftAlign}
      disabled={disabled}
    >
      {hasIcon && (
        <Box ml={1} mr={2} display="flex" alignContent="center">
          {type === 'radio' ? (
            <>{checked ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}</>
          ) : (
            <>{checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</>
          )}
        </Box>
      )}
      {children}
      <input id={id} type={type} hidden checked={checked} {...inputProps} />
    </Button>
  );
}
