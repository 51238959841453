import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { MoPageContainer } from '@motivo/guanyin/src/components';

const PageWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    minHeight: '80vh',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const Content = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
    },
  },
}));

const Image = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(5),
  maxHeight: 180,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',

  [theme.breakpoints.up('md')]: {
    width: 'auto',
    maxHeight: 'none',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: theme.spacing(4),
  },
}));

export type ErrorLayoutProps = {
  imageSrc: string;
  title: React.ReactNode;
  body: React.ReactNode;
  cta?: React.ReactNode;
};

export default function ErrorLayout({ imageSrc, title, body, cta }: ErrorLayoutProps) {
  return (
    <MoPageContainer maxWidth="md" fixed>
      <PageWrapper>
        <Content>
          <Image src={imageSrc} />
          <Box flex="1" display="flex" flexDirection="column" justifyContent="center">
            <Box mb={2}>
              <Typography variant="h3">{title}</Typography>
            </Box>
            <Typography variant="body2">{body}</Typography>
            {cta && <Box mt={5}>{cta}</Box>}
          </Box>
        </Content>
      </PageWrapper>
    </MoPageContainer>
  );
}
