import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { MoSpacing } from '@src/components/MoSpacing';
import { MoAvatar, SMALL_AVATAR_SIZE } from '@src/components/MoAvatar';
import { MessagesGroupedByDate } from '../utils';

export type MessagesBoxViewProps = {
  groupedMessages: MessagesGroupedByDate[];
  setLastMessageRef?: (node: HTMLSpanElement | null) => void;
  setMessagesRef?: (index: number, node: HTMLElement | null) => void;
};

const MessagesBoxView: FunctionComponent<MessagesBoxViewProps> = ({
  groupedMessages,
  setLastMessageRef,
  setMessagesRef,
}) => {
  return (
    <MoSpacing y={4}>
      {groupedMessages.map(({ formattedDate, messagesByTime }) => (
        <Box key={formattedDate}>
          <Typography
            variant="body2"
            sx={{ mb: 2, textAlign: 'center', textTransform: 'uppercase', fontWeight: 600 }}
          >
            {formattedDate}
          </Typography>
          <MoSpacing y={4}>
            {messagesByTime.map(({ sender, formattedTime, messages }) => (
              <Box key={messages[0].id} display="flex" gap={2}>
                <MoAvatar user={sender} size={SMALL_AVATAR_SIZE} />

                <div>
                  <Typography variant="body2" sx={{ mb: 0.5, display: 'flex', gap: 1.5 }}>
                    <Box sx={{ color: 'text.primary', fontWeight: 600 }} component="span">
                      {sender.fullName}
                    </Box>
                    {formattedTime}
                  </Typography>
                  {messages.map((message, index) => (
                    <Typography
                      sx={{ whiteSpace: 'pre-line' }}
                      key={message.id}
                      variant="body1"
                      data-id={message.id}
                      ref={(node) => {
                        const isLastMessage = index === messages.length - 1;
                        if (isLastMessage) {
                          setLastMessageRef?.(node);
                        }
                        setMessagesRef?.(index, node);
                      }}
                    >
                      {message.text}
                    </Typography>
                  ))}
                </div>
              </Box>
            ))}
          </MoSpacing>
        </Box>
      ))}
    </MoSpacing>
  );
};

export default MessagesBoxView;
