import { camelToSnakeCase } from '@src/utils/stringUtils';
import throwWithoutBreaking from '@src/utils/throwWithoutBreaking';
import { useEffect } from 'react';

const UTM_PARAMS_LOCALSTORAGE_KEY = 'utm_params';

const camelCasedUTMParams = <const>[
  'utmSource',
  'utmMedium',
  'utmCampaign',
  'utmTerm',
  'utmContent',
  'referralcode',
];
type UTMParam = typeof camelCasedUTMParams[number];
type UTMParams = Partial<Record<UTMParam, string>>;

export function useParseAndStoreUTMParams() {
  useEffect(() => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const userSignupSourceData: UTMParams = {};
      for (const param of camelCasedUTMParams) {
        const snakeCasedParam = camelToSnakeCase(param);
        if (urlParams.has(snakeCasedParam)) {
          userSignupSourceData[param] = urlParams.get(snakeCasedParam)!;
        }
      }
      if (Object.keys(userSignupSourceData).length > 0) {
        localStorage.setItem(UTM_PARAMS_LOCALSTORAGE_KEY, JSON.stringify(userSignupSourceData));
      }
    } catch (err) {
      throwWithoutBreaking(err as Error);
    }
  }, []);
}

export function getUTMParamsFromLocalStorage(): UTMParams | null {
  const utmParamsJSON = localStorage.getItem(UTM_PARAMS_LOCALSTORAGE_KEY);
  if (utmParamsJSON) {
    try {
      return JSON.parse(utmParamsJSON);
    } catch {
      throwWithoutBreaking(new Error(`Malformed UTM params in local storage: ${utmParamsJSON}`));
    }
  }
  return null;
}

export function clearUTMParamsFromLocalStorage() {
  console.log('Clearing UTM params from local storage');
  localStorage.removeItem(UTM_PARAMS_LOCALSTORAGE_KEY);
}
