import { createContext } from 'react';
import {
  ChatActorType,
  ChatMessageType,
  ChatType,
  ChatWithLatestMessageType,
  LatestChatMessageType,
  ViewerActorType,
} from './types';

export type ChatsContextProps = {
  activeChatId: string | null;
  chat: ChatType | null;
  chatMessages: ChatMessageType[] | null;
  chatMessagesNextCursor: string | null;
  chatRootPath: string;
  chats: ChatWithLatestMessageType[];
  chatsWithUnreadMessages: { id: string }[];
  enqueueSnackbar: (message: string, options?: { variant: 'error' }) => void;
  fetchMoreChatMessages: (options: {
    variables: { chatId: string; cursor: string };
  }) => Promise<unknown>;
  findOrCreateChat: (otherActorId: number) => void;
  findOrCreateChatLoading: boolean;
  onViewChat: (chatId: string) => void;
  onNewMessage: (listener: (message: ChatMessageType) => void) => void;
  offNewMessage: (listener: (message: ChatMessageType) => void) => void;
  potentialChatParticipants: ChatActorType[];
  refetchChatMessages: () => void;
  socketIsConnected: boolean;
  sendMessage: ({ chatId, message }: { chatId: string; message: string }) => void;
  updateChatWithLatestMessage: ({
    chatId,
    message,
  }: {
    chatId: string;
    message: LatestChatMessageType;
  }) => void;
  viewerActor: ViewerActorType;
};

const ChatsContext = createContext<ChatsContextProps | null>(null);

export default ChatsContext;
