import { MenuItem, SelectProps } from '@mui/material';
import {
  TimeZoneEnum,
  getBrowserTimeZone,
  getTimeZoneNameFromIANA,
  timeZoneNames,
} from '@src/utils/timeZoneUtils';
import React, { ReactElement, useMemo } from 'react';
import { MoSelect } from '../MoSelect';

export interface TimeZoneSelectProps extends SelectProps {
  name: string;
  label: string;
  helperText?: string | ReactElement;
}

type TimeZoneOption = {
  value: string;
  label: string;
};

export function TimeZoneSelect(props: TimeZoneSelectProps) {
  const { value } = props;

  const timeZoneOptions: TimeZoneOption[] = useMemo(() => {
    const ianaTimeZones = Object.values(TimeZoneEnum);
    const browserTimeZone = getBrowserTimeZone();

    const options: TimeZoneOption[] = ianaTimeZones.map((ianaTimeZone) => ({
      value: ianaTimeZone,
      label: timeZoneNames[ianaTimeZone],
    }));

    if (browserTimeZone && !ianaTimeZones.includes(browserTimeZone as TimeZoneEnum)) {
      options.unshift({
        value: browserTimeZone,
        label: getTimeZoneNameFromIANA(browserTimeZone),
      });
    }

    if (typeof value !== 'string') {
      return options;
    }

    if (value && value !== browserTimeZone && !ianaTimeZones.includes(value as TimeZoneEnum)) {
      options.unshift({
        value,
        label: getTimeZoneNameFromIANA(value),
      });
    }

    return options;
  }, [value]);

  return (
    <MoSelect {...props}>
      {timeZoneOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </MoSelect>
  );
}
