import React from 'react';
import { Link, LinkProps } from '@mui/material';

export type MoExternalLinkProps = {
  openInNewTab?: boolean;
} & Omit<LinkProps, 'target'>;

export function MoExternalLink({
  variant = 'body1',
  openInNewTab = false,
  fontWeight = 'bold',
  ...restProps
}: MoExternalLinkProps) {
  const props = {
    ...restProps,
    ...(openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}),
  };
  return (
    <Link
      color={'secondary' as const}
      variant={variant}
      underline="hover"
      fontWeight={fontWeight}
      {...props}
      sx={{
        ...props.sx,
        fontSize: 'inherit',
        '&:focus': {
          textDecoration: 'underline',
        },
      }}
    />
  );
}
