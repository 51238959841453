import React from 'react';
import { css, styled } from '@mui/material';
import { ArrowDownward, Clear } from '@mui/icons-material';
import { pluralize } from '@src/utils/stringUtils';

const PILL_HEIGHT = 40;

const unstyledButtonCss = css`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
`;

const PillWrapper = styled('div')`
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

const Pill = styled('button')`
  ${unstyledButtonCss}
  align-items: center;
  appearance: none;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: ${PILL_HEIGHT / 2}px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: ${PILL_HEIGHT}px;
  justify-content: center;
  padding-left: 16px;
  padding-right: 44px;
  gap: 8px;
`;

const XButton = styled('button')`
  ${unstyledButtonCss}
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  right: 16px;
  padding: 4px;

  svg {
    display: block;
  }
`;

export type NewMessagesPillProps = {
  onClick: () => void;
  onDismiss: () => void;
  count: number;
};

export default function NewMessagesPill({ onClick, onDismiss, count }: NewMessagesPillProps) {
  return (
    <PillWrapper>
      <Pill
        onClick={() => {
          onDismiss();
          onClick();
        }}
      >
        <ArrowDownward fontSize="small" />
        <span>
          {count} new {pluralize('message', count)}
        </span>
      </Pill>
      <XButton onClick={onDismiss}>
        <Clear fontSize="small" />
      </XButton>
    </PillWrapper>
  );
}
