import React from 'react';
import { Avatar, AvatarProps, Badge, CSSObject, styled } from '@mui/material';

const colorPropsList: Pick<CSSObject, 'backgroundColor' | 'color'>[] = [
  // white on vineyard rose
  { backgroundColor: '#A73A64', color: '#FFF' },
  // white on bluebell
  { backgroundColor: '#006DA6', color: '#FFF' },
  // white on spruce
  { backgroundColor: '#156A5F', color: '#FFF' },
  // white on indigo (non-brand color)
  { backgroundColor: '#4F26C3', color: '#FFF' },
  // light vineyard rose
  { backgroundColor: '#F5D9DE', color: '#A73A64' },
  // light bluebell
  { backgroundColor: '#C9E3E5', color: '#005480' },
  // light spruce
  { backgroundColor: '#C2E0DD', color: '#156A5F' },
  // light maple
  { backgroundColor: '#FFD18C', color: '#6E4311' },
];

const StyledAvatar = styled(Avatar)<{ size: number }>(({ size }) => ({
  textTransform: 'uppercase',
  height: `${size}rem`,
  width: `${size}rem`,
}));

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    width: 4rem;
    height: 1.5rem;
    right: 50%;
    transform: translateX(50%) translateY(50%);
    color: #066626;
    background-color: #b0e1c0;
    font-weight: 700;
    padding: 0.5rem;
  }
`;

export const XXS_AVATAR_SIZE = 2;
export const XS_AVATAR_SIZE = 3;
export const SMALL_AVATAR_SIZE = 4;
export const MEDIUM_AVATAR_SIZE = 4.5;
export const LARGE_AVATAR_SIZE = 5;

type UserType = {
  firstName: string;
  lastName: string;
  smallAvatar?: string | null;
  mediumAvatar?: string | null;
};

export interface MoAvatarProps extends Omit<AvatarProps, 'src' | 'children'> {
  user: UserType;
  size?:
    | typeof XXS_AVATAR_SIZE
    | typeof XS_AVATAR_SIZE
    | typeof SMALL_AVATAR_SIZE
    | typeof MEDIUM_AVATAR_SIZE
    | typeof LARGE_AVATAR_SIZE;
  label?: string;
}

const getRandomColors = (name: string) => {
  const sum = name
    .toLowerCase()
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const index = sum % colorPropsList.length;
  return colorPropsList[index];
};

export function MoAvatar({ user, size = MEDIUM_AVATAR_SIZE, label, sx, ...props }: MoAvatarProps) {
  const { firstName, lastName, mediumAvatar, smallAvatar } = user;
  const colorProps = getRandomColors(`${firstName}${lastName}`);

  const avatarSrc = mediumAvatar ?? smallAvatar;

  const AvatarIcon = () => (
    <StyledAvatar
      sx={{ ...colorProps, ...sx }}
      aria-hidden="true"
      size={size}
      {...(avatarSrc ? { src: avatarSrc } : {})}
      {...props}
    >
      {firstName[0]}
      {lastName[0]}
    </StyledAvatar>
  );

  return (
    <>
      {label ? (
        <StyledBadge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap="rectangular"
          badgeContent={label}
        >
          <AvatarIcon />
        </StyledBadge>
      ) : (
        <AvatarIcon />
      )}
    </>
  );
}
