import * as React from 'react';
import { Menu, MenuItem, Button, Typography, styled, Box } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMutation, useQuery } from '@apollo/client';
import { updateSupervisorBandwidthMutation } from ':src/domains/users/mutations';

import { SupervisorBandwidthEnum } from '@motivo/guanyin/src/lookup';
import { viewerQuery } from ':src/domains/auth/queries';
import { useSnackbar } from 'notistack';

const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: '#CECECE',
  color: theme.palette.text.primary,
  fontWeight: 600,
  fontSize: '1rem',
  padding: '8px 16px',
  display: 'inline-flex',
  justifyContent: 'space-between',
  gap: '0.5rem',
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: '300px',
  whiteSpace: 'pre-line',
}));

const BandwidthHeader = styled(Typography)({
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginBottom: 8,
});

const ReadyForOpportunities = () => (
  <>
    <PersonPinOutlinedIcon color="success" />
    <span>Ready for opportunities</span>
  </>
);

const LimitedAvailability = () => (
  <>
    <BeenhereOutlinedIcon color="warning" />
    <span>Limited availability</span>
  </>
);

const NoBandwidth = () => (
  <>
    <DarkModeOutlinedIcon color="error" />
    <span>No bandwidth</span>
  </>
);

export type BandwidthSelectorProps = {
  fullWidth?: boolean;
};

export default function BandwidthSelector({ fullWidth = false }: BandwidthSelectorProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useQuery(viewerQuery);

  const [updateSupervisorBandwidth] = useMutation(updateSupervisorBandwidthMutation);

  const supervisorBandwidthId = data?.viewer?.supervisorProfile?.supervisorBandwidthId;

  if (!supervisorBandwidthId) return null;

  const handleClick = async (supervisorBandwidthId: number, popupState: any) => {
    popupState.close();
    await updateSupervisorBandwidth({
      variables: {
        input: { supervisorBandwidthId },
      },
      refetchQueries: [viewerQuery],
    });

    let confirmationMessage = 'You changed your status to Ready. Your profile is public.';
    if (supervisorBandwidthId === SupervisorBandwidthEnum.LIMITED)
      confirmationMessage = 'You changed your status to Limited. Your profile is public.';
    if (supervisorBandwidthId === SupervisorBandwidthEnum.NONE)
      confirmationMessage = 'You changed your status to No bandwidth. Your profile is hidden.';

    enqueueSnackbar(confirmationMessage);
  };

  return (
    <PopupState variant="popover" popupId="navbar-config-menu">
      {(popupState: any) => (
        <>
          <StyledButton
            {...bindTrigger(popupState)}
            variant="outlined"
            color="neutral"
            fullWidth={fullWidth}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={0.5}
              sx={{ span: { display: { md: 'none', lg: 'inline' } } }}
            >
              {supervisorBandwidthId === SupervisorBandwidthEnum.READY && <ReadyForOpportunities />}
              {supervisorBandwidthId === SupervisorBandwidthEnum.LIMITED && <LimitedAvailability />}
              {supervisorBandwidthId === SupervisorBandwidthEnum.NONE && <NoBandwidth />}
            </Box>
            <ArrowDropDownIcon />
          </StyledButton>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <StyledMenuItem onClick={() => handleClick(SupervisorBandwidthEnum.READY, popupState)}>
              <BandwidthHeader>
                <ReadyForOpportunities />
              </BandwidthHeader>
              <Typography color="text.secondary">
                You’re available to be matched with supervisees. Your profile is public.
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => handleClick(SupervisorBandwidthEnum.LIMITED, popupState)}
            >
              <BandwidthHeader>
                <LimitedAvailability />
              </BandwidthHeader>
              <Typography color="text.secondary">
                You are open to hearing about opportunities, but have limited bandwidth. Your
                profile is public.
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem onClick={() => handleClick(SupervisorBandwidthEnum.NONE, popupState)}>
              <BandwidthHeader>
                <NoBandwidth />
              </BandwidthHeader>
              <Typography color="text.secondary">
                You don’t want to be reached out for any opportunities. Your profile will be hidden.
              </Typography>
            </StyledMenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
}
