import { MoNotificationBadge } from '@motivo/guanyin/src/components';
import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { useScrollToSearchParam } from '@motivo/guanyin/src/hooks/useScrollToSearchParam';

export type SettingsHeaderType = {
  hasMissingFields?: boolean;
} & TypographyProps;

export default function SettingsHeader({
  hasMissingFields = false,
  id,
  children,
  ...props
}: SettingsHeaderType) {
  return (
    <Typography
      ref={useScrollToSearchParam(id)}
      variant="h3"
      {...props}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {children} {hasMissingFields && <MoNotificationBadge />}
    </Typography>
  );
}
