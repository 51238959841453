import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useField } from 'react-final-form';
import { DateTime } from 'luxon';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MoFieldErrorText } from '../MoFieldErrorText';

export class StartsOnSundayLuxonAdapter extends AdapterLuxon {
  getWeekdays = () => ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  getWeekArray = (date: DateTime) => {
    const numberOfDays = date
      .endOf('month')
      .endOf('week')
      .diff(date.startOf('month').startOf('week'), 'days').days;
    const weeks = [];
    for (let i = 0; i < numberOfDays; i += 1) {
      const currentDate = date.startOf('month').startOf('week').minus({ day: 1 }).plus({ days: i });
      if (i === 0 || (i % 7 === 0 && i >= 7)) {
        weeks.push([currentDate]);
        continue;
      }
      weeks[weeks.length - 1].push(currentDate);
    }

    return weeks.filter((week) => {
      return week[0].hasSame(date, 'month') || week[week.length - 1].hasSame(date, 'month');
    });
  };
}

interface MoDatePickerFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
  onChange?: (date: DateTime) => void;
  shouldAllowClear?: boolean;
  shouldDisableDate?: (date: DateTime) => boolean;
  disablePast?: boolean;
  inputFormat?: string;
  required?: boolean;
}

export function MoDatePickerField({
  name,
  label = 'Date',
  disabled = false,
  minDate,
  maxDate,
  onChange = () => {},
  shouldAllowClear,
  shouldDisableDate = () => false,
  inputFormat = 'DDDD',
  disablePast = false,
  required = false,
}: MoDatePickerFieldProps) {
  const {
    input: { value, onChange: onInputChange, variant, ...restInput },
    meta,
  } = useField<DateTime | null>(name);

  const showError = meta.touched && meta.error && !meta.active;
  const errorMessages = Array.isArray(meta.error) ? meta.error : [meta.error];
  const finalErrorMessage = errorMessages.join('\n');

  const browserTimeZone = DateTime.local().zoneName;
  const datepickerValue = value ? value.setZone(browserTimeZone) : null;

  const onClear = () => {
    onInputChange(null);
  };

  return (
    <LocalizationProvider dateAdapter={StartsOnSundayLuxonAdapter}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DatePicker
          disablePast={disablePast}
          openTo="day"
          value={datepickerValue}
          format={inputFormat}
          onChange={(date: DateTime | null) => {
            if (date) {
              onChange(date);
            }
            onInputChange(date);
          }}
          showDaysOutsideCurrentMonth
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          shouldDisableDate={shouldDisableDate}
          slotProps={{
            textField: {
              ...restInput,
              name,
              label,
              error: showError,
              placeholder: 'Select a date',
              fullWidth: true,
              helperText: null,
              required,
            },
          }}
          sx={shouldAllowClear ? { flexShrink: 0 } : {}}
        />
        {shouldAllowClear && (
          <IconButton onClick={onClear} sx={{ marginLeft: '8px' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      {showError && finalErrorMessage && <MoFieldErrorText>{finalErrorMessage}</MoFieldErrorText>}
    </LocalizationProvider>
  );
}
