import throwWithoutBreaking from '@motivo/guanyin/src/utils/throwWithoutBreaking';
import { refreshAccessToken } from './services';

export function getAccessToken() {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken === 'null') {
    localStorage.removeItem('access_token');
    return null;
  }
  return accessToken;
}

export function setAccessToken(accessToken: string) {
  localStorage.setItem('access_token', accessToken);
}

export const refreshAndGetAccessToken = async () => {
  try {
    const response = await refreshAccessToken();
    if (!response?.access_token) {
      localStorage.removeItem('access_token');
      return null;
    }

    const accessToken = response.access_token;
    setAccessToken(accessToken);
    return accessToken;
  } catch (err) {
    throwWithoutBreaking(err);
    localStorage.removeItem('access_token');
    return null;
  }
};
