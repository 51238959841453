import useViewer from ':src/domains/auth/hooks/useViewer';
import { opportunitiesQuery } from ':src/domains/opportunities/queries';
import { useSuspenseQuery } from '@apollo/client';
import {
  OpportunitiesCandidateStatusEnum,
  OpportunityStatusEnum,
} from '@motivo/guanyin/src/lookup';
import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import { ResultOf } from ':src/graphql';

export function useOpportunities() {
  const { viewer } = useViewer();

  const { data } = useSuspenseQuery(opportunitiesQuery, { skip: !viewer.isSupervisor });

  const opportunitiesMap = useMemo(() => {
    const result = data?.opportunities.result || [];
    return result.reduce<
      Record<'active' | 'archived', ResultOf<typeof opportunitiesQuery>['opportunities']['result']>
    >(
      (map, opportunity) => {
        const { opportunityStatusId, opportunitiesCandidates } = opportunity;
        if (
          [OpportunityStatusEnum.MATCHED, OpportunityStatusEnum.CLOSED].includes(
            opportunityStatusId,
          )
        ) {
          map.archived.push(opportunity);
        } else {
          const candidate = opportunitiesCandidates.find(
            ({ supervisorUserId }) => viewer.id === supervisorUserId,
          );

          invariant(candidate);
          if (
            candidate.opportunitiesCandidateStatusId === OpportunitiesCandidateStatusEnum.DECLINED
          ) {
            map.archived.push(opportunity);
          } else {
            map.active.push(opportunity);
          }
        }
        return map;
      },
      { active: [], archived: [] },
    );
  }, [data, viewer.id]);

  return {
    active: opportunitiesMap.active,
    archived: opportunitiesMap.archived,
  };
}
