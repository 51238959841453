import React from 'react';
import { Box } from '@mui/material';
import MotivoLogo from '@motivo/guanyin/src/components/Header/MotivoLogo';

export type LoadingProps = {
  message?: string;
};

export default function Loading({ message }: LoadingProps) {
  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign="center">
        <MotivoLogo width="50%" />
        {message && <h3>{message}</h3>}
      </Box>
    </Box>
  );
}
