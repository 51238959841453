import { DateTime } from 'luxon';
import { z, ZodTypeAny } from 'zod';
import type * as zodTypes from 'zod';

export type { zodTypes };

export const moZ = {
  number(message = 'Required') {
    return z.number({ invalid_type_error: message, required_error: message });
  },
  string(message = 'Required') {
    return z.string({ invalid_type_error: message, required_error: message });
  },
  boolean(message = 'Required') {
    return z.boolean({ invalid_type_error: message, required_error: message });
  },
  dateTime(message = 'Required') {
    return z.custom<DateTime>((value) => !!value, { message });
  },
  profilePhoto(message = 'Required') {
    return z.custom((value) => !!value, { message });
  },
  file(message = 'Required') {
    return z.instanceof(File, { message });
  },
  fileList(message = 'Required') {
    return z.instanceof(FileList, { message });
  },
  email(message = 'Required') {
    return z
      .string({ invalid_type_error: message, required_error: message })
      .email({ message: 'Enter a valid email address' });
  },
  array: z.array,
  object: z.object,
  unknown: z.unknown,
  literal: (value: any, message: string) => z.literal(value, { errorMap: () => ({ message }) }),
};

export function generateOutput(
  issues: { code: string; message: string; path: (string | number)[] }[],
) {
  const output: any = {};

  for (const issue of issues) {
    let node = output;
    for (const [index, pathSegment] of Array.from(issue.path.entries())) {
      const isLastSegment = index === issue.path.length - 1;
      let nextNode = node[pathSegment];
      if (isLastSegment) {
        if (Array.isArray(nextNode)) {
          nextNode.push(issue.message);
        } else {
          nextNode = [issue.message];
          node[pathSegment] = nextNode;
        }
      } else if (nextNode) {
        if (typeof nextNode === 'string') {
          break;
        }
        if (Array.isArray(nextNode) && typeof pathSegment !== 'number') {
          break;
        }
      } else {
        const nextPathSegment = issue.path[index + 1];
        nextNode = typeof nextPathSegment === 'number' ? [] : {};
        node[pathSegment] = nextNode;
      }
      node = nextNode;
    }
  }

  return output;
}

export function getValidator(formSchema: ZodTypeAny) {
  return function validator(formData: object): Partial<Record<string, string[]>> {
    const result = formSchema.safeParse(formData);
    if (result.success) {
      return {};
    }

    return generateOutput(result.error.issues);
  };
}

export function validateYearString(value: string): boolean {
  const year = Number(value);
  if (Number.isNaN(year)) {
    return false;
  }
  if (year < 1900 || year > 2100) {
    return false;
  }
  return true;
}

export function validatePassword(value: string): boolean {
  if (value.length < 8) return false;
  if (/([a-zA-Z\d\W])\1\1/.test(value)) return false;

  const hasLowercase = /[a-z]/.test(value);
  const hasUppercase = /[A-Z]/.test(value);
  const hasNumbers = /[\d]/.test(value);
  const hasSpecial = /[\W]/.test(value);

  const hasEnoughCharacterTypes = (() => {
    let count = 0;
    if (hasLowercase) count += 1;
    if (hasUppercase) count += 1;
    if (hasNumbers) count += 1;
    if (hasSpecial) count += 1;
    return count >= 3;
  })();

  if (!hasEnoughCharacterTypes) return false;

  return true;
}
