'use client';

import React, { Children, cloneElement, ReactElement, ReactNode, useState } from 'react';
import {
  AppBar,
  AppBarProps,
  Badge,
  Box,
  BoxProps,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HeaderMenu from './HeaderMenu';
import MotivoLogo from './MotivoLogo';

type BaseLinkProps = {
  isActive?: boolean;
  isLogout?: boolean;
};

const BaseWrapper = <C extends React.ElementType>(props: BoxProps<C, { component?: C }>) => {
  return <Box {...props} />;
};

export const BaseHeaderLink = styled(BaseWrapper, {
  shouldForwardProp: (prop) => !['isActive', 'isLogout'].includes(prop as string),
})<BaseLinkProps>(({ theme, isLogout }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: isLogout ? theme.palette.primary.main : theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: 600,
}));

export const HeaderLink = styled(BaseHeaderLink)(({ theme, isActive }) => ({
  marginLeft: theme.spacing(4),
  padding: '20px 0',

  ...(isActive && {
    paddingBottom: '18px',
    borderBottom: `2px ${theme.palette.primary.main} solid`,
    color: theme.palette.primary.main,
  }),

  '&:hover': {
    paddingBottom: '18px',
    borderBottom: `2px ${theme.palette.primary.main} solid`,
    color: theme.palette.primary.main,
  },
}));

export type HeaderProps = {
  logoPath: string;
  logoUrlOverride?: string;
  isLoggedIn?: boolean;
  hideLinks?: boolean;
  centerLogo?: boolean;
  links: ReactNode;
  loginLink: ReactNode;
  logoutLink: ReactNode;
  menuLinks: ReactElement[];
  menuButton: ReactNode;
  headerWidget?: ReactNode;
  headerWidgetMobile?: ReactNode;
  menuNotificationCount?: number;
  mobileLinks: ReactElement[];
  appBarProps?: AppBarProps;
};

export function Header({
  logoPath,
  logoUrlOverride,
  isLoggedIn,
  hideLinks,
  centerLogo,
  links,
  loginLink,
  menuLinks,
  menuButton,
  headerWidget,
  headerWidgetMobile,
  menuNotificationCount,
  mobileLinks,
  appBarProps = {},
}: HeaderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const logo = <MotivoLogo />;
  const logoWithUrl = <a href={logoUrlOverride || 'https://motivohealth.com/'}>{logo}</a>;
  const menuIcon = menuNotificationCount ? (
    <Badge badgeContent={menuNotificationCount} color="error">
      <MenuIcon />
    </Badge>
  ) : (
    <MenuIcon />
  );

  return (
    <AppBar {...appBarProps} position="static" color="inherit">
      <Toolbar>
        {/* ------------------------------------------------- */}
        {/* desktop */}
        {/* ------------------------------------------------- */}
        <Box
          width={1}
          display="flex"
          alignItems="stretch"
          justifyContent={centerLogo ? 'center' : 'space-between'}
        >
          <Box display="flex" alignItems="stretch">
            {/* ------------------------------------------------- */}
            {/* desktop - left side */}
            {/* ------------------------------------------------- */}
            {isLoggedIn ? (
              <>
                {logoUrlOverride ? logoWithUrl : <a href={logoPath}>{logo}</a>}
                {!hideLinks && <Box sx={{ display: { xs: 'none', md: 'flex' } }}>{links}</Box>}
              </>
            ) : (
              logoWithUrl
            )}
          </Box>

          {/* ------------------------------------------------- */}
          {/* desktop - right side */}
          {/* ------------------------------------------------- */}
          {!hideLinks && (
            <Box display="flex" alignItems="stretch">
              {isLoggedIn ? (
                <>
                  <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'stretch', gap: 2 }}>
                    {!isMobile && !!headerWidget && (
                      <Box display="flex" alignItems="center">
                        {headerWidget}
                      </Box>
                    )}
                    <HeaderMenu menuLinks={menuLinks} menuButton={menuButton} />
                  </Box>
                  <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                    <IconButton
                      onClick={() => setIsMenuOpen((previousIsMenuOpen) => !previousIsMenuOpen)}
                    >
                      {isMenuOpen ? <CloseIcon /> : menuIcon}
                    </IconButton>
                  </Box>
                </>
              ) : (
                loginLink
              )}
            </Box>
          )}
        </Box>
      </Toolbar>

      {/* ------------------------------------------------- */}
      {/* mobile */}
      {/* ------------------------------------------------- */}
      {!hideLinks && (
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Collapse in={isMenuOpen}>
            {isMobile && !!headerWidgetMobile && (
              <>
                <Box p={2}>{headerWidgetMobile}</Box>
                <Divider />
              </>
            )}
            <List>
              {Children.map(mobileLinks, (child) => {
                if (child?.type === ListItem) {
                  return cloneElement(child, {
                    onClick: () => setIsMenuOpen(false),
                  });
                }
                return child;
              })}
            </List>
          </Collapse>
        </Box>
      )}
    </AppBar>
  );
}
