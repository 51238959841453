import { useState } from 'react';

export type ModalState = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
};

export function useModal(): ModalState {
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return {
    open,
    close,
    isOpen,
  };
}
