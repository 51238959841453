import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';
import { StartsOnSundayLuxonAdapter } from '../MoDatePickerField';
import { CalendarDay } from './CalendarDay';

export type MoSelectAvailableDateCalendarProps = {
  availableWeekdays: number[];
  onChange: (value: DateTime) => void;
  value: DateTime | null;
};

export const MoSelectAvailableDateCalendar: FunctionComponent<
  MoSelectAvailableDateCalendarProps
> = ({ availableWeekdays, onChange = () => {}, value }) => {
  return (
    <LocalizationProvider dateAdapter={StartsOnSundayLuxonAdapter} adapterLocale="en-us">
      <DateCalendar
        slots={{ day: CalendarDay as any }}
        slotProps={{ day: { availableWeekdays } } as any}
        onChange={(value) => {
          if (!value) {
            return;
          }

          onChange(value);
        }}
        value={value}
      />
    </LocalizationProvider>
  );
};
