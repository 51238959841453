interface PluralizeSuffixes {
  singular: string;
  plural: string;
}

export function toSentence(items: string[]) {
  if (items.length === 0) return '';
  if (items.length === 1) return items[0];
  if (items.length === 2) return `${items[0]} & ${items[1]}`;

  const lastItem = items[items.length - 1];
  return `${items.slice(0, -1).join(', ')}, & ${lastItem}`;
}

export function pluralize(
  word: string,
  count: number,
  suffixes: PluralizeSuffixes = { singular: '', plural: 's' },
) {
  return `${word}${count === 1 ? suffixes.singular : suffixes.plural}`;
}

export function countWords(str: string) {
  const trimmedString = str.trim();
  return trimmedString ? trimmedString.split(/\s+/).length : 0;
}

export function snakeToCamelCase(word: string) {
  return word
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
}

export function camelToSnakeCase(word: string) {
  return word.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function capitalize(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
}

export function formatCentsAsDollars(amount: number) {
  const dollars = amount / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}

export function validatePhoneNumber(data: string) {
  const numericData = data.replace(/\D+/g, '');
  const strippedFirstOne = numericData.replace(/^1/, '');
  if (strippedFirstOne[0] === '1') return false;
  return strippedFirstOne.length === 10;
}
