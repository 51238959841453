import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { MoExternalLink, MoExternalLinkProps } from '../MoExternalLink';

export type MoLinkProps = LinkProps & MoExternalLinkProps;

export function MoLink({ children, ...restProps }: MoLinkProps) {
  return (
    <MoExternalLink component={Link} {...restProps}>
      {children}
    </MoExternalLink>
  );
}
