import { Box, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import NotificationsPageForm from './components/NotificationsPageForm';

export const notificationsPagePath = '/settings/notifications';

export type NotificationsPageProps = {};

const NotificationsPage: FunctionComponent<NotificationsPageProps> = () => {
  return (
    <>
      <Typography variant="h2" mb={2}>
        Notifications
      </Typography>
      <Box mb={4}>Choose which notifications you wish to receive from Motivo.</Box>

      <NotificationsPageForm />
    </>
  );
};

export default NotificationsPage;
