import React from 'react';
import { Route } from 'react-router-dom';
import { verifyConfig } from './config';
import Callback from './pages/Callback';
import Logout from './pages/Logout';

const authRoutes = ({ fetchViewer }: { fetchViewer: () => Promise<unknown> }) => {
  verifyConfig();

  return [
    <Route key="/callback" path="/callback">
      <Callback fetchViewer={fetchViewer} />
    </Route>,
    <Route key="/logout" path="/logout" component={Logout} />,
  ];
};

export default authRoutes;
