import { type BookIntroCallViewProps } from '@src/components/IntroRequestView/types';
import React, { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';

export const BookIntroCallContext = createContext<BookIntroCallViewProps | null>(null);

type BookIntroCallProviderProps = {
  children: ReactNode;
  value: BookIntroCallViewProps;
};

export function useBookIntroCallProvider() {
  const context = useContext(BookIntroCallContext);
  invariant(context, 'useBookIntroCallProvider must be used within a BookIntroCallProvider');

  return context;
}

export default function BookIntroCallProvider({ children, value }: BookIntroCallProviderProps) {
  return <BookIntroCallContext.Provider value={value}>{children}</BookIntroCallContext.Provider>;
}
