import React from 'react';
import { Typography, Chip, styled } from '@mui/material';
import { DateTime } from 'luxon';
import { IntroRequestStatusEnum, RoleEnum } from '@src/lookup';
import { IntroRequestType } from '../types';

const StyledChip = styled(Chip)({
  fontSize: '14px',
  fontWeight: 600,
  padding: '8px',
  borderRadius: '20px',
  height: 'unset',
});

const IntroRequestStatusEnumToLabel = {
  [IntroRequestStatusEnum.WAITING_ON_USER]: 'Times sent',
  [IntroRequestStatusEnum.WAITING_ON_SUPERVISOR]: 'Times sent',
  [IntroRequestStatusEnum.SCHEDULED]: 'Call booked',
  [IntroRequestStatusEnum.ACCEPTED_BY_USER]: 'Match sent',
  [IntroRequestStatusEnum.ACCEPTED_BY_SUPERVISOR]: 'Match sent',
  [IntroRequestStatusEnum.ACCEPTED]: 'Matched',
  [IntroRequestStatusEnum.DECLINED_BY_USER]: 'Cancelled',
  [IntroRequestStatusEnum.DECLINED_BY_SUPERVISOR]: 'Cancelled',
  [IntroRequestStatusEnum.EXPIRED]: 'Expired',
};

type IntroRequestStatusChipType = {
  userId: number;
  introRequest: IntroRequestType;
};

export default function IntroRequestStatusChip({
  userId,
  introRequest,
}: IntroRequestStatusChipType) {
  const otherParty =
    userId === introRequest.supervisor.id ? RoleEnum.SUPERVISEE : RoleEnum.SUPERVISOR;
  const { scheduledAt, introRequestStatusId } = introRequest;
  const label = IntroRequestStatusEnumToLabel[introRequestStatusId];

  if (label === '-') return <span>{label}</span>;

  if (
    [
      IntroRequestStatusEnum.ACCEPTED,
      IntroRequestStatusEnum.DECLINED_BY_SUPERVISOR,
      IntroRequestStatusEnum.DECLINED_BY_USER,
      IntroRequestStatusEnum.EXPIRED,
    ].includes(introRequestStatusId)
  ) {
    return (
      <Typography variant="body3" color="text.tertiary">
        {label}
      </Typography>
    );
  }

  const waitingOnOtherUser =
    (introRequestStatusId === IntroRequestStatusEnum.WAITING_ON_SUPERVISOR &&
      otherParty === RoleEnum.SUPERVISOR) ||
    (introRequestStatusId === IntroRequestStatusEnum.WAITING_ON_USER &&
      otherParty === RoleEnum.SUPERVISEE) ||
    (introRequestStatusId === IntroRequestStatusEnum.ACCEPTED_BY_SUPERVISOR &&
      otherParty === RoleEnum.SUPERVISEE) ||
    (introRequestStatusId === IntroRequestStatusEnum.ACCEPTED_BY_USER &&
      otherParty === RoleEnum.SUPERVISOR);

  if (waitingOnOtherUser) {
    return <StyledChip label={label} />;
  }

  if (
    introRequestStatusId === IntroRequestStatusEnum.SCHEDULED &&
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    DateTime.local() < DateTime.fromISO(scheduledAt)
  )
    return <StyledChip label={label} sx={{ backgroundColor: '#B0E1C0', color: '#066626' }} />;

  return <StyledChip label="Action needed" sx={{ backgroundColor: '#FFE3AD', color: '#5C4300' }} />;
}
