import { graphql } from ':src/graphql';
import { useQuery } from '@apollo/client';
import { MoConfirmModal } from '@motivo/guanyin/src/components';
import { useModal } from '@motivo/guanyin/src/hooks/useModal';
import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import { GOOGLE_CALENDAR_ICON_SRC, OUTLOOK_ICON_SRC } from '../constants';

export const calendarReconnectModalQuery = graphql(`
  query CalendarReconnectModalQuery {
    viewer {
      id
      accessRevokedCalendar
    }
  }
`);

const CalendarReconnectModal = () => {
  const history = useHistory();
  const connectCalendarModal = useModal();
  const hasOpened = useBoolean();

  const { data } = useQuery(calendarReconnectModalQuery);
  const accessRevokedCalendar = data?.viewer.accessRevokedCalendar;

  useEffect(() => {
    if (accessRevokedCalendar && !hasOpened.value) {
      connectCalendarModal.open();
      hasOpened.setTrue();
    }
  }, [accessRevokedCalendar, connectCalendarModal, hasOpened]);

  function getRevokedCalendarProvider() {
    if (accessRevokedCalendar === 'google') {
      return {
        name: 'Google',
        src: GOOGLE_CALENDAR_ICON_SRC,
      };
    }

    return {
      name: 'Outlook',
      src: OUTLOOK_ICON_SRC,
    };
  }

  const goToCalendarSettings = () => {
    connectCalendarModal.close();
    history.push('/settings/calendar');
  };

  return (
    <MoConfirmModal
      open={connectCalendarModal.isOpen}
      title={`We could not access your ${getRevokedCalendarProvider()?.name} calendar.`}
      yesText="Reconnect"
      noText="Continue without reconnecting"
      onYes={goToCalendarSettings}
      onClose={connectCalendarModal.close}
      image={getRevokedCalendarProvider()?.src}
      imageWidth="100px"
    >
      <Typography>
        You aren’t able to check for scheduling conflicts or add new events until you reconnect your
        calendar.
      </Typography>
    </MoConfirmModal>
  );
};

export default CalendarReconnectModal;
