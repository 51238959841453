import { FormControl, InputLabel, Select, SelectProps } from '@mui/material';
import React, { ReactElement } from 'react';
import { MoFieldErrorText } from '../MoFieldErrorText';

export interface MoSelectProps extends SelectProps {
  name: string;
  label: string;
  helperText?: string | ReactElement;
}

export function MoSelect({
  name,
  label,
  fullWidth = true,
  error,
  helperText,
  ...props
}: MoSelectProps): ReactElement {
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={`${name}-label`} error={error}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        label={label}
        fullWidth={fullWidth}
        error={error}
        {...props}
      />
      {error && helperText && <MoFieldErrorText>{helperText}</MoFieldErrorText>}
    </FormControl>
  );
}
