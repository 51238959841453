import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ScrollToTop = ({ children, location }) => {
  const prevLocationRef = usePrevious(location);

  useEffect(() => {
    if (prevLocationRef && prevLocationRef.pathname !== location.pathname) {
      window.scrollTo(0, 0);
    }
  });

  return children;
};

export default withRouter(ScrollToTop);
