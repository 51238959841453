import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import { MoModal, MoModalProps } from '../MoModal';
import { MoGrid } from '../MoGrid';

export type MoConfirmModalProps = {
  open: boolean;
  onClose: () => void;
  title?: ReactNode;
  image?: string;
  imageWidth?: string;
  children?: ReactNode;
  noText?: string;
  yesText?: string;
  onYes: () => void;
  onNo?: () => void;
  disabled?: boolean;
  yesDisabled?: boolean;
  noDisabled?: boolean;
  maxWidth?: MoModalProps['maxWidth'];
  textAlign?: MoModalProps['textAlign'];
};

export function MoConfirmModal(props: MoConfirmModalProps) {
  const {
    open,
    onClose,
    title,
    image,
    imageWidth,
    children,
    yesText = 'Yes, confirm',
    noText,
    onYes,
    onNo,
    yesDisabled,
    noDisabled,
    disabled,
    maxWidth,
    textAlign,
  } = props;

  return (
    <MoModal
      open={open}
      onClose={onClose}
      image={image}
      imageWidth={imageWidth}
      title={title}
      maxWidth={maxWidth}
      isCloseDisabled={disabled}
      textAlign={textAlign}
      actions={
        <MoGrid container spacing={2}>
          <MoGrid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={onYes}
              disabled={yesDisabled ?? disabled}
            >
              {yesText}
            </Button>
          </MoGrid>
          {noText && (
            <MoGrid item xs={12}>
              <Button
                variant="outlined"
                color="neutral"
                fullWidth
                onClick={onNo || onClose}
                disabled={noDisabled ?? disabled}
                type="submit"
              >
                {noText}
              </Button>
            </MoGrid>
          )}
        </MoGrid>
      }
    >
      {children}
    </MoModal>
  );
}
