import { usePostHog } from 'posthog-js/react';
import type { PostHog } from 'posthog-js';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface User {
  email?: string | null;
  id?: number | null;
  fullName?: string | null;
}

export default function useSetUpAnalytics<U extends User = User>({
  user,
  afterSetUser,
  getAdditionalProperties,
}: {
  user: U | undefined;
  afterSetUser?: ({ user, posthog }: { user: U; posthog: PostHog }) => void;
  getAdditionalProperties?: (user: U) => Record<string, any>;
}) {
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    if (user?.id) {
      const idAsString = user.id.toString();
      posthog?.identify(idAsString, {
        internal: user.email?.endsWith('@motivohealth.com'),
        // email: user.email,
        // name: user.fullName,
        ...(getAdditionalProperties ? getAdditionalProperties(user) : {}),
      });
      Sentry.setUser({ id: idAsString, email: user.email, fullName: user.fullName });

      if (afterSetUser) {
        afterSetUser({ user, posthog });
      }
    }
  }, [posthog, user]);

  const fullPath = location.pathname + location.search;

  useEffect(() => {
    // Never log auth-based routes which are both noisy and contain sensitive info like auth code
    if (fullPath.startsWith('/callback') || fullPath.startsWith('/logout')) {
      return;
    }
    posthog?.capture('$pageview');
  }, [posthog, fullPath]);
}
