import React from 'react';
import * as Sentry from '@sentry/react';
import ErrorPage from './ErrorPage';
import NotFoundPage from './NotFoundPage';

export class NotFoundError extends Error {
  name = 'NotFoundError';
  message = '[NotFoundError] Page not found';
}

export default function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        if (error instanceof NotFoundError) {
          return <NotFoundPage />;
        }
        return <ErrorPage />;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
