import { Box, BoxProps, Typography } from '@mui/material';
import { TimeZoneEnum } from '@src/utils/timeZoneUtils';
import { DateTime } from 'luxon';
import React, { FunctionComponent } from 'react';
import { MoSelectionButton } from '../MoSelectionButton';

export type MoTimeSlotsProps = {
  timezone: TimeZoneEnum;
  timeSlots: { date: string; times: string[] }[];
  onChange: (value: DateTime) => void;
  isChecked: (currentDate: DateTime) => boolean;
  maxHeight?: BoxProps['maxHeight'];
};

export const MoTimeSlots: FunctionComponent<MoTimeSlotsProps> = ({
  timeSlots,
  timezone,
  onChange,
  isChecked,
  maxHeight = 'unset',
}) => {
  return (
    <>
      {timeSlots.map(({ date: dateString, times }) => {
        const date = DateTime.fromFormat(dateString, 'D');

        return (
          <div key={`date-${date.toISO()}`} data-cy="time-slot-day">
            <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
              {date.toFormat('LLL d, cccc')}
            </Typography>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: 1,
                maxHeight,
                overflow: 'auto',
              }}
            >
              {times.length === 0 ? (
                <>No available times</>
              ) : (
                times.map((timeString) => {
                  const time = DateTime.fromFormat(timeString, 'T');
                  const dateTime = date
                    .set({ hour: time.hour, minute: time.minute })
                    .setZone(timezone, { keepLocalTime: true });

                  return (
                    <MoSelectionButton
                      color="primary"
                      key={`datetime-${dateTime.toISO()}`}
                      id={`datetime-${dateTime.toISO()}`}
                      onChange={(event) => {
                        event.preventDefault();
                        onChange(dateTime);
                      }}
                      checked={isChecked(dateTime)}
                    >
                      {time.toLocaleString(DateTime.TIME_SIMPLE)}
                    </MoSelectionButton>
                  );
                })
              )}
            </Box>
          </div>
        );
      })}
    </>
  );
};
