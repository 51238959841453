import {
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useState } from 'react';

export type Columns<T> = Column<T>[];

export interface Column<T> {
  header: React.ReactNode;
  content: (rowData: T, rowIndex: number) => React.ReactNode;
  align?: 'left' | 'right';
  sx?: SxProps<Theme>;
}

type ExpandableRows = Record<number, boolean>;

export function useExpandableRows() {
  const [expandableRows, setExpandableRows] = useState<ExpandableRows>({});

  const toggleExpandableRow = useCallback(
    (rowId: number) => {
      setExpandableRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
    },
    [setExpandableRows],
  );

  const renderExpandableRowToggle = (rowId: number) => {
    return (
      <IconButton onClick={() => toggleExpandableRow(rowId)}>
        {expandableRows[rowId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    );
  };

  return { expandableRows, toggleExpandableRow, renderExpandableRowToggle };
}

export function MoDataTable<T>({
  columns,
  data,
  rowSx = {},
  sx = {},
  footer,
  expandableRowContent = () => null,
  expandableRows = {},
}: {
  columns: Column<T>[];
  data: T[];
  sx?: SxProps<Theme>;
  rowSx?: SxProps<Theme>;
  footer?: React.ReactNode;
  expandableRowContent?: (rowData: T, rowIndex: number) => React.ReactNode;
  expandableRows?: ExpandableRows;
}): React.ReactElement {
  return (
    <>
      <Table sx={sx}>
        <TableHead>
          <TableRow sx={rowSx}>
            {columns.map((col, i) => (
              <TableCell key={i} align={col.align} sx={col.sx}>
                {col.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((rowData, i) => (
              <React.Fragment key={i}>
                <TableRow sx={rowSx}>
                  {columns.map((col, j) => (
                    <TableCell key={j} align={col.align} sx={col.sx}>
                      {col.content(rowData, i)}
                    </TableCell>
                  ))}
                </TableRow>
                {expandableRows[i] && (
                  <TableRow sx={rowSx}>
                    <TableCell colSpan={columns.length}>
                      {expandableRowContent(rowData, i)}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
        </TableBody>
        {footer && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columns.length}>{footer}</TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </>
  );
}
