import { FormHelperText } from '@mui/material';
import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { MoSelect, MoSelectProps } from '../MoSelect';

export interface MoSelectFieldProps<T> extends MoSelectProps {
  children: React.ReactNode;
  parse?: (value: any) => T;
  validate?: FieldValidator<T>;
  renderValue?: (value: any) => React.ReactNode;
}

export function MoSelectField<T>({
  name,
  label,
  children,
  parse = (value) => value,
  validate,
  ...restProps
}: MoSelectFieldProps<T>) {
  const { helperText, onChange, ...otherProps } = restProps;
  return (
    <Field<T>
      name={name}
      parse={parse}
      validate={validate}
      subscription={{
        error: true,
        value: true,
        submitFailed: true,
        valid: true,
      }}
      render={({ input, meta }) => (
        <>
          <MoSelect
            {...input}
            label={label}
            error={meta.submitFailed && !meta.valid}
            helperText={meta.error}
            onChange={(event, child) => {
              input.onChange(event);

              if (onChange) {
                onChange(event, child);
              }
            }}
            {...otherProps}
          >
            {children}
          </MoSelect>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </>
      )}
    />
  );
}
